import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";
const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));

export default function ConfirmModal({ isOpen = false, title = "", description = "", confirmHandler, cancelHandler }) {
  const [open, setOpen] = React.useState(isOpen);

  useEffect(() => setOpen(isOpen), [isOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={callAll(handleClose, cancelHandler)} color="primary">
          Annuleren
        </Button>
        <Button onClick={callAll(handleClose, confirmHandler)} color="secondary" autoFocus>
          Verwijderen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
