import React, { useContext, useState, useEffect, useRef } from "react";
import { withStyles } from "@mui/styles";
import { withRouter } from "react-router";
import { Button, IconButton, Typography, ListItem, ListItemText, Grid, Divider } from "@mui/material";

import proj4 from "proj4";

import { withLeaflet, Popup, Marker, FeatureGroup } from "react-leaflet";
import L from "leaflet";
import WMTSTileLayer from "react-leaflet-wmts";
import WFST from "leaflet-wfst";
import Proj4Leaflet from "proj4leaflet";
import rewind from "geojson-rewind";
import wkx from "wkx";
import WKT from "terraformer-wkt-parser";
import LayersIcon from "@mui/icons-material/Layers";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import { Map, ControlButton } from "@orbit/components";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { StoresContext } from "contexts";
import { APP_LEGGER_TYPES, SUBSCRIPTION_CODE } from "app-constants";
import Logo from "layout/img/MapLogo.png";
import { observer as hooksObserver } from "mobx-react-lite";
import styles from "./MapStyles";
import PerceelInfo from "../perceelInfo/PerceelInfoView";
import { ROUTE_PERCEEL } from "../../routes/RouteList";
import { toJS } from "mobx";
import { PERCEEL_INFO_PANEL_ENUM } from "stores/UiStore";
import DrawTool from "./DrawTools";
import "../../utils/leaflet-measure";
import ThreeDMCViewer from "./ThreeDMCViewer";
import * as d3 from "d3";
import RenderIf from "components/RenderIf";
import MapBaseLayer from "./MapBaseLayer";

//show icon
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

L.OrbitFeatureGroup = L.FeatureGroup.extend({
  addLayer: function (layer) {
    L.FeatureGroup.prototype.addLayer.call(this, layer);
  },
});

/**
 * adds caching to the wfst layer
 * should be further checked but the first versions seems ok
 */
L.WFST.prototype.addLayer = function (layer) {
  if (!this.dictionary) {
    this.dictionary = {};
  }
  if (!this.dictionary[layer.feature.properties.CAPAKEY]) {
    this.dictionary[layer.feature.properties.CAPAKEY] = true;
    L.FeatureGroup.prototype.addLayer.call(this, layer);
    if (!layer.feature) {
      layer.feature = { properties: {} };
    }

    if (!layer.state) {
      layer.state = this.state.insert;
      var id = this.getLayerId(layer);
      this.changes[id] = layer;
    }
  }

  return this;
};

const logo = {
  img: Logo,
  url: "https://www.orbitgis.com/",
};

let cancable;

const MapView = hooksObserver(({ match: { params: { capakey: urlCapakey, leggerType: urlLeggerType } }, classes }) => {
  const {
    mapStore: {
      setZoomLevel,
      zoomLevel,
      latLng,
      setLatLng,
      bounds,
      setBounds,
      mapLayer: { activeMap },
      mappingCloud: { mcActive, enableMc, disableMc, setSvgLayer, pickPosition, setPickPosition },
      pointOfInterest,
      setPointOfInterest,
    },
    uiStore: {
      addToHistory,
      perceelInfoPanel,
      minimizePerceelInfoPanel,
      setPerceelInfoPanel,
      mapHeight,
      errorModel,
      popupModel,
      popupModel: { position, actueleCapakey, fiscaleCapakey, isMarkerOpened, setIsMarkerOpened, getDataForLocationFromLatLng, getDataForCapakey },
    },
    routingStore: { push, location },
    notifierStore,
    applicationStore: { perceelInfoActief: { setCapakey, loadParcelInfo, capakey, setLoading } },
    authStore: { subscriptions },
  } = useContext(StoresContext);

  let mapRef = useRef();
  let featureRef = useRef();
  let drawRef = useRef(null);

  const [enableLayerSelector, setEnableLayerSelector] = useState(false);
  const [activeLayer, setActiveLayer] = useState(null);
  const [oldLayer, setOldLayer] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDrawing, _setIsDrawing] = useState(false);

  const isDrawingRef = React.useRef(isDrawing);
  const setIsDrawing = (data) => {
    isDrawingRef.current = data;
    _setIsDrawing(data);
  };

  useEffect(() => {
    if (position) {
      const [lng, lat] = toJS(position);
      setLatLng({ lat, lng });
    }
  }, [position]);

  useEffect(() => {
    if (oldLayer !== null) {
      oldLayer.remove();
    }
    setOldLayer(activeLayer);
  }, [activeLayer]);

  useEffect(() => {
    const myMap = mapRef.current.leafletElement;

    const svgLayer = L.svg();
    svgLayer.addTo(myMap);

    const svg = d3.select("#map").select("svg");
    svg.attr("class", "leaflet-zoom-animated customzindex");
    setSvgLayer(svg);

    myMap.on(L.Draw.Event.TOOLBAROPENED, () => {
      setIsDrawing(true);
    });

    myMap.on(L.Draw.Event.TOOLBARCLOSED, () => {
      setTimeout(() => setIsDrawing(false), 50);
    });

    myMap.on("dragstart", function (e) {
      if (!e.hard) {
        setIsDragging(true);
        minimizePerceelInfoPanel();
      }
    });

    myMap.on("dragend", function (e) {
      if (!e.hard) {
        setIsDragging(false);
      }
    });
  }, [featureRef]);

  useEffect(() => {
    if (mapRef && drawRef) {
      mapRef.current.leafletElement.on(L.Draw.Event.CREATED, function (e) {
        let layer = e.layer;

        drawRef.current.leafletElement.clearLayers();
        drawRef.current.leafletElement.addLayer(layer);
        drawRef.current.leafletElement.eachLayer(function (myLayer) {
          if (layer === myLayer) {
            myLayer.showMeasurements();
          }
        });
      });
      mapRef.current.leafletElement.on("draw:edited", function (e) {
        let layers = e.layers;
        layers.eachLayer(function (layer) {
          layer.updateMeasurements();
        });
      });
      mapRef.current.leafletElement.on("zoomend", function (e) {
        drawRef.current.leafletElement.eachLayer(function (layer) {
          layer.updateMeasurements();
        });
      });
    }
  }, [mapRef, drawRef]);

  const onZoomend = (action) => {
    setZoomLevel(action.target._zoom);
  };

  const zoom = {
    currentLevel: zoomLevel,
    maxLevel: activeMap.maxZoom,
    minLevel: activeMap.minZoom,
  };

  const markerClosed = (event) => {
    setIsMarkerOpened(false);
  };

  const searchPerceel = async (leggerType) => {
    const capakey = leggerType === APP_LEGGER_TYPES.ACTUAL ? actueleCapakey : fiscaleCapakey;

    addToHistory(capakey);
    setCapakey(capakey);
    push(`${ROUTE_PERCEEL}${leggerType}/${encodeURIComponent(capakey.full)}`);
  };

  useEffect(() => {
    if (location.pathname === "/account/selecteer") return;
    urlCapakey = decodeURIComponent(urlCapakey);
    if (urlCapakey === "undefined") {
      return;
    }
    const getData = async () => {
      if (cancable && cancable.cancel) {
        cancable.cancel();
      }
      // removed because of
      //https://orbitgis.atlassian.net/browse/LEGGER-136
      /* if (!capakey.full) {
          await getDataForCapakey(urlCapakey);
        } */
      await getDataForCapakey(urlCapakey);

      const activeCapakey = urlLeggerType === APP_LEGGER_TYPES.FISCAL ? popupModel.fiscaleCapakey : popupModel.actueleCapakey;
      try {
        cancable = loadParcelInfo(Object.keys(activeCapakey).length ? activeCapakey : urlCapakey, urlLeggerType).then(
          (success) => {
            setLoading(false);
            if (!success) {
              return false;
            }
            const [polygon, capakey] =
              urlLeggerType === APP_LEGGER_TYPES.ACTUAL ? [popupModel.actuelePolygon, popupModel.actueleCapakey] : [popupModel.fiscalePolygon, popupModel.fiscaleCapakey];

            if (!Object.keys(polygon).length) {
              setPerceelInfoPanel(PERCEEL_INFO_PANEL_ENUM.OPEN);
              setActiveLayer(null);
              return;
            }

            let wkt = wkx.Geometry.parse(WKT.convert(polygon));
            let geojson = rewind(wkt.toGeoJSON(), false);
            geojson.crs = {
              type: "name",
              properties: {
                name: "EPSG:4326",
              },
            };
            geojson.capakey = capakey.full;
            let myPolygon = L.Proj.geoJson(geojson);
            featureRef.current.leafletElement.addLayer(myPolygon);
            if (perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN) {
              mapRef.current.leafletElement.fitBounds(myPolygon.getBounds());
            }

            let layers = featureRef.current.leafletElement._layers;
            let myLayer = layers[Object.keys(layers)[Object.keys(layers).length - 1]];
            let myObject = myLayer._layers[Object.keys(myLayer._layers)[Object.keys(myLayer._layers).length - 1]];

            myObject._path.classList.add("active");

            setActiveLayer(myObject);
            setPerceelInfoPanel(PERCEEL_INFO_PANEL_ENUM.OPEN);
          },
          (error) => {
            console.log("error", error);
            setLoading(false);
            if ("" + error === "Error: FLOW_CANCELLED") {
            } else {
              switch (error.response && error.response.status) {
                default:
                  console.log("TODO::loadParcelInfo fetch: We still need to catch the following error: ", error);
                  break;
              }
            }
          },
        );
      } catch (error) {
        console.log("Error::loadParcelInfo fetch: ", error.toString());
      }
    };

    if (urlCapakey && urlLeggerType && featureRef !== "") {
      getData();
    }
  }, [location, featureRef, mapRef, urlCapakey]);

  useEffect(() => {
    if (activeLayer && !isDragging) {
      setTimeout(() => {
        mapRef.current.leafletElement.fitBounds(activeLayer.getBounds());
      }, 10);
    }
  }, [perceelInfoPanel]);

  const handleDataForLatLng = async (latlng) => {
    await getDataForLocationFromLatLng(latlng.lat, latlng.lng);
    if (popupModel.actueleCapakey.full || popupModel.fiscaleCapakey.full) {
      setIsMarkerOpened(true);
      setLatLng(latlng);
    }
  };

  const handleClick = async (e) => {
    if (pointOfInterest) {
      setPointOfInterest(null);
    }
    if (mcActive && pickPosition) {
      const { latlng } = e;
      setPickPosition(latlng.lng, latlng.lat, mapRef.current.leafletElement);
    } else {
      if (!isDrawingRef.current) {
        const { latlng } = e;
        handleDataForLatLng(latlng);
      }
    }
  };

  return (
    <React.Fragment>
      <Map
        zoom={zoom}
        latLng={latLng}
        logo={logo}
        header={true}
        mapRef={mapRef}
        id="map"
        bounds={bounds}
        onViewportChanged={() => setBounds(null)}
        style={{
          height: perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED ? 0 : mapHeight,
        }}
        customClass={
          mcActive && pickPosition
            ? classes.leafletInteractiveMCpickPosition
            : perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN
              ? classes.leafletInteractiveWithPerceelInfo
              : classes.leafletInteractive
        }
        onZoomend={onZoomend}
        popupCloseFn={markerClosed}
        onClick={handleClick}
      >
        <MapBaseLayer mapSelectorOpen={enableLayerSelector} onCloseSelector={setEnableLayerSelector} />
        <ControlButton id={"map-layers"} onClick={(e) => setEnableLayerSelector(true)} position={"topright"} icon={<LayersIcon />} />
        <ControlButton
          onClick={() => {
            if (!subscriptions.getSubscriptionByCode(SUBSCRIPTION_CODE.MOBILE_MAPPING)) {
              notifierStore.enqueueSnackbar({
                message: "Deze optie zit niet in uw formule!",
                options: { variant: "info" },
              });
            } else {
              mcActive ? disableMc() : enableMc();
            }
          }}
          position={"topright"}
          icon={<ThreeDRotationIcon />}
        />
        <RenderIf condition={!!pointOfInterest}>
          <Marker
            key="pointOfInterest"
            position={[pointOfInterest?.lat, pointOfInterest?.lng]}
            // onclick={() => handleDataForLatLng(pointOfInterest)}
          />
        </RenderIf>
        <DrawTool
          onChange={() => {}}
          drawedGeoJson={{}}
          featureGroupRef={drawRef}
          drawOptions={{
            metric: true,
            circle: true,
            circlemarker: false,
            marker: false,
            polyline: true,
            rectangle: true,
          }}
        />
        <ControllableMarker key={Math.random()} position={latLng} isOpen={isMarkerOpened} closeFn={markerClosed}>
          <Popup isOpen={true} className={classes.popup}>
            <ListItem className={classes.popupListItemTitle} dense>
              <ListItemText
                className={classes.popupListItemTextTitle}
                primary={
                  <React.Fragment>
                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                      Perceels identificatie
                    </Typography>
                  </React.Fragment>
                }
              />
              <IconButton onClick={markerClosed} color="primary">
                <CloseIcon />
              </IconButton>
            </ListItem>
            <Divider />
            <Grid container>
              <Grid item xs={7}>
                <ListItem>
                  <ListItemText className={classes.popupListItemText} primary={"Actueel"} secondary={actueleCapakey.full} />
                </ListItem>
              </Grid>
              <Grid item xs={5}>
                {actueleCapakey.full && (
                  <Button
                    data-test-id="btn-bekijken-actueel"
                    variant="text"
                    color="secondary"
                    onClick={() => searchPerceel(APP_LEGGER_TYPES.ACTUAL)}
                    className={classes.popupButton}
                    size="small"
                  >
                    Bekijken
                    <ChevronRightIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={7}>
                <ListItem>
                  <ListItemText className={classes.popupListItemText} primary={"Fiscaal"} secondary={fiscaleCapakey.full ? fiscaleCapakey.full : "Geen resultaat"} />
                </ListItem>
              </Grid>
              <Grid item xs={5}>
                {fiscaleCapakey.full && (
                  <Button
                    data-test-id="btn-bekijken-fiscaal"
                    variant="text"
                    color="secondary"
                    onClick={() => searchPerceel(APP_LEGGER_TYPES.FISCAL)}
                    className={classes.popupButton}
                    size="small"
                  >
                    Bekijken
                    <ChevronRightIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Popup>
        </ControllableMarker>
        <FeatureGroup ref={featureRef}></FeatureGroup>
        <ThreeDMCViewer />
      </Map>

      {perceelInfoPanel !== PERCEEL_INFO_PANEL_ENUM.CLOSED && <PerceelInfo />}
    </React.Fragment>
  );
});

export default withLeaflet(withRouter(withStyles(styles)(MapView)));

const ControllableMarker = withLeaflet((props) => {
  const { isOpen, leaflet, closeFn, ...markerProps } = props;
  const markerRef = React.createRef();

  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    const marker = markerRef.current;
    if (open) {
      setTimeout(() => {
        marker.leafletElement.openPopup();
      }, 0);
    } else {
    }
  }, [open]);

  return (
    <Marker
      {...markerProps}
      ref={markerRef}
      onclick={() => setOpen(true)}
      tooltipclose={() => closeFn()}
      icon={
        new L.divIcon({
          className: "dontshowmymarker",
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          tooltipAnchor: [16, -28],
          shadowSize: [41, 41],
        })
      }
    >
      {props.children}
    </Marker>
  );
});
