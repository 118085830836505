import { observable, action, toJS } from "mobx";
import { persist } from "mobx-persist";

export default class SubscriptionModel {
  @persist("list")
  @observable
  subscriptions = [];

  getSubscriptionByCode = (code) => {
    if (this.subscriptions.length < 1) return false;
    return this.subscriptions.find((value) => value.code === code);
  };

  @action
  setSubscriptions = (subscriptions) => {
    this.subscriptions = subscriptions;
  };
}
