import { Divider, IconButton, InputBase, List, ListItem, ListItemText, TextField, Tooltip } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { withStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { CustomLinearProgress, HorizontalDataTable } from "@orbit/components";
import { APP_LEGGER_TYPES } from "app-constants";
import { StoresContext } from "contexts";
import fuzzysort from "fuzzysort";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import TableModel from "stores/models/TableModel";
import { useDebounce } from "use-debounce";
import styles from "./SidebarPersonsParcelsStyles";
import { useParams } from "react-router";
import { createCSVDownload } from "services/utils";
import { fetchDepartments } from "services/api";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { toJS } from "mobx";

const options = {
  selectableRows: "none",
  download: false,
  print: false,
  filter: false,
  responsive: "scroll",
  sort: false,
  search: false,
  viewColumns: false,
  rowsPerPage: 50,
  rowsPerPageOptions: [50],
  textLabels: {
    body: {
      noMatch: "Geen info gevonden...",
      toolTip: "Sorteren",
    },
    pagination: {
      rowsPerPage: "Rijen per pagina:",
      displayRows: "van",
    },
  },
};

const loadingOption = JSON.parse(JSON.stringify(options));
loadingOption.textLabels.body.noMatch = "Laden van gegevens...";

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          height: "inherit",
          height: "calc(100% - 336px)",
        },
        responsiveScroll: {
          maxHeight: "calc(100% - 53px) !important",
          height: "100% !important",
        },
      },
      MUIDataTableToolbar: {
        root: {
          display: "none",
        },
      },
      MUIDataTableHeadRow: {
        root: {
          "& th:not(:first-child)": {
            padding: "0 10px !important",
          },
        },
      },
      MUIDataTableBodyRow: {
        root: {
          "& td:not(:first-child)": {
            padding: "0 10px !important",
          },
          "& td:nth-child(2)": {
            padding: "0 16px !important",
          },
        },
      },
      MuiTableCell: {
        footer: {
          padding: "0px 12px !important",
          "& div": {
            paddingLeft: 0,
          },
        },
      },
      MUIDataTableHead: {
        main: {
          display: "none",
        },
      },
      MUIDataTablePagination: {
        toolbar: {
          "& span:nth-child(2)": {
            display: "none",
          },
          "& div:nth-child(3)": {
            /*marginRight: 0,
            marginLeft: 4*/
          },
          "& div:last-child": {
            //marginLeft: 0
          },
        },
      },
    },
  });

const SidebarPersonsParcels = hooksObserver(({ match: { params: { officialId } }, classes }) => {
  const {
    applicationStore: { search: { loading, personParcels, getParcels, activePerson, searchPersonByInsz, searchCompanyByInsz } },
    uiStore: { setSideBarOpen, popupModel: { getDataForCapakey, setIsMarkerOpened } },
    authStore: { nis },
  } = useContext(StoresContext);

  const [parcelsOfPerson, setParcelsOfPerson] = useState(new TableModel());
  const [search, setSearch] = useState("");
  const [fuzzyItems, setFuzzyItems] = useState([]);
  const [searchDebounced] = useDebounce(search, 250);
  const [listOfDepartments, setListOfDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  let { leggerType } = useParams();

  useEffect(() => {
    const asyncDepartments = async () => {
      const data = await fetchDepartments(nis, leggerType);
      setListOfDepartments(data.departments);
    };
    asyncDepartments();
  }, []);

  useEffect(() => {
    setSelectedDepartment(null);
  }, [listOfDepartments]);

  useEffect(() => {
    if (officialId.length > 10) {
      searchPersonByInsz(leggerType, officialId);
    } else {
      searchCompanyByInsz(leggerType, officialId);
    }
  }, [leggerType, officialId]);

  useEffect(() => {
    if (officialId) {
      if (selectedDepartment) {
        getParcels(leggerType, officialId, selectedDepartment.departmentCode);
      } else {
        getParcels(leggerType, officialId);
      }
      setSideBarOpen(true);
    }
  }, [officialId, selectedDepartment]);

  useEffect(() => {
    const items = search.length > 0 ? fuzzyItems : personParcels;
    const parcels = items.map((parcel) => {
      return {
        Capakey: {
          capakey: parcel.capakey,
          officialId: parcel.officialId,
          street: parcel.street,
          streetNumber: parcel.streetNumber,
          aantalpartities: parcel.aantalpartities,
        },
      };
    });
    const parcelDataModel = new TableModel();
    parcelDataModel.insert(parcels);

    parcelDataModel.rowNames = parcelDataModel.rowNames.map((rowName) => {
      if (rowName.name === "Capakey") {
        return {
          ...rowName,
          options: {
            customBodyRender: (value, { rowData }) => {
              return (
                <div
                  key={value.capakey}
                  className={classes.customListItemBase}
                  onClick={async () => {
                    await getDataForCapakey(value.capakey);
                    setIsMarkerOpened(true);
                  }}
                >
                  <ListItemText
                    className={classes.customListItemBaseText}
                    primary={
                      <React.Fragment>
                        {value.capakey}{" "}
                        <small>
                          <em>
                            {value.aantalpartities} partitie
                            {value.aantalpartities > 1 ? "s" : null}
                          </em>
                        </small>
                      </React.Fragment>
                    }
                    secondary={`${value.street} ${value.streetNumber}`}
                  />
                  <IconButton size="small" className={classes.customListItemButton}>
                    <ArrowRightIcon />
                  </IconButton>
                </div>
              );
            },
          },
        };
      } else {
        return rowName;
      }
    });
    setParcelsOfPerson(parcelDataModel);
  }, [fuzzyItems]);

  const searchParcel = async (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const result = fuzzysort.go(searchDebounced, personParcels, {
      keys: ["capakey", "officialId", "street", "streetNumber"],
      allowTypo: false,
    });
    const filteredParcels = [];
    result.forEach((parcel) => {
      filteredParcels.push(parcel.obj);
    });
    filteredParcels.sort((a, b) => (a.capakey > b.capakey ? 1 : -1));
    setFuzzyItems(filteredParcels);
  }, [searchDebounced, personParcels]);

  useEffect(() => {
    console.log("get data");
  }, []);

  return (
    <React.Fragment>
      <List>
        <ListItem>
          <ListItemText primary="Eigenaar" secondary={(activePerson && `${activePerson.name} ${activePerson.firstname}`) || ` `} data-test-id="li-eigenaar" />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Woonadres"
            data-test-id="li-woonadres"
            secondary={
              (activePerson &&
                activePerson.street &&
                `${activePerson?.street?.replace(/\(([^)]+)\)/g, "")} ${activePerson.nr}${activePerson.Busnummer ? activePerson.Busnummer : ""} - ${activePerson.Postcode} ${
                  activePerson.Gemeente
                } `) ||
              ` `
            }
          />
        </ListItem>
      </List>
      <Autocomplete
        id="departments"
        options={listOfDepartments}
        getOptionLabel={(option) => `${option.departmentName} - ${option.departmentCode}`}
        onChange={(e, value) => {
          setSelectedDepartment(value);
        }}
        value={selectedDepartment}
        style={{ padding: 15, paddingTop: 0 }}
        renderInput={(params) => <TextField {...params} label="Verfijn op afdeling" fullWidth data-test-id="btn-verfijnopafdeling" />}
      />

      <Divider />
      {loading && <CustomLinearProgress />}

      <div className={classes.searchWrapper}>
        <InputBase className={classes.input} placeholder="Zoeken in eigendommen" inputProps={{ "aria-label": "Zoeken in eigendommen" }} onChange={searchParcel} value={search} />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton className={classes.iconButton} aria-label="search" color="primary">
          <SearchIcon />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <Tooltip title="download als csv" aria-label="download als csv">
          <IconButton
            id="download"
            color="primary"
            className={classes.iconButton}
            onClick={() => {
              const { rowData, rowNames } = parcelsOfPerson;
              const headers = Object.keys(rowData[0][0]);
              const oldColumn = rowNames[0];
              delete oldColumn.options;
              const newHeaders = headers.map((header) => {
                return {
                  ...oldColumn,
                  name: header,
                };
              });
              const newData = rowData.map((body) => Object.values(body[0]));
              createCSVDownload(newHeaders, newData, options);
            }}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      </div>

      <HorizontalDataTable title={""} data={parcelsOfPerson._rowsData} columns={parcelsOfPerson.rowNames} options={loading ? loadingOption : options} muiTheme={getMuiTheme} />
    </React.Fragment>
  );
});

SidebarPersonsParcels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SidebarPersonsParcels));
