import { withStyles } from "@mui/styles";
import { Button, Card, CardContent, CardHeader, Grid, Icon, Link, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useEffect, useContext, useState } from "react";
import styles from "./LoginStyles";
import img1 from "../../public/img/itsme-auth.png";
import img2 from "../../public/img/id-auth.png";
import img3 from "../../public/img/sms-auth.png";
import img4 from "../../public/img/totp-auth.png";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import Notifier from "views/notifier/Notifier";

const Login = hooksObserver(({ classes }) => {
  const {
    notifierStore,
    authStore: { sessionId, selectingProfile, doLogin },
  } = useContext(StoresContext);

  useEffect(() => {
    if (sessionId && selectingProfile) {
      const validate = async () => {
        await doLogin();
      };
      validate();
    }
  }, [sessionId, selectingProfile]);

  const [isACMIDM, setIsACMIDM] = useState(true);

  return (
    <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: "90vh" }}>
      <Notifier />
      <Card className={classes.card}>
        <CardHeader avatar={<Icon className={"icon-logo-orbit-black"} />} title={"Geoportaal Legger"} />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              Om deze pagina te bezoeken dient u zich aan te melden via ACM/IDM.
              <Grid container spacing={0} alignItems="center" justifyContent="center" className={classes.acmidmimgs}>
                <Grid item xs={3} className={classes.gridItem}>
                  <img src={img1} className={classes.img} />
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <img src={img2} className={classes.img} />
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <img src={img3} className={classes.img} />
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <img src={img4} className={classes.img} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <Link href={(isACMIDM && `${window.env.AUTH_URL}legger_login`) || ""}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={(e) => {
                      if (!isACMIDM) {
                        e.preventDefault();
                        doLogin(true);
                      }
                    }}
                  >
                    Klik hier om u aan te melden
                  </Button>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>
                <Link href={`https://mijnprofiel-gebruikersbeheer${window.env.NODE_ENV === "development" ? "-ti" : ""}.vlaanderen.be/user/`}>
                  <Button variant="text" size="small" style={{ color: "rgba(0, 0, 0, 0.87)" }} fullWidth>
                    Klik hier om uw profiel te raadplegen
                  </Button>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>
                <Link
                  href={`https://zelfregistratie-gebruikersbeheer${
                    window.env.NODE_ENV === "development" ? "-ti" : ""
                  }.vlaanderen.be/user/aanvraag?&an=267&dg=LB&ec=Orbit_GeoportaalLegger_LB_CompEnt_Gebruiker`}
                >
                  <Button variant="text" size="small" style={{ color: "rgba(0, 0, 0, 0.87)" }} fullWidth>
                    Klik hier indien uw beheerder u nog geen finaliteiten heeft toegewezen
                  </Button>
                </Link>
              </Typography>
            </Grid>
            {window.location.hostname === "localhost" && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<VerifiedUserOutlinedIcon />}
                      checkedIcon={<VerifiedUserIcon />}
                      checked={isACMIDM}
                      onChange={() => {
                        setIsACMIDM(!isACMIDM);
                      }}
                      data-test-id="fake-login"
                    />
                  }
                  label="Indien aangevinkt zal het aanmelden via ACM/IDM gaan."
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
});

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Login);
