const styles = (theme) => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 5,
    marginLeft: -16,
    marginRight: -16,
  },
});

export default styles;
