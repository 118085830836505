import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useMapPopupStyles = () => {
    const theme = useTheme();
    return makeStyles({
        toolbar: {
            display: "flex",
            alignItems: "center",
        },
        grow: {
            flex: 1,
        },
    })();
};
export default useMapPopupStyles;
