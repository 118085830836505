import fetchJsonp from "fetch-jsonp";

export const getStreetSuggestions = async ({ value, muncipality = "Gent", count = 15 }) => {
  try {
    const response = await fetchJsonp(`https://geo.api.vlaanderen.be/geolocation/v4/Suggestion?q=${muncipality}%20${value}&c=${count}`);
    const result = await response.json();
    return result.SuggestionResult.map((item, index) => ({
      value: item,
      label: item,
    }));
  } catch (error) {
    console.log("error", error.toString());
    return [error.toString()];
  }
};

export const getLocation = async (value) => {
  try {
    const response = await fetchJsonp(`https://geo.api.vlaanderen.be/geolocation/v4/Location?q=${value}`);
    const result = await response.json();
    return {
      lat: result.LocationResult["0"].Location.Lat_WGS84,
      lng: result.LocationResult["0"].Location.Lon_WGS84,
    };
  } catch (error) {
    console.log("error", error.toString());
    return [error.toString()];
  }
};
