import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useAutoCompleteStyles = () => {
    const theme = useTheme();
    return makeStyles({
        root: {
            flexGrow: 1,
            height: 250,
        },
        container: {
            flexGrow: 1,
            position: "relative",
        },
        paper: {
            position: "absolute",
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        chip: {
            //@ts-ignore
            margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
        },
        divider: {
            height: theme.spacing(2),
        },
        searchIcon: {
            width: theme.spacing(9),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
            width: "100%",
        },
        inputInput: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(7),
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: 150,
                "&:focus": {
                    width: 250,
                },
            },
        },
    })();
};
export default useAutoCompleteStyles;
