import md5 from "md5";

const cache = {};

/**
 *
 * @param {Function} actionFn
 * @param {Object} data
 * @param {String} [uniqueName]
 * @returns {object}
 */
export const apiLocalCacheAction = async (actionFn, data, uniqueName) => {
  const md5String = `${uniqueName || actionFn.name}_${md5(JSON.stringify(data))}`;
  if (cache[md5String]) {
    return cache[md5String];
  }

  const response = await actionFn();
  if (response?.data?.data && !response.errors?.length) {
    cache[md5String] = response;
  }
  return response;
};
