import { CompositeLayer } from "@deck.gl/core";
import { IconLayer, ScatterplotLayer, TextLayer } from "@deck.gl/layers";
import Supercluster from "supercluster";
import { lineString } from "@turf/helpers";
import bbox from "@turf/bbox";
const getIconSize = (size) => {
    return Math.min(100, size) / 100 + 1;
};
const getClusterColor = (pointCount) => {
    let color = [3, 155, 229];
    if (pointCount < 10) {
        color = [3, 155, 229];
    }
    else if (pointCount < 100) {
        color = [57, 73, 171];
    }
    else {
        color = [142, 36, 170];
    }
    return color;
};
const getMaxMinSize = (maxValue) => {
    if (maxValue < 100) {
        return 20;
    }
    else if (100 <= maxValue && maxValue < 1000) {
        return 25;
    }
    else {
        return 30;
    }
};
const calculateBoundingBox = (innerComponents) => {
    const coordinates = innerComponents.map((innerComponent) => innerComponent.geometry.coordinates);
    const line = lineString(coordinates);
    const bboxCoord = bbox(line);
    return [
        [bboxCoord[0], bboxCoord[1]],
        [bboxCoord[2], bboxCoord[3]],
    ];
};
// @ts-ignore
export default class IconClusterLayer extends CompositeLayer {
    shouldUpdateState({ changeFlags }) {
        return changeFlags.somethingChanged;
    }
    updateState({ props, oldProps, changeFlags }) {
        const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;
        if (rebuildIndex) {
            const index = new Supercluster({
                maxZoom: props.maxZoom,
                radius: props.sizeScale,
            });
            index.load(props.data.map((d) => ({
                geometry: { coordinates: props.getPosition(d) },
                properties: d,
            })));
            // @ts-ignore
            this.setState({ index });
        }
        // @ts-ignore
        const z = Math.floor(this.context.viewport.zoom);
        // @ts-ignore
        if (rebuildIndex || z !== this.state.z) {
            // @ts-ignore
            this.setState({
                // @ts-ignore
                data: this.state.index.getClusters([-180, -85, 180, 85], z),
                z,
            });
        }
    }
    getPickingInfo({ info, mode }) {
        const pickedObject = info.object && info.object.properties;
        if (pickedObject) {
            if (mode !== "hover") {
                info.cluster = false;
                if (pickedObject.cluster) {
                    // @ts-ignore
                    info.bbox = calculateBoundingBox(this.state.index.getLeaves(pickedObject.cluster_id, "infitiy", 0));
                    info.cluster = true;
                }
            }
            info.object = pickedObject;
        }
        return info;
    }
    renderLayers() {
        // @ts-ignore
        const { data } = this.state;
        // @ts-ignore
        const { options } = this.props;
        const clusterData = data.filter((obj) => obj.properties.cluster);
        const nonClusterData = data.filter((obj) => !obj.properties.cluster);
        const maxValueCluster = Math.max(...clusterData.map((obj) => obj.properties.point_count));
        return [
            new ScatterplotLayer(
            // @ts-ignore
            this.getSubLayerProps({
                id: "cluster-circle",
                data: clusterData,
                pickable: true,
                opacity: 0.8,
                filled: true,
                radiusMinPixels: getMaxMinSize(maxValueCluster),
                radiusMaxPixels: getMaxMinSize(maxValueCluster),
                stroked: false,
                getPosition: (d) => d.geometry.coordinates,
                getRadius: (d) => getIconSize(d.properties.point_count) * 100,
                getFillColor: (d) => getClusterColor(d.properties.point_count),
                getLineColor: (d) => [0, 0, 0],
                parameters: {
                    depthTest: false,
                },
            })),
            new TextLayer(
            // @ts-ignore
            this.getSubLayerProps({
                id: "cluster-text",
                data: clusterData,
                getPosition: (d) => d.geometry.coordinates,
                getText: (d) => d.properties.point_count + "",
                getSize: (d) => 14,
                getColor: (d) => [255, 255, 255],
                sizeScale: 24 / 20,
                getTextAnchor: "middle",
                getAlignmentBaseline: "center",
            })),
            new IconLayer(
            // @ts-ignore
            this.getSubLayerProps({
                id: "icon",
                data: nonClusterData,
                sizeScale: 15,
                getSize: (d) => 1.7,
                pickable: true,
                getPosition: (d) => d.geometry.coordinates,
                getIcon: options.getIconSingle,
            })),
        ];
    }
}
