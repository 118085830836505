import { Button, Grid, TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import { StoresContext } from "contexts";
import SearchSubscriptionMessage from "./search-subscription-message";

const SearchCompanyByName = ({ type }) => {
  const {
    applicationStore: { search },
  } = useContext(StoresContext);

  const [companyName, setCompanyName] = useState("");

  return (
    <form
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault();
        if (companyName.length > 0) {
          search.searchCompanyByName(type, companyName);
        }
      }}
    >
      <Grid container spacing={2} direction="column" alignItems="stretch">
        <SearchSubscriptionMessage type={type} />

        <Grid item>
          <TextField required id="company-name" label="Bedrijfsnaam" variant="outlined" fullWidth onChange={(event) => setCompanyName(event.target.value)} />
        </Grid>
        <Grid item>
          <Button type="submit" variant="outlined" fullWidth data-test-id="btn-zoekop-companyname">
            Zoek op
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchCompanyByName;
