import React from "react";
import MuiExpansionPanel from "@mui/material/Accordion";
import MuiExpansionPanelDetails from "@mui/material/AccordionDetails";
import MuiExpansionPanelSummary from "@mui/material/AccordionSummary";
import { withStyles } from "@mui/styles";
import { ExpansionPanelStyles, ExpansionPanelSummaryStyles, ExpansionPanelDetailsStyles } from "./CustomExpansionPanelsStyles";

export const ExpansionPanel = withStyles(ExpansionPanelStyles)(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles(ExpansionPanelSummaryStyles)((props) => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

export const ExpansionPanelDetails = withStyles(ExpansionPanelDetailsStyles)(MuiExpansionPanelDetails);
