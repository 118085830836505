import { MESSAGE_TYPES } from "./config";

const MAGDA_MESSAGES = [
  {
    type: MESSAGE_TYPES.INFO,
    text: "geen gegevens gevonden voor de vraag",
  },
  {
    type: MESSAGE_TYPES.INFO,
    text: "geen gegevens in antwoord wegens filtering",
  },
  {
    type: MESSAGE_TYPES.WARNING,
    text: "fout formaat in de vraag",
  },
  {
    type: MESSAGE_TYPES.ERROR,
    text: "er heeft zich een technische fout voorgedaan",
  },
  {
    type: MESSAGE_TYPES.ERROR,
    text: "kan antwoord bron niet interpreteren",
  },
  {
    type: MESSAGE_TYPES.ERROR,
    text: "geen antwoord van de bron ontvangen",
  },
];

const MAGDA_TITLES = {
  info: "Info van Magda",
  warning: "Waarschuwing van Magda",
  error: "Error van Magda",
  unknown: "Melding van Magda",
};

export const getMagdaNotificationType = (exceptions) => {
  try {
    if (Array.isArray(exceptions) && exceptions.length) {
      const exists = MAGDA_MESSAGES.find((message) => {
        return exceptions.find((exception) => {
          if (typeof exception === "object") {
            return exception.Diagnose?.toLowerCase?.().includes?.(message.text);
          }
          return false;
        });
      });
      if (exists) {
        return exists.type;
      }
    }
  } catch (er) {}
  return MESSAGE_TYPES.UNKNOWN;
};

export const getMagdaNotificationTitle = (type) => {
  return MAGDA_TITLES[type] || MAGDA_TITLES.unknown;
};
