import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import { Slider } from "@mui/material";
import React, { useEffect } from "react";

import styles from "./PerimeterSliderStyles";

import debounce from "debounce";

const MAX_VALUE = 500;

const PerimeterSlider = ({ classes, onChange, defaultValue, disabled }) => {
  const [value, setValue] = React.useState(defaultValue);

  const debouncedOnChange = debounce(onChange);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > MAX_VALUE) {
      setValue(MAX_VALUE);
    }
  };

  useEffect(() => {
    if (value > 0 && value <= MAX_VALUE) {
      debouncedOnChange(value, 1000);
    }
  }, [value]);

  return (
    <div className={classes.root}>
      <Typography id="input-slider" gutterBottom>
        Perimeter
      </Typography>
      <Grid container spacing={8} alignItems="center">
        <Grid item xs>
          <Slider disabled={disabled} value={typeof value === "number" ? value : 0} onChange={handleSliderChange} step={10} aria-labelledby="input-slider" max={MAX_VALUE} />
        </Grid>
        <Grid item>
          <Input
            disabled={disabled}
            className={classes.input}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 1,
              max: MAX_VALUE,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />{" "}
          m.
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(PerimeterSlider);
