import { action, observable, computed } from "mobx";
import { defaultColumnOption } from "services/utils";

class TableModel {
  @observable
  data = [];

  @observable
  searchValue;

  @observable
  rowNames = [];

  @observable
  _rowsData = [];

  @action
  setData = (data) => {
    this.data = data;
  };

  @action
  setSearchValue = (value) => {
    this.searchValue = value;
  };

  @computed
  get rowData() {
    if (this.searchValue) {
      return this._rowsData.filter((data) => {
        const checkRow = data.map((value) => JSON.stringify(value).toLowerCase().includes(this.searchValue.toLowerCase()));
        if (checkRow.indexOf(true) > -1) return data;
      });
    }
    return this._rowsData;
  }

  @action
  setRowsData = (data) => {
    this._rowsData = data;
  };

  @action
  setRows = (columns) => {
    this.columns = columns;
  };

  @action
  insert = (
    data,
    addOptions = (value) => {
      return { name: value, ...defaultColumnOption };
    },
  ) => {
    this.data = data;
    this.rowNames = this.data?.length && this.data[0] ? Object.keys(this.data[0]) : [];
    this.rowNames = this.rowNames.map(function (x) {
      return x.replace("_", " ");
    });
    this.rowNames = this.rowNames.map(addOptions);
    this._rowsData = [];
    if (this.data.length > 0)
      this.data.forEach((values) => {
        let tmp = [];
        for (const key in values) {
          tmp.push(values[key]);
        }
        this._rowsData.push(tmp);
      });
  };
}

export default TableModel;
