import { action, observable, toJS } from "mobx";

const pxToMm = () => {
  let heightRef = document.createElement("div");
  heightRef.style = "height:1mm;display:block;";
  heightRef.id = "heightRef";
  document.body.appendChild(heightRef);

  heightRef = document.getElementById("heightRef");
  const pxPermm = heightRef.clientHeight;
  //console.log(pxPermm);

  heightRef.parentNode.removeChild(heightRef);

  return function pxTOmm(px) {
    return px / pxPermm;
  };
};

const calculateScaleDenominator = (map) => {
  const CenterOfMap = map.getSize().y / 2;

  const RealWorlMetersPer100Pixels = map.distance(map.containerPointToLatLng([0, CenterOfMap]), map.containerPointToLatLng([100, CenterOfMap]));

  const ScreenMetersPer100Pixels = pxToMm()(100) / 1000;
  //console.log(ScreenMetersPer100Pixels);

  const scaleFactor = RealWorlMetersPer100Pixels / ScreenMetersPer100Pixels;

  //.replace formats the scale with commas 50000 -> 50,000
  //console.log(Math.round(scaleFactor)); //.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));
  return Math.round(scaleFactor); //.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * model used to control the layer of the maps
 * maplist is a json with al possible layers
 * when changing the activemapindex the map will be rerendered
 *
 */
class MappingCloudModel {
  channel = null;

  @observable
  mcActive = false;

  @observable
  footprints = [];

  @observable
  fovs = [];

  @observable
  svgLayer = null;

  @observable
  pickPosition = false;

  @observable
  pingFootprints = false;

  setBounds;

  constructor(setBounds) {
    this.setBounds = setBounds;
    this.channel = new BroadcastChannel("3DMC");
  }

  @action
  enableMc = () => {
    this.mcActive = true;
    //var base_url = window.location.origin;

    //var host = window.location.host;
    window.open(window.location.origin + "/3dmc_viewer.html", "_blank");
    //console.log("Enabling the messages");
    this.channel.onmessage = (event) => {
      // console.log("received event", event)
      switch (event.data.type) {
        case "disableMC":
          this.disableMc();
          break;
        case "workspaceOpened":
          this.pingFootprints = true;
          break;
        case "fov":
          this.fovs = event.data.data;
          break;
        case "footprints":
          this.footprints = event.data.data;
          break;
        case "pickPositionRequested":
          this.pickPosition = true;
          break;
        case "pickPositionCancelled":
          this.pickPosition = false;
          break;
        case "zoomToExtent":
          const { bounds } = event.data;
          const geoJson = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [bounds.minX, bounds.minY],
                  [bounds.maxX, bounds.minY],
                  [bounds.maxX, bounds.maxY],
                  [bounds.minX, bounds.maxY],
                  [bounds.minX, bounds.minY],
                ],
              ],
            },
          };
          this.setBounds(L.geoJson(geoJson).getBounds());
          break;
        default:
          console.log("No code for this type:", event.data.type);
          break;
      }
    };
  };

  @action
  disableMc = () => {
    this.mcActive = false;
    this.pingFootprints = false;
    this.pickPosition = false;
    //console.log("Disabling the messages");
    this.channel.onmessage = null;
  };

  @action
  setSvgLayer = (value) => {
    this.svgLayer = value;
  };

  @action
  fetchFootprints = (bounds, map) => {
    if (this.mcActive) {
      // fromXY(crs: string, minX: float64, minY: float64, maxX: float64, maxY: float64)
      const body = {
        type: "getFootprints2D",
        bounds: [bounds._southWest.lng, bounds._southWest.lat, bounds._northEast.lng, bounds._northEast.lat],
        crs: "4326",
        zoomRatio: calculateScaleDenominator(map),
      };
      //console.log(body);
      this.channel.postMessage(body);
    } else {
      console.log("User tried to fetch footprints but MC is not active.");
    }
  };

  @action
  fetchFovs = () => {
    if (this.mcActive) {
      const body = {
        type: "getFieldOfViews2D",
      };
      this.channel.postMessage(body);
    } else {
      console.log("User tried to fetch fovs but MC is not active.");
    }
  };

  @action
  setPickPosition = (x, y, map) => {
    if (this.mcActive) {
      const body = {
        type: "setPickPosition",
        x,
        y,
        crs: "4326",
      };
      this.channel.postMessage(body);
    } else {
      console.log("User tried to set pickPosition but MC is not active.");
    }
  };
}

export default MappingCloudModel;
