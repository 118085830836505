import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import React from "react";
import { IntlProvider } from "react-intl";
import { Router } from "react-router";
import { translationMessages } from "./i18n";
import { inject } from "mobx-react";
import { observer as hooksObserver } from "mobx-react-lite";
import AppRoute from "./routes/AppRoute";
import { ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";

import { light } from "./layout/theme.js";
import { StyledEngineProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import SnackMessage from "./views/notifier/SnackMessage";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const App = ({ routingStore, uiStore: { locale } }) => {
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, routingStore);
  const useLocale = locale || "en";
  return (
    <IntlProvider locale={useLocale} messages={translationMessages[useLocale]}>
      <Router history={history}>
        <ThemeApp />
      </Router>
    </IntlProvider>
  );
};

const ThemeApp = hooksObserver(() => {
  /*const {
    settingsStore: { darkMode }
  } = useContext(StoresContext);*/

  let theme = light;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          content={(key, message) => {
            if (message.message) {
              return <SnackMessage id={key} {...message} />;
            }
          }}
        >
          <AppRoute />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default inject("routingStore", "uiStore")(App);
