import { MAP_TYPES, MAP_TILE_FORMAT } from "app-constants";

export const PRECIPITATION = {
  name: "PRECIPITATION",
  maxZoom: 20,
  minZoom: 3,
  type: MAP_TYPES.WMS,
  url: "http://geoservices.knmi.nl/cgi-bin/RADNL_OPER_R___25PCPRR_L3_dev.cgi?&SERVICE=WMS",
  version: "1.0.0",
  layers: "RADNL_OPER_R___25PCPRR_L3_COLOR",
  srs: "EPSG:3857",
  format: MAP_TILE_FORMAT.PNG,
  opacity: 0.35,
  transparent: true,
};

export const GIPOD_WO = {
  name: "GIPOD_WO",
  maxZoom: 20,
  minZoom: 13,
  type: MAP_TYPES.WMS,
  url: "https://geo.api.vlaanderen.be/gipodpubliek/wms?",
  version: "1.3.0",
  layers: "WoIcoonVndg",
  srs: "EPSG:3857",
  format: MAP_TILE_FORMAT.PNG,
  opacity: 1,
  transparent: true,
};
