import { withStyles } from "@mui/styles";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import styles from "./ShowResultsStyles";
import { Typography, Button, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio } from "@mui/material";
import { SubscriptionMessage } from "views/advanced-search/search-subscription-message";
import { SUBSCRIPTION_CODE } from "app-constants";
import { downloadPublicInvestigationShapeResults } from "services/api";

const ShowResults = hooksObserver(({ classes }) => {
  const {
    uiStore: { duplicateSpatialResearchResult, setDuplicateSpatialResearchResult, mapSpatialResearchResult, setMapSpatialResearchResult },
    applicationStore: { loading, publicInvestigationId, publicInvestigation: { titel, geoJsonData, lastResult, deduplicate } },
  } = useContext(StoresContext);

  const hasGeoJsonData = !!geoJsonData && !!Object.keys(geoJsonData).length;
  useEffect(() => {
    setMapSpatialResearchResult(false);
  }, [geoJsonData]);

  const handleDownloadShapeFile = async () => {
    await downloadPublicInvestigationShapeResults(publicInvestigationId, titel);
  };

  useEffect(() => {
    if (deduplicate) {
      setDuplicateSpatialResearchResult(true);
    }
  }, [deduplicate]);

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" gutterBottom>
        Omschrijving: {titel}
      </Typography>
      <SubscriptionMessage code={SUBSCRIPTION_CODE.EXPORT_EXTERNAL_APP} />
      <FormControl component="fieldset" style={{ marginTop: 20 }} disabled={!hasGeoJsonData}>
        <FormLabel component="legend">Weergave</FormLabel>
        <RadioGroup
          aria-label="mapResult"
          name="mapResult"
          value={mapSpatialResearchResult ? "map" : "table"}
          onChange={(event) => setMapSpatialResearchResult(event.target.value === "map")}
        >
          <FormControlLabel value="table" control={<Radio />} label="Toon tabel" />
          <FormControlLabel style={{ marginTop: -5 }} value="map" control={<Radio />} label="Toon kaart" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" style={{ marginTop: 20 }}>
        <FormLabel component="legend">Opties</FormLabel>
        <FormControlLabel
          control={<Checkbox checked={duplicateSpatialResearchResult} onChange={(event) => setDuplicateSpatialResearchResult(event.target.checked)} name="duplicateResult" />}
          label="Ontdubbelen i.f.v. aanschrijving"
          disabled={mapSpatialResearchResult}
        />
      </FormControl>
      <FormControl component="fieldset" style={{ marginTop: 20 }}>
        <FormLabel component="legend">Download</FormLabel>
        <Button style={{ marginTop: 10 }} disabled={!lastResult?.length || !hasGeoJsonData || loading} onClick={handleDownloadShapeFile} variant="outlined" size="small" fullWidth>
          Shape
        </Button>
      </FormControl>
    </div>
  );
});

ShowResults.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShowResults);
