import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import {
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  FormHelperText,
  Switch,
  FormControlLabel,
} from "@mui/material";
import styles from "./SidebarSettingsStyles";

const items = [
  "Bepaling van de hoegrootheid van opcentiemen",
  "Buitengewone herziening kis",
  "Heffen gemeentelijke belastingen",
  "Inventaris leegstand",
  "Inventaris onbebouwde percelen",
  "Invorderingsprocedures",
  "Onteigening",
  "Ruimtelijk Onderzoek",
  "Planbaten",
  "Planschade",
  "Toekennen premies en voordelen",
];

function SidebarSettings(props) {
  const { classes } = props;

  const [finaliteit, setFinaliteit] = useState("Ruimtelijk Onderzoek");
  const [actueleData, setActueleData] = useState(true);

  const handleFinaliteit = (e) => {
    setFinaliteit(e.target.value);
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <FormControl className={classes.textfield}>
          <InputLabel shrink htmlFor="age-label-placeholder">
            Kies uw finaliteit
          </InputLabel>
          <Select value={finaliteit} onChange={handleFinaliteit} input={<Input name="finaliteit" />} displayEmpty name="finaliteit">
            {items.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={actueleData}
              onChange={() => {
                setActueleData(!actueleData);
              }}
              value={actueleData}
            />
          }
          label={actueleData ? "Gebruik actuele data" : "Gebruik fiscale data"}
        />
      </div>
    </React.Fragment>
  );
}

SidebarSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarSettings);
