import React, { useState, useContext, useEffect, Fragment } from "react";
import { Button, Grid, Tabs, Tab, TextField } from "@mui/material";
import { StoresContext } from "contexts";
import { fetchDepartments, fetchSections, fetchParcels, fetchParcel, fetchDepartment, fetchSection } from "services/api";
import Autocomplete from "@mui/lab/Autocomplete";
import L from "leaflet";
import { withStyles } from "@mui/styles";
import styles from "./adpStyles";
import { APP_LEGGER_TYPES } from "app-constants";
import { ROUTE_PERCEEL } from "routes/RouteList";

const SearchCapakeyADP = ({ classes, type }) => {
  const {
    uiStore: { addToHistory, popupModel, popupModel: { getDataForCapakey, setIsMarkerOpened } },
    mapStore: { setBounds },
    routingStore: { push },
    applicationStore: { perceelInfoActief: { setCapakey }, search },
    authStore: { nis },
  } = useContext(StoresContext);

  const [address, setAddress] = useState(null);
  const [geometry, setGeometry] = useState(null);
  const [mode, setMode] = useState("actual");

  const [listOfDepartments, setListOfDepartments] = useState([]);
  const [listOfSections, setListOfSections] = useState([]);
  const [listOfParcels, setListOfParcels] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedParcel, setSelectedParcel] = useState(null);

  useEffect(() => {
    type === APP_LEGGER_TYPES.ACTUAL ? setMode("actual") : setMode("fiscal");
  }, [type]);

  useEffect(() => {
    console.log("mode changed to", mode);
    const asyncDepartments = async () => {
      const data = await fetchDepartments(nis, mode);
      setListOfDepartments(data.departments);
    };
    asyncDepartments();
  }, [mode]);

  useEffect(() => {
    setSelectedDepartment(null);
    setSelectedSection(null);
    setSelectedParcel(null);
    setAddress(null);
    setIsMarkerOpened(false);
  }, [listOfDepartments]);

  useEffect(() => {
    setSelectedSection(null);
    setSelectedParcel(null);
    setAddress(null);
    setIsMarkerOpened(false);
  }, [listOfSections]);

  useEffect(() => {
    setSelectedParcel(null);
    setAddress(null);
    setIsMarkerOpened(false);
  }, [listOfParcels]);

  useEffect(() => {
    if (!selectedDepartment) return;
    console.log("set selected department to", selectedDepartment);

    const loadSections = async () => {
      const [depData, secData] = await Promise.all([
        await fetchDepartment(nis, selectedDepartment.departmentCode, mode),
        await fetchSections(nis, selectedDepartment.departmentCode, mode),
      ]);
      // Laad de geometrie op kaart
      setGeometry(depData.geometry);
      // Steek de secties in de state
      setListOfSections(secData.sections);
    };

    loadSections();
  }, [selectedDepartment]);

  useEffect(() => {
    if (!selectedSection) return;
    console.log("set selected section to", selectedSection);

    const loadParcels = async () => {
      const [secData, parData] = await Promise.all([
        await fetchSection(nis, selectedDepartment.departmentCode, selectedSection.sectionCode, mode),
        await fetchParcels(nis, selectedDepartment.departmentCode, selectedSection.sectionCode, mode),
      ]);
      // Laad de geometrie op kaart
      setGeometry(secData.geometry);
      // Steek de percelen in de state
      setListOfParcels(parData.parcels);
    };

    loadParcels();
  }, [selectedSection]);

  useEffect(() => {
    if (!selectedParcel) return;
    console.log("set selected parcel to", selectedParcel);

    const loadParcel = async () => {
      const parData = await fetchParcel(nis, selectedDepartment.departmentCode, selectedSection.sectionCode, selectedParcel.perceelnummer, mode);
      setGeometry(parData.geometry);
      setAddress(parData.adres);
      search.setLoading(true);
      await getDataForCapakey(parData.capakey, false);
      search.setLoading(false);
      setTimeout(() => {
        setIsMarkerOpened(true);
      }, 150);
    };

    loadParcel();
  }, [selectedParcel]);

  useEffect(() => {
    if (geometry !== null) {
      const geoJson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: JSON.parse(geometry.boundingBox).coordinates,
        },
      };
      setBounds(L.geoJson(geoJson).getBounds());
    }
  }, [geometry]);

  const searchPerceel = async (leggerType) => {
    const capakey = leggerType === APP_LEGGER_TYPES.ACTUAL ? popupModel.actueleCapakey : popupModel.fiscaleCapakey;

    addToHistory(capakey);
    setCapakey(capakey);
    push(`${ROUTE_PERCEEL}${leggerType}/${encodeURIComponent(capakey.full)}`);
  };

  return (
    <div>
      <Grid container spacing={2} direction="column" alignItems="stretch">
        <Grid item>
          <Autocomplete
            id="departments"
            options={listOfDepartments}
            getOptionLabel={(option) => `${option.departmentName} - ${option.departmentCode}`}
            onChange={(e, value) => {
              setSelectedDepartment(value);
            }}
            value={selectedDepartment}
            renderInput={(params) => <TextField {...params} label="Afdeling" variant="outlined" fullWidth />}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            id="sections"
            options={listOfSections}
            getOptionLabel={(option) => option.sectionCode}
            onChange={(e, value) => {
              setSelectedSection(value);
            }}
            value={selectedSection}
            renderInput={(params) => <TextField {...params} label="Sectie" disabled={listOfSections.length === 0} variant="outlined" fullWidth />}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            id="parcels"
            options={listOfParcels}
            getOptionLabel={(option) => option.perceelnummer}
            onChange={(e, value) => {
              setSelectedParcel(value);
            }}
            value={selectedParcel}
            renderInput={(params) => <TextField {...params} label="Perceel" disabled={listOfParcels.length === 0} variant="outlined" fullWidth />}
          />
        </Grid>
        <Grid item data-test-id="li-zoekperceel-capakey">
          Capakey:{" "}
          <div>
            {selectedDepartment && selectedDepartment.departmentCode}
            {selectedSection && selectedSection.sectionCode}
            {selectedParcel && selectedParcel.perceelnummer}
          </div>
        </Grid>
        <Grid item data-test-id="li-zoekperceel-adres">
          Adres: {address !== null && address.length > 0 && address.map((address) => <div key={address}>{address}</div>)}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SearchCapakeyADP);
