import { toJS } from "mobx";

const textLabels = {
  body: {
    noMatch: "Sorry, no matching records found",
    toolTip: "Sort",
  },
  pagination: {
    next: "Next Page",
    previous: "Previous Page",
    rowsPerPage: "Rows per page:",
    displayRows: "of",
  },
  toolbar: {
    search: "Search",
    downloadCsv: "Download CSV",
    print: "Print",
    viewColumns: "View Columns",
    filterTable: "Filter Table",
  },
  filter: {
    all: "All",
    title: "FILTERS",
    reset: "RESET",
  },
  viewColumns: {
    title: "Show Columns",
    titleAria: "Show/Hide Table Columns",
  },
  selectedRows: {
    text: "row(s) selected",
    delete: "Delete",
    deleteAria: "Delete Selected Rows",
  },
};

const defaultOptions = {
  responsive: "stacked",
  filterType: "dropdown",
  pagination: true,
  textLabels,
  serverSideFilterList: [],
  expandableRows: false,
  expandableRowsOnClick: false,
  resizableColumns: false,
  selectableRows: "multiple",
  selectableRowsOnClick: false,
  selectableRowsHeader: true,
  caseSensitive: false,
  serverSide: false,
  rowHover: true,
  fixedHeader: true,
  elevation: 4,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 15, 100],
  filter: true,
  sortFilterList: true,
  sort: true,
  search: true,
  print: true,
  viewColumns: true,
  download: true,
  downloadOptions: {
    filename: "tableDownload.csv",
    separator: ",",
  },
};

const defaultColumnOption = {
  display: "true",
  empty: false,
  filter: true,
  sort: true,
  print: true,
  searchable: true,
  download: true,
  viewColumns: true,
  sortDirection: "none",
};

function createCSVDownload(columns, data, options) {
  options = { ...defaultOptions, ...options };
  const replaceDoubleQuoteInString = (columnData) => (typeof columnData === "string" ? columnData.replace(/\"/g, '""') : columnData);

  const buildHead = (columns) => {
    return (
      columns
        .reduce((soFar, column) => (column.download ? soFar + '"' + replaceDoubleQuoteInString(column.name) + '"' + options.downloadOptions.separator : soFar), "")
        .slice(0, -1) + "\r\n"
    );
  };
  const CSVHead = buildHead(columns);

  const buildBody = (data) => {
    if (!data.length) return "";
    return data
      .reduce((soFar, row) => {
        const myData = row.data === undefined ? row : row.data;
        return (
          soFar +
          '"' +
          myData
            .filter((_, index) => columns[index].download)
            .map((columnData) => replaceDoubleQuoteInString(columnData))
            .join('"' + options.downloadOptions.separator + '"') +
          '"\r\n'
        );
      }, [])
      .trim();
  };
  const CSVBody = buildBody(data);

  const csv = options.onDownload ? options.onDownload(buildHead, buildBody, columns, data) : `${CSVHead}${CSVBody}`.trim();

  if (options.onDownload && csv === false) {
    return;
  }

  const blob = new Blob([csv], { type: "text/csv" });

  /* taken from react-csv */
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, options.downloadOptions.filename);
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI = typeof URL.createObjectURL === "undefined" ? dataURI : URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.setAttribute("href", downloadURI);
    link.setAttribute("download", options.downloadOptions.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const firstLetterUpperCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export { createCSVDownload, defaultColumnOption, firstLetterUpperCase };
