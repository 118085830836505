import React from "react";
import { Drawer, Typography, Toolbar, List, ListItem, ListItemAvatar, Avatar, ListItemText, Accordion, AccordionSummary, FormGroup, FormControlLabel, FormLabel, FormControl, Switch, AccordionDetails, Badge, RadioGroup, Radio, Tooltip, IconButton, } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { observer as hooksObserver } from "mobx-react";
//@ts-ignore
import { injectIntl } from "react-intl";
import useLayerSelectorStyles from "./LayerSelectorStyles";
const LayerSelector = hooksObserver(({ classes: classesProp, baseLayers, neighboorhoodLayers, overlayLayers, thematicLayers, isOpen, onClose, title, messages, intl: { formatMessage } }) => {
    const classes = classesProp !== null && classesProp !== void 0 ? classesProp : useLayerSelectorStyles();
    return (React.createElement(Drawer, { classes: { root: classes.root, paper: classes.drawer }, anchor: "right", open: isOpen, onClose: onClose },
        React.createElement(Toolbar, { className: classes.toolbar },
            React.createElement(Typography, { variant: "h6", className: classes.toolbar, "data-test-id": "maps-sidebar-header" }, title)),
        React.createElement("div", { className: classes.content },
            baseLayers &&
                baseLayers.layers &&
                baseLayers.layers.length > 0 &&
                baseLayers.layers.map((layer, index) => (React.createElement(List, { key: "base-" + index, "data-test-id": "basemap-" + index },
                    React.createElement(ListItem, { button: true, selected: baseLayers.value === index, onClick: (event) => baseLayers.onChange(index) },
                        React.createElement(ListItemAvatar, null,
                            React.createElement(Avatar, { alt: layer.title, src: layer.avatar })),
                        React.createElement(ListItemText, { primary: layer.title }))))),
            !!(overlayLayers === null || overlayLayers === void 0 ? void 0 : overlayLayers.layers.length) && (React.createElement("div", { style: { padding: 15 } },
                React.createElement(FormControl, { component: "fieldset" },
                    React.createElement(FormLabel, { component: "legend" },
                        overlayLayers.title,
                        !!overlayLayers.info && (React.createElement(Tooltip, { title: overlayLayers.info },
                            React.createElement(IconButton, { "aria-label": "info", style: { padding: 5 } },
                                React.createElement(InfoOutlinedIcon, { fontSize: "small" }))))),
                    React.createElement(RadioGroup, { value: overlayLayers.value, "aria-label": "layerType", name: "layerType", className: classes.group, style: { marginLeft: 7 }, onChange: (event) => overlayLayers.onChange(event.target.value) }, overlayLayers === null || overlayLayers === void 0 ? void 0 : overlayLayers.layers.map((layer, index) => (React.createElement(FormControlLabel, { key: `overlay-${index}`, control: React.createElement(Radio, null), label: layer.label, value: layer.value }))))))),
            neighboorhoodLayers && neighboorhoodLayers.layers && (React.createElement(Accordion, null,
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), "data-test-id": "expand-thematic-maps" },
                    React.createElement(Typography, { className: classes.heading },
                        neighboorhoodLayers.title,
                        React.createElement(Badge, { className: classes.badge, badgeContent: neighboorhoodLayers.layers.length, color: "primary" }, " "))),
                React.createElement(AccordionDetails, { style: { flexDirection: "column" } }, neighboorhoodLayers.layers.map((layer, index) => (React.createElement(FormGroup, { key: layer.id, "data-test-id": "thematic-map" },
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { onChange: (event) => {
                                neighboorhoodLayers.onChange(layer.id, event.target.checked);
                            }, checked: layer.visible ? true : false, "data-test-id": "thematic-map-switch" }), 
                        //@ts-ignore
                        label: formatMessage(messages[layer.id.toLowerCase()]) }))))))),
            thematicLayers && thematicLayers.layers.length > 0 && (React.createElement(Accordion, null,
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                    React.createElement(Typography, { className: classes.heading },
                        thematicLayers.title,
                        React.createElement(Badge, { className: classes.badge, badgeContent: thematicLayers.layers.length, color: "primary" }, " "))),
                React.createElement(AccordionDetails, { style: { flexDirection: "column" } }, thematicLayers.layers.map((layer, index) => (React.createElement(FormGroup, { key: "thematiclayer-" + index },
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { onChange: (event) => {
                                thematicLayers.onChange(layer.id, event.target.checked);
                            }, checked: layer.visible ? true : false }), 
                        //@ts-ignore
                        label: layer.value.title }))))))))));
});
export default injectIntl(LayerSelector);
