import { Avatar, IconButton, Box, ListItem, ListItemAvatar, ListItemText, Typography, Divider, Grid, InputBase, List } from "@mui/material";
import { withStyles } from "@mui/styles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import PublicIcon from "@mui/icons-material/Public";
import SearchIcon from "@mui/icons-material/Search";
import { StoresContext } from "contexts";
import React, { useContext, useState, useEffect } from "react";
import { observer as hooksObserver } from "mobx-react-lite";
import styles from "./PublicInvestigationListStyles";
import { fetchPublicInvestigationGeom, fetchPublicInvestigations, fetchPublicInvestigationsV2 } from "services/api";
import { useDebouncedCallback } from "use-debounce";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { formatDate } from "utils/date";

// @todo: after releasing v2, remove v1 deprecated code
const PublicInvestigationList = hooksObserver(({ classes }) => {
  const OMV_V2 = window.env.OMV_V2 === "true";
  const {
    mapStore: { setLatLng },
    applicationStore: { publicInvestigation: { setShape } },
  } = useContext(StoresContext);

  const [publicInvestigations, setPublicInvestigations] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [search, setSearch] = useState("");

  const [debouncedFetchPublicInvestigations] = useDebouncedCallback(async (searchTerm) => {
    if (!OMV_V2) return;
    const getPublicInvestigations = async () => {
      const { data } = await fetchPublicInvestigationsV2({ searchTerm });
      const sorted = data?.sort((a, b) => {
        const aDate = new Date(a.submissionDate || "");
        const bDate = new Date(b.submissionDate || "");
        const dateComparison = bDate.getTime() - aDate.getTime();
        const projectnumComparison = (a.projectnum || "").localeCompare(b.projectnum || "");
        return dateComparison !== 0 ? dateComparison : projectnumComparison;
      });
      setPublicInvestigations(sorted);
    };
    getPublicInvestigations();
  }, 500);

  useEffect(() => {
    if (OMV_V2) {
      debouncedFetchPublicInvestigations();
      return;
    }

    // Fallback version 1
    const getPublicInvestigations = async () => {
      const { data } = await fetchPublicInvestigations();
      setPublicInvestigations(data);
    };
    getPublicInvestigations();
  }, []);

  useEffect(() => {
    if (OMV_V2) return;

    // Old version fallback
    if (publicInvestigations.length > 0) {
      const result = publicInvestigations.filter((investigation) => {
        if (investigation?.projectName.toLowerCase().indexOf(search.toLowerCase()) !== -1 || investigation?.projectNumber.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
          return investigation;
        }
      });
      setSearchItems(result);
    } else {
      setSearchItems([]);
    }
  }, [search, publicInvestigations]);

  const omvsToShow = OMV_V2 ? publicInvestigations : searchItems;

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid item>
        <Typography variant="subtitle2" gutterBottom>
          Zoek op naam of projectnummer en selecteer de gewenste omgevingsvergunning uit onderstaande lijst om ze op kaart weer te geven.
        </Typography>
        <Typography variant="caption" gutterBottom>
          Klik op Volgende om door te gaan.
        </Typography>
      </Grid>
      <Grid item>
        <div className={classes.searchWrapper}>
          <InputBase
            className={classes.input}
            placeholder="Zoeken in omgevingsvergunningen"
            inputProps={{ "aria-label": "Zoeken in omgevingsvergunningen" }}
            {...(OMV_V2
              ? { onChange: (e) => debouncedFetchPublicInvestigations(e.target.value?.replace(/[OMV_]/g, "") || "") }
              : { onChange: (e) => setSearch(e.target.value), value: search })}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton className={classes.iconButton} aria-label="search" color="primary">
            <SearchIcon />
          </IconButton>
        </div>
      </Grid>
      <Grid item>
        {omvsToShow.length > 0 && (
          <List role="list" className={classes.list} style={{ height: "calc(100vh - 477px)", padding: 0 }} dense>
            <AutoSizer>
              {({ width, height }) => (
                <>
                  <FixedSizeList height={height} width={width} itemSize={OMV_V2 ? 64 : 85} itemCount={omvsToShow.length} overscanCount={5}>
                    {ListItemRender({ omvsToShow, OMV_V2, setLatLng, setShape })}
                  </FixedSizeList>
                </>
              )}
            </AutoSizer>
          </List>
        )}
      </Grid>
    </Grid>
  );
});

const ListItemRender =
  ({ omvsToShow, OMV_V2, setLatLng, setShape }) =>
  ({ index, style }) => {
    const publicInvestigation = omvsToShow[index];
    return (
      <ListItem
        key={index}
        style={style}
        button
        onClick={async () => {
          // New version when enabled
          if (OMV_V2) {
            const { projectNum, center } = publicInvestigation;
            setLatLng({ lat: center[1], lng: center[0] });
            const feature = await fetchPublicInvestigationGeom({ projectNum });
            if (feature) setShape(feature.geometry);
            return;
          }

          // Fallback old version
          const {
            center: { coordinates },
            location,
          } = publicInvestigation;
          setLatLng({ lat: coordinates[1], lng: coordinates[0] });
          setShape(location);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <PublicIcon />
          </Avatar>
        </ListItemAvatar>
        {OMV_V2 ? (
          <ListItemText primary={publicInvestigation.projectNum} secondary={formatDate(publicInvestigation.submissionDate)} />
        ) : (
          <ListItemText sx={{ maxWidth: 240 }} primary={publicInvestigation.projectNumber} secondary={publicInvestigation.projectName} />
        )}
        <IconButton size="small">
          <ArrowRightIcon />
        </IconButton>
      </ListItem>
    );
  };

export default withStyles(styles)(PublicInvestigationList);
