import { makeStyles } from "@mui/styles";

export const useBottomActionStyles = makeStyles((theme) => ({
  root: {},
  selected: { color: "#fff" },
}));

const styles = (theme) => ({
  sidebarContent: {
    height: "calc(100% - 128px)",
  },
  bottomNav: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    ...theme.mixins.toolbar,
    marginBottom: "0px",
  },
});

export default styles;
