import React, { useContext, useState, useEffect, Fragment } from "react";
import { Grid, Tabs, Tab, Divider, List, ListItem, ListItemText, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { CustomLinearProgress } from "@orbit/components";
import { APP_LEGGER_TYPES } from "app-constants";
import PropTypes from "prop-types";
import { StoresContext } from "contexts";
import Autocomplete from "@mui/lab/Autocomplete";
import { toJS } from "mobx";
import { observer as hooksObserver } from "mobx-react-lite";
import SearchCapakeyADP from "views/advanced-search/search-capakey-by-adp";
import SearchCapakeyWeb from "views/advanced-search/search-capakey-by-web";
import styles, { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "./SidebarSearchByPersonStyles";

import { useParams } from "react-router";

const searchTypes = [
  {
    title: "Zoek via GRB perceel",
    form: ({ type }) => <SearchCapakeyADP type={type} />,
  },
  {
    title: "Zoek via Magda webservice",
    form: ({ type }) => <SearchCapakeyWeb type={type} />,
  },
];

const SidebarSearchCapakey = hooksObserver(({ classes }) => {
  const {
    uiStore: { popupModel: { setFiscaleData, setActueleData } },
    routingStore: { push },
    applicationStore: { search, perceelInfoActief: { setCapakey } },
  } = useContext(StoresContext);

  let { leggerType } = useParams();
  const [expanded, setExpanded] = useState(0);

  const handleExpansionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleChange(event, type) {
    push(type);
  }

  const LeggerTypeTabs = () => (
    <Tabs value={leggerType} indicatorColor="secondary" textColor="secondary" variant="fullWidth" onChange={handleChange}>
      <Tab label="Actueel" value={APP_LEGGER_TYPES.ACTUAL} />
      <Tab label="Fiscaal" value={APP_LEGGER_TYPES.FISCAL} data-test-id="tab-fiscal-sidebarsearchcapakey" />
    </Tabs>
  );

  return (
    <Fragment>
      <div>
        {searchTypes.map((searchType, index) => (
          <ExpansionPanel key={index} square expanded={expanded === index} onChange={handleExpansionChange(index)}>
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography>{searchType.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column">
                {index === 0 && (
                  <Grid item>
                    <LeggerTypeTabs />
                  </Grid>
                )}
                <Grid item className={classes.container}>
                  {searchType.form({
                    type: leggerType,
                  })}
                </Grid>
                {search.loading && <CustomLinearProgress />}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
      <Divider />
      <List className={classes.parcellist}>
        {search.parcels.map((parcel) => {
          return (
            <ListItem
              key={parcel.capakey}
              button
              onClick={() => {
                setFiscaleData();
                setActueleData();
                push("/perceelsidentificatie/" + APP_LEGGER_TYPES.ACTUAL + "/" + encodeURIComponent(parcel.capakey));
              }}
            >
              <ListItemText primary={parcel.capakey} />
              <IconButton size="small">
                <ArrowRightIcon />
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    </Fragment>
  );
});

export default withStyles(styles)(SidebarSearchCapakey);
