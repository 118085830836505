import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import { withStyles } from "@mui/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import React from "react";
import styles from "./StepperStyles";

const Stepper = (props) => {
  const { classes, activeStep, handleBack, handleNext, disableNext = false, disablePrevious = false } = props;

  return (
    <MobileStepper
      variant="progress"
      steps={6}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      disabled={disablePrevious}
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
          Vorige
        </Button>
      }
      nextButton={
        <Button size="small" onClick={handleNext} disabled={disableNext} color="secondary" data-test-id="btn-start-or-vorige">
          {activeStep === 0 ? "Start" : "Volgende"}
          <KeyboardArrowRight />
        </Button>
      }
    />
  );
};

export default withStyles(styles)(Stepper);
