import React from "react";
import Control from "react-leaflet-control";
import ZoomInIcon from "@mui/icons-material/Add";
import ZoomOutIcon from "@mui/icons-material/Remove";
import Fab from "@mui/material/Fab";
import useZoomButtonStyles from "./ZoomButtonStyles";
const ZoomButton = ({ canZoomIn, canZoomOut, zoom, position }) => {
    const classes = useZoomButtonStyles();
    return (React.createElement(Control, { position: position },
        React.createElement("div", null,
            React.createElement(Fab, { id: "zoom-in", disabled: !canZoomIn, color: "secondary", size: "small", className: classes.fab, onClick: () => zoom("zoomIn") },
                React.createElement(ZoomInIcon, null)),
            " "),
        React.createElement(Fab, { id: "zoom-out", color: "secondary", disabled: !canZoomOut, size: "small", className: classes.fab, onClick: () => zoom("zoomOut") },
            React.createElement(ZoomOutIcon, null))));
};
export default ZoomButton;
