import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction } from "@mui/material";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import styles from "./SidebarHomeStyles";
import InfoIcon from "@mui/icons-material/Info";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from "@mui/icons-material/Search";
import PublicIcon from "@mui/icons-material/Public";
import {
  ROUTE_PERCEELINFO,
  ROUTE_SEARCH_OWNER_TYPE,
  ROUTE_HISTORY,
  ROUTE_SETTINGS,
  ROUTE_PUBLIC_INVESTIGATION,
  ROUTE_PERCEEL,
  ROUTE_SEARCH_OWNER,
  ROUTE_SEARCH_CAPAKEY,
} from "routes/RouteList";
import { APP_LEGGER_TYPES } from "app-constants";

const SidebarHome = hooksObserver(({ classes }) => {
  const {
    routingStore: { push },
  } = useContext(StoresContext);

  return (
    <List>
      <ListItem
        button
        onClick={() => {
          push(ROUTE_PERCEEL);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <InfoIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Perceels identificatie" />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Perceels identificatie"
            onClick={() => {
              push(ROUTE_PERCEEL);
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        button
        onClick={() => {
          push(ROUTE_SEARCH_CAPAKEY + APP_LEGGER_TYPES.ACTUAL);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <SearchIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Zoeken op perceel" />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Zoeken op perceel"
            onClick={() => {
              push(ROUTE_SEARCH_CAPAKEY + APP_LEGGER_TYPES.ACTUAL);
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        button
        onClick={() => {
          push(ROUTE_SEARCH_OWNER + APP_LEGGER_TYPES.ACTUAL);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <SearchIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Zoeken op eigenaar" />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Zoeken op eigenaar"
            onClick={() => {
              push(ROUTE_SEARCH_OWNER + APP_LEGGER_TYPES.ACTUAL);
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        button
        onClick={() => {
          push(ROUTE_HISTORY);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <HistoryIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Geschiedenis" />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Geschiedenis"
            onClick={() => {
              push(ROUTE_HISTORY);
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        button
        onClick={() => {
          push(ROUTE_PUBLIC_INVESTIGATION);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <PublicIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Ruimtelijk Onderzoek" />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Ruimtelijk Onderzoek"
            onClick={() => {
              push(ROUTE_PUBLIC_INVESTIGATION);
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {/* <ListItem
        button
        onClick={() => {
          push(ROUTE_SETTINGS);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <SettingsIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Instellingen" />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="Instellingen">
            <ArrowRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem> */}
    </List>
  );
});

SidebarHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarHome);
