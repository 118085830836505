import React, { useState, useContext } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { StoresContext } from "contexts";
import SearchSubscriptionMessage from "./search-subscription-message";

const SearchPersonByINSZ = ({ type }) => {
  const {
    applicationStore: { search },
  } = useContext(StoresContext);

  const [insz, setInsz] = useState("");

  return (
    <form
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault();
        if (insz.length > 0) {
          search.searchPersonByInsz(type, insz);
        }
      }}
    >
      <Grid container spacing={2} direction="column" alignItems="stretch">
        <SearchSubscriptionMessage type={type} />
        <Grid item>
          <TextField required id="insz-required" label="Rijksregisternummer" variant="outlined" fullWidth onChange={(event) => setInsz(event.target.value)} />
        </Grid>
        <Grid item>
          <Button type="submit" variant="outlined" fullWidth data-test-id="btn-zoekop-personinsz">
            Zoek op
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchPersonByINSZ;
