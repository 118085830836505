import { Button, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "containers/partials/modals/ConfirmModal";

import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { ROUTE_PUBLIC_INVESTIGATION_PROCEDURE, ROUTE_PUBLIC_INVESTIGATION_RESULT } from "routes/RouteList";
import styles from "./PublicInvestigationHistoryStyles";
import { deletePublicInvestigationHistory } from "services/api";
import { toJS } from "mobx";
import { APP_LEGGER_TYPES, SUBSCRIPTION_CODE } from "app-constants";

const typeList = [
  "Type selecteren",
  "Contour zelf tekenen op kaart",
  "Percelen selecteren op kaart",
  "Omgevingsvergunning zoeken",
  "Geo dataset importeren",
  "Lijst met perceelnummers importeren",
];

const statusList = ["Selecteer databron", "Weergave op map", "Kies type opzoeking", "Kies type onderzoek", "Weergave percelen op kaart", "Weergave resultaten"];

const SpacialResearchStatusMap = {
  creating: { color: "primary", status: "In Opmaak" },
  ready: { color: "default", status: "In verwerking" },
  harvesting: { color: "orange", status: "In verwerking" },
  harvesting_give_company: { color: "orange", status: "In verwerking" },
  harvesting_register_person: { color: "orange", status: "In verwerking" },
  harvesting_give_person: { color: "orange", status: "In verwerking" },
  finished: { color: "green", status: "Afgerond" },
  finished_callback: { color: "green", status: "Afgerond met callback" },
  harvesting_callback: { color: "orange", status: "In verwerking met callback" },
  error: { color: "secondary", status: "Foutmelding" },
  error_no_parcels: { color: "secondary", status: "Foutmelding" },
  error_callback: { color: "secondary", status: "Callback gefaald" },
};

const PublicInvestigationHistory = hooksObserver(({ classes }) => {
  const {
    mapStore,
    applicationStore,
    applicationStore: {
      publicInvestigation,
      publicInvestigation: { load },
      publicInvestigationHistory,
      loadPublicInvestigationHistory,
      setPublicInvestigationId,
      resetPublicInvestigation,
    },
    authStore: { subscriptions },
    routingStore: { push },
  } = useContext(StoresContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    resetPublicInvestigation();
    loadPublicInvestigationHistory();
  }, []);

  useEffect(() => {
    setOpen(deleteItem ? true : false);
  }, [deleteItem]);

  const startPublicInvestigation = async (leggerType) => {
    console.log("startPublicInvestigation with leggerType", leggerType);
    resetPublicInvestigation(leggerType);
    push(ROUTE_PUBLIC_INVESTIGATION_PROCEDURE);
  };

  useEffect(() => {
    setOptions([
      {
        type: APP_LEGGER_TYPES.FISCAL,
        label: `Start een onderzoek met fiscale data`,
        disabled: !subscriptions.getSubscriptionByCode(SUBSCRIPTION_CODE.SEARCH_URBAIN),
      },
      {
        type: APP_LEGGER_TYPES.ACTUAL,
        label: "Start een onderzoek met actuele data",
        disabled: false,
      },
    ]);
  }, [subscriptions]);

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={open}
        title={"Verwijder Ruimtelijk Onderzoek"}
        description="Bent u zeker dat u dit onderzoek wil verwijderen? Dit kan niet ongedaan worden."
        cancelHandler={() => setDeleteItem(null)}
        confirmHandler={async () => {
          await deletePublicInvestigationHistory(deleteItem);
          loadPublicInvestigationHistory();
          setDeleteItem(null);
        }}
      />
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.container}>
        <Grid item className={classes.button}>
          <Button fullWidth color="secondary" variant="outlined" onClick={handleClickListItem}>
            Nieuw onderzoek starten ...
          </Button>
          <Menu id="lock-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {options.map((option, index) => (
              <MenuItem key={option.type} onClick={(event) => startPublicInvestigation(option.type)} disabled={option.disabled}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item className={classes.historyTitle}>
          <Typography variant="h5">Overzicht</Typography>
        </Grid>
        <Grid item className={classes.listcontainer}>
          <List className={classes.list}>
            <Divider component="li" />
            {publicInvestigationHistory.map((publicInvestigation) => {
              const user = publicInvestigation.userModified !== null ? publicInvestigation.userModified : publicInvestigation.userCreated;
              const t = publicInvestigation.userModified !== null ? publicInvestigation.dateModified.split(/[- : T Z]/) : publicInvestigation.dateCreated.split(/[- : T Z]/);
              const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
              let type = typeList[publicInvestigation.type];
              let status = statusList[publicInvestigation?.status];
              return (
                <React.Fragment key={publicInvestigation.id}>
                  <ListItem
                    alignItems="flex-start"
                    button
                    onClick={async () => {
                      if (publicInvestigation.status === 5) {
                        push(ROUTE_PUBLIC_INVESTIGATION_RESULT + `/${publicInvestigation.id}`);
                      }
                      applicationStore.setCanSave(false);
                      setPublicInvestigationId(publicInvestigation.id);
                      await load(publicInvestigation.id);
                      applicationStore.setCanSave(true);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h6" className={classes.piTitle}>
                          {publicInvestigation.titel}
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography variant="caption" gutterBottom>
                            {user} - {d.toLocaleDateString("nl-BE", { timeZone: "UTC" })}
                          </Typography>
                          <Chip
                            label={SpacialResearchStatusMap[publicInvestigation.researchStatus]?.status}
                            size="small"
                            variant="outlined"
                            // color={SpacialResearchStatusMap[publicInvestigation.researchStatus]?.color}
                            style={{
                              marginLeft: 5,
                            }}
                          />
                          <Typography variant="body2">
                            <strong>Type</strong>: {type}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Onderzoek</strong>: {publicInvestigation.leggerType}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Status</strong>: {status}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Type Opzoeking</strong>: {publicInvestigation.searchType === "owners" ? "Eigenaars" : "Bewoners"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemSecondaryAction style={{ marginRight: 5 }}>
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={async () => {
                          setDeleteItem(publicInvestigation.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default withStyles(styles)(PublicInvestigationHistory);
