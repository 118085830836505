import { Grid, ListItemText, Tab, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";
import { APP_LEGGER_TYPES } from "app-constants";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { ROUTE_PERCEEL } from "routes/RouteList";
import styles from "./SidebarParcelnfoStyles";
import { getMetadataForCapakey } from "services/api";
import { getArea, getPerimeter } from "utils/turf";
import { toJS } from "mobx";

const Row = ({ children }) => (
  <Grid container spacing={12} style={{ marginBottom: 10 }}>
    {children}
  </Grid>
);
const Col = ({ children, xs }) => (
  <Grid item xs={xs || 5} container>
    {children}
  </Grid>
);

const SidebarPerceelinfo = hooksObserver(({ match: { params: { capakey: urlCapakey, leggerType: urlLeggerType } } }) => {
  const {
    applicationStore: { perceelInfoActief: { capakey } },
    routingStore: { push },
    uiStore: { popupModel: { actueleCapakey, fiscaleCapakey, actuelePolygon, fiscalePolygon } },
  } = useContext(StoresContext);

  const [metadata, setMetadata] = useState({});
  const currentPolygon = urlLeggerType === APP_LEGGER_TYPES.ACTUAL ? toJS(actuelePolygon) : toJS(fiscalePolygon);

  useEffect(() => {
    if (!capakey?.full) return;
    const getData = async () => {
      const data = await getMetadataForCapakey(capakey.full);
      setMetadata(data);
    };
    getData();
  }, [capakey]);

  function handleChange(event, type) {
    push(`${ROUTE_PERCEEL}${type}/${encodeURIComponent(urlCapakey)}`);
  }
  return (
    <React.Fragment>
      <Tabs value={urlLeggerType} indicatorColor="secondary" textColor="secondary" variant="fullWidth" onChange={handleChange}>
        <Tab
          label="Actueel"
          value={APP_LEGGER_TYPES.ACTUAL}
          disabled={actueleCapakey.full === undefined || (urlLeggerType === APP_LEGGER_TYPES.ACTUAL && actueleCapakey.full !== fiscaleCapakey.full)}
        />
        <Tab
          label="Fiscaal"
          value={APP_LEGGER_TYPES.FISCAL}
          disabled={fiscaleCapakey.full === undefined || (urlLeggerType === APP_LEGGER_TYPES.FISCAL && actueleCapakey.full !== fiscaleCapakey.full)}
        />
      </Tabs>
      <div style={{ overflowY: "scroll", height: "100%" }}>
        <Grid container style={{ padding: 25 }}>
          <Row>
            <Col>
              <ListItemText secondary={capakey.full} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ListItemText primary="Kadastrale Afdeling" secondary={capakey.KadastraleAfdeling} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ListItemText primary="Sectie" secondary={capakey.Sectie} />
            </Col>
            <Col>
              <ListItemText primary="Grondnummer" secondary={capakey.Grondnummer} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ListItemText primary="Cijferexponent" secondary={capakey.Cijferexponent ? capakey.Cijferexponent : "000"} />
            </Col>
            <Col>
              <ListItemText primary="Letterexponent" secondary={capakey.Letterexponent} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ListItemText primary="Bisnummer" secondary={capakey.Bisnummer ? capakey.Bisnummer : 0} />
            </Col>
          </Row>
          {!!currentPolygon?.coordinates && (
            <Row>
              <Col>
                <ListItemText
                  primary="Oppervlakte"
                  secondary={
                    <>
                      {`${getArea(currentPolygon).toFixed(2)}m`}
                      <sup>2</sup>
                    </>
                  }
                />
              </Col>
              <Col>
                <ListItemText primary="Omtrek" secondary={`${getPerimeter(currentPolygon).toFixed(2)}m`} />
              </Col>
            </Row>
          )}
          {metadata.center && (
            <Row>
              <Col xs={12}>
                <ListItemText
                  primary="Coördinaten centroïde"
                  secondary={
                    <>
                      X: {metadata.center.lng?.toFixed(6)}
                      <br />
                      Y: {metadata.center.lat?.toFixed(6)}
                    </>
                  }
                />
              </Col>
            </Row>
          )}
          {metadata.adres && (
            <Row>
              <Col xs={12}>
                <ListItemText primary="Adres" secondary={
                  <>
                    {metadata.adres.map(adres => (<>{adres}<br /></>))}
                  </>
                } />
              </Col>
            </Row>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
});

export default withStyles(styles)(SidebarPerceelinfo);

export const parseCapaKeyToObject = (capaKey) => {
  // check if the capakey is in the correct format
  var re = new RegExp("\\d{5}[A-Z_]{1}\\d{4}\\/\\d{2}[A-Z_]\\d{3}");
  const [result] = capaKey.match(re) || [null];
  if (result === capaKey) {
    try {
      let kadastraleAfdeling = capaKey.substring(0, 5);
      let sectie = capaKey.substring(5, 6);
      let grondnummer = capaKey.substring(6, 10);
      let bisnummer = capaKey.substring(11, 13);
      let letterexponent = capaKey.substring(13, 14);
      let cijferexponent = capaKey.substring(14, 17);

      // watch out do not reorder these variables
      // magda service expect them to be in the correct order
      let identificatie = {
        KadastraleAfdeling: kadastraleAfdeling,
        Sectie: sectie,
        Grondnummer: grondnummer,
        ...(Number(bisnummer.replace(/^\D+/g, "")) > 0 && {
          Bisnummer: bisnummer,
        }),
        ...(Number(cijferexponent.replace(/^\D+/g, "")) > 0 && {
          Cijferexponent: cijferexponent,
        }),
        ...(letterexponent !== "_" && { Letterexponent: letterexponent }),
        Partitie: "P0000",
        full: capaKey,
      };

      return identificatie;
    } catch (error) {
      throw new Error("wrong format capaKey");
    }
  } else {
    throw new Error("wrong format capaKey");
  }
};
