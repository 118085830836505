import React from "react";
import Control from "react-leaflet-control";
import Fab from "@mui/material/Fab";
import useControlButtonStyles from "./ControlButtonStyles";
const ControlButton = ({ disabled, onClick, position, icon, id = Math.random() }) => {
    const classes = useControlButtonStyles();
    return (React.createElement(Control, { position: position },
        React.createElement(Fab, { disabled: disabled, id: id, color: "secondary", size: "small", className: classes.fab, onClick: onClick }, icon)));
};
export default ControlButton;
