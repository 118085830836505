import { Drawer, IconButton, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Route, Switch, useLocation, withRouter } from "react-router";
import { PERCEEL_INFO_PANEL_ENUM } from "stores/UiStore";
import {
  ROUTE_BASE,
  ROUTE_HISTORY,
  ROUTE_PERCEELINFO,
  ROUTE_PERSONS_PARCELS,
  ROUTE_PUBLIC_INVESTIGATION,
  ROUTE_PUBLIC_INVESTIGATION_PROCEDURE,
  ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW,
  ROUTE_PUBLIC_INVESTIGATION_RESULT,
  ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW,
  ROUTE_SEARCH_CAPAKEY_TYPE,
  ROUTE_SEARCH_OWNER_TYPE,
  ROUTE_SETTINGS,
} from "../../../routes/RouteList";
import SidebarHistory from "./SidebarHistory";
import SidebarHome from "./SidebarHome";
import SidebarPerceelinfo from "./SidebarParcelnfo";
import SidebarPersonsParcels from "./SidebarPersonsParcels";
import SidebarPublicInvestigation from "./SidebarPublicInvestigation";
import SidebarSearch from "./SidebarSearch";
import SidebarSearchCapakey from "./SidebarSearchCapakey";
import SidebarSettings from "./SidebarSettings";
import styles from "./SidebarStyles";
import { useWidth } from "hooks/useWidth";

const Sidebar = hooksObserver(({ classes, history }) => {
  const {
    routingStore: { goBack, push },
    uiStore: { sideBarOpen, setSideBarOpen, perceelInfoPanel, setPerceelInfoPanel },
  } = useContext(StoresContext);

  let location = useLocation();
  const width = useWidth();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={sideBarOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Switch>
          <Route exact path={ROUTE_BASE}>
            <Typography variant="h6" className={classes.whiteText}>
              Menu
            </Typography>
            <div className={classes.grow} />
            {["sm"].includes(width) && (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={(e) => {
                  setSideBarOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Route>
          <Route exact path={[ROUTE_PERSONS_PARCELS]}>
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                goBack(ROUTE_BASE);
              }}
              data-test-id="btn-close-owners"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.whiteText}>
              Eigendommen van persoon
            </Typography>
          </Route>
          <Route
            exact
            path={[
              ROUTE_PERCEELINFO,
              ROUTE_SEARCH_OWNER_TYPE,
              ROUTE_SEARCH_CAPAKEY_TYPE,
              ROUTE_HISTORY,
              ROUTE_SETTINGS,
              ROUTE_PUBLIC_INVESTIGATION,
              ROUTE_PUBLIC_INVESTIGATION_PROCEDURE,
              ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW,
              ROUTE_PUBLIC_INVESTIGATION_RESULT,
              ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW,
            ]}
          >
            <IconButton className={classes.homeButton} onClick={() => push(ROUTE_BASE)} data-test-id="btn-home">
              <HomeIcon />
            </IconButton>
            <IconButton
              className={classes.closeButton}
              data-test-id="btn-close"
              onClick={
                perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN || perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED
                  ? () => {
                      setPerceelInfoPanel(PERCEEL_INFO_PANEL_ENUM.MINIMIZED);
                      //push(ROUTE_BASE);
                      history.goBack();
                    }
                  : () => {
                      if (location.pathname.includes(ROUTE_PUBLIC_INVESTIGATION_PROCEDURE) || location.pathname.includes(ROUTE_PUBLIC_INVESTIGATION_RESULT)) {
                        push(ROUTE_PUBLIC_INVESTIGATION);
                      } else {
                        push(ROUTE_BASE);
                        //history.goBack();
                      }
                    }
              }
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.whiteText} style={{ width: "100%" }}>
              <Switch>
                <Route exact path={ROUTE_PERCEELINFO}>
                  Perceels identificatie
                </Route>
                <Route exact path={ROUTE_SEARCH_CAPAKEY_TYPE}>
                  Zoeken op perceel
                </Route>
                <Route exact path={ROUTE_SEARCH_OWNER_TYPE}>
                  Zoeken op eigenaar
                </Route>
                <Route exact path={ROUTE_PERSONS_PARCELS}>
                  Eigendommen van persoon
                </Route>
                <Route exact path={ROUTE_HISTORY}>
                  Overzicht
                </Route>
                <Route
                  exact
                  path={[
                    ROUTE_PUBLIC_INVESTIGATION,
                    ROUTE_PUBLIC_INVESTIGATION_PROCEDURE,
                    ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW,
                    ROUTE_PUBLIC_INVESTIGATION_RESULT,
                    ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW,
                  ]}
                >
                  Ruimtelijk Onderzoek
                </Route>
              </Switch>
            </Typography>
          </Route>
        </Switch>
      </div>
      <Switch>
        <Route exact path={ROUTE_BASE} component={SidebarHome} />
        <Route exact path={ROUTE_PERCEELINFO} component={SidebarPerceelinfo} />
        <Route exact path={ROUTE_SEARCH_OWNER_TYPE} component={SidebarSearch} />
        <Route exact path={ROUTE_SEARCH_CAPAKEY_TYPE} component={SidebarSearchCapakey} />
        <Route exact path={ROUTE_PERSONS_PARCELS} component={SidebarPersonsParcels} />
        <Route exact path={ROUTE_HISTORY} component={SidebarHistory} />
        <Route exact path={ROUTE_SETTINGS} component={SidebarSettings} />
        <Route
          exact
          path={[
            ROUTE_PUBLIC_INVESTIGATION,
            ROUTE_PUBLIC_INVESTIGATION_PROCEDURE,
            ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW,
            ROUTE_PUBLIC_INVESTIGATION_RESULT,
            ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW,
          ]}
          component={SidebarPublicInvestigation}
        />
      </Switch>
    </Drawer>
  );
});

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Sidebar));
