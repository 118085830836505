var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Icon, InputAdornment, Link, TextField, Typography } from "@mui/material";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
import Send from "@mui/icons-material/Send";
import useLoginStyles from "./LoginStyles";
require("../style/logo.css");
const Login = ({ messages, urlForgotPassword, doLogin, enableForgotPassword = true }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const classes = useLoginStyles();
    const validateEmail = () => {
        if (email.length < 1)
            return false;
        return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };
    const handleValidation = () => {
        return email.length > 0 && !validateEmail() && password.length > 0;
    };
    const handleKeyPress = (e) => {
        if (/enter/gi.test(e.key) && handleValidation()) {
            handleClick();
        }
    };
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { success, error } = yield doLogin(email, password);
        }
        catch (error) {
            console.log(error);
            setMessage((_a = messages.notFound) !== null && _a !== void 0 ? _a : "Combinatie e-mail en wachtwoord niet gekend.");
        }
    });
    return (React.createElement(Grid, { container: true, spacing: 0, alignItems: "center", justifyContent: "center", sx: { minHeight: "90vh" } },
        React.createElement(Card, { className: classes.card },
            React.createElement(CardHeader, { avatar: React.createElement(Icon, { className: "icon-logo-orbit-black" }), title: messages.title }),
            React.createElement(CardContent, null,
                React.createElement(TextField, { required: true, id: "email", inputProps: {
                        "data-test-id": "email",
                    }, label: messages.labelEmail, helperText: messages.labelEmailHelper, value: email, onChange: (e) => setEmail(e.target.value), onKeyDown: handleKeyPress, error: validateEmail(), margin: "normal", fullWidth: true, InputProps: {
                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                            React.createElement(Mail, null))),
                    } }),
                React.createElement(TextField, { required: true, id: "password", inputProps: {
                        "data-test-id": "password",
                    }, label: messages.labelPassword, value: password, type: "password", onChange: (e) => setPassword(e.target.value), onKeyDown: handleKeyPress, margin: "normal", fullWidth: true, InputProps: {
                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                            React.createElement(Lock, null))),
                    } }),
                React.createElement(Typography, { "data-test-id": "error-message", className: classes.loginError }, message)),
            React.createElement(CardActions, { className: classes.action },
                React.createElement(Button, { id: "btn_login", "data-test-id": "submit", onClick: (e) => handleClick(), disabled: !handleValidation(), color: "primary", 
                    //className={classes.button}
                    variant: "contained" },
                    React.createElement(Send, { className: classes.extendedIcon }),
                    messages.button),
                enableForgotPassword && (React.createElement(Typography, null,
                    React.createElement(Link, { "data-test-id": "forgot-link", href: urlForgotPassword, target: "_blank", className: classes.link }, messages.forgotPassword)))))));
};
export default Login;
