import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Grid, Tabs, Tab, Paper } from "@mui/material";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { LOGGING_MODE_ENUM } from "stores/models/LoggingModel";
import { styles, datePickerTheme } from "./SidebarLoggingStyles";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const SidebarHome = hooksObserver(({ classes }) => {
  const {
    routingStore: { push },
    applicationStore: { logging: { mode, setMode, startDate, endDate, setStartDate, setEndDate, loadLogging } },
  } = useContext(StoresContext);

  function handleChange(event, type) {
    setMode(type);
  }

  const LoggingTypeTabs = () => (
    <Tabs value={mode} indicatorColor="secondary" textColor="secondary" variant="fullWidth" onChange={handleChange}>
      <Tab label="Applicatie" value={LOGGING_MODE_ENUM.APPLICATION} />
      <Tab label="Magda" value={LOGGING_MODE_ENUM.MAGDA} />
    </Tabs>
  );

  const fetchLogs = async () => {
    await loadLogging();
  };

  useEffect(() => {
    console.log("date changed, fetch logs");
    fetchLogs();
  }, [startDate, endDate]);

  return (
    <Grid container direction="column">
      <Grid item>
        <LoggingTypeTabs />
      </Grid>
      <Grid item className={classes.datePicker}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={datePickerTheme}>
            <DateTimePicker
              className={classes.datepicker}
              autoOk
              ampm={false}
              value={startDate}
              onChange={setStartDate}
              maxDate={endDate}
              disableFuture
              label="Startdatum"
              fullWidth
              format="dd/MM/yyyy HH:mm"
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item className={classes.datePicker}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={datePickerTheme}>
            <DateTimePicker
              className={classes.datepicker}
              autoOk
              ampm={false}
              value={endDate}
              onChange={setEndDate}
              minDate={startDate}
              disableFuture
              label="Einddatum"
              fullWidth
              format="dd/MM/yyyy HH:mm"
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
});

SidebarHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarHome);
