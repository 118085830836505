import { AppBar, IconButton, InputBase, Tab, Tabs, Toolbar, Tooltip, Typography, Grid, Link, CardContent, Card, Checkbox, FormControlLabel } from "@mui/material";
import { withStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Lock from "@mui/icons-material/Lock";
import MinimizeIcon from "@mui/icons-material/Minimize";
import SearchIcon from "@mui/icons-material/Search";
import { CustomLinearProgress, HorizontalDataTable } from "@orbit/components";
import { APP_LEGGER_TYPES } from "app-constants";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ROUTE_SEARCH_OWNER } from "routes/RouteList";
import { createCSVDownload } from "services/utils";
import { PERCEEL_INFO_PANEL_ENUM, TOUR_ENUM } from "stores/UiStore";
import { useDebouncedCallback } from "use-debounce";
import { TableSubscriptionMessage } from "views/advanced-search/search-subscription-message";
import LongMenu from "./LongMenu";
import styles, { getMuiThemeEigenaars, getParcelInfoTableStyle } from "./PerceelInfoStyles";
import { toJS } from "mobx";

/**
 * depending on the role users can view some tabs or not
 */
const roleRestrictions = [
  { role: "Administrator", restricted: [] },
  { role: "Openbaar-onderzoek", restricted: [] },
  { role: "Onteigening", restricted: [] },
  { role: "Planbaten", restricted: [] },
  { role: "Planschade", restricted: [] },
  { role: "Toekennen-premies-en-voordelen", restricted: [] },
  { role: "Heffen-gemeentelijke-belastingen", restricted: [] },
  { role: "Invorderingsprocedures", restricted: [] },
  { role: "Inventaris-onbebouwde-percelen", restricted: [2] },
  { role: "Inventaris-leegstand", restricted: [] },
  {
    role: "Bepaling-van-de-hoegrootheid-van-opcentiemen",
    restricted: [0, 1, 2],
  },
  { role: "Buitengewone-herziening-kis", restricted: [1] },
];

const NotAllowedRowRenderer = () => {
  return (
    <Tooltip title="Geen toegang tot gegevens o.b.v. huidig profiel, klik voor meer uitleg.">
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Lock fontSize="small" style={{ opacity: 0.5 }} />
        </Grid>
        <Grid item>
          <Link color="primary" href="https://wiki.geoportaal.be/nl/Legger/Faq" target="_blank">
            {"Meer info"}
          </Link>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

const PerceelInfo = hooksObserver(({ classes }) => {
  const {
    uiStore: { perceelInfoPanelHeight, perceelInfoTableHeight, setPerceelInfoPanel, perceelInfoPanel, popupModel, setShowTour, showTour },
    authStore: { role },
    routingStore: { push },
    applicationStore: { perceelInfoActief: { patrimonyParcels, owners, residents, vkbo, capakey, loading, loadResidents, loadVkbo, personalInfo, loadPersonalInfo, setLoading } },
  } = useContext(StoresContext);

  let { leggerType } = useParams();

  const [tabValue, setTabValue] = useState(0);
  const [houseHolder, setHouseHolders] = useState(false);
  const [busNumberFilter, setBusNumberFilter] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const [debouncedCallback] = useDebouncedCallback(
    // function
    (mySearchValue) => {
      downloadCSVObject[tabValue] ? downloadCSVObject[tabValue].setSearchValue(mySearchValue) : null;
    },
    // delay in ms
    200,
  );

  useEffect(() => {
    debouncedCallback(searchValue);
  }, [searchValue]);

  /**
   * when tabindex changes we remove the search value
   */
  useEffect(() => {
    setSearchValue("");
    setHouseHolders(false);
    setBusNumberFilter(true);
  }, [tabValue]);

  /**
   * when capakey changes we reset the tab index to default 0
   */
  useEffect(() => {
    setTabValue(0);
  }, [capakey]);

  // when the owners tabs is choosen then we need te fetch the data
  // when there is allready loading we should stop loading via the cancel
  useEffect(() => {
    const getResidentsForCapakey = async (capakey) => {
      if (tabValue === 2 && !getTabRestriction(role, 2)) {
        await loadResidents(capakey.full, popupModel.actuelePolygon, houseHolder, busNumberFilter);
      }
    };
    getResidentsForCapakey(capakey);
  }, [tabValue, capakey, houseHolder, busNumberFilter]);

  const parcelColumns = patrimonyParcels.rowNames.map((value) => {
    return {
      name: value.name,
      options: {
        customBodyRender: (value, { rowData }) => {
          if (value === "n.v.t.") {
            return <NotAllowedRowRenderer />;
          } else {
            return value;
          }
        },
      },
    };
  });

  const ownerColumns = owners.rowNames.map((rowName) => {
    if (rowName.name === "Acties") {
      return {
        name: "Acties",
        options: {
          customBodyRender: (value, { rowData }) => {
            if (Array.isArray(rowData)) {
              const [partition, personId, ...rest] = rowData;
              console.log(rowData);
              const vkbo = rowData[7];
              return personId ? (
                <LongMenu
                  key={personId}
                  data-test-id="menu-actions-owner"
                  options={
                    vkbo === true
                      ? [
                          {
                            title: "Eigendommen opzoeken",
                            action: () => {
                              push(ROUTE_SEARCH_OWNER + leggerType + "/" + personId);
                            },
                          },
                          {
                            title: "VKBO",
                            action: async () => {
                              await loadVkbo(APP_LEGGER_TYPES.ACTUAL, personId);
                              setTabValue(3);
                              setLoading(false);
                            },
                          },
                        ]
                      : [
                          {
                            title: "Eigendommen opzoeken",
                            action: () => {
                              push(ROUTE_SEARCH_OWNER + leggerType + "/" + personId);
                            },
                          },
                          {
                            title: "Persoonsgegevens",
                            action: async () => {
                              await loadPersonalInfo(personId);
                              setTabValue(4);
                              setLoading(false);
                            },
                          },
                        ]
                  }
                />
              ) : null;
            }
          },
        },
      };
    } else if (rowName.name === "VKBO") {
      return {
        name: "VKBO",
        options: {
          customBodyRender: (value, { rowData }) => {
            if (value) {
              const [partial, insz] = rowData;
              return <CheckIcon />;
            }
          },
        },
      };
    } else if (rowName.name === "INSZ") {
      return {
        ...rowName,
        name: "INSZ of Ondernemingsnr.",
      };
    } else {
      return {
        name: rowName.name,
        options: {
          customBodyRender: (rowName, { rowData }) => {
            if (rowName === "n.v.t.") {
              return <NotAllowedRowRenderer />;
            } else {
              return rowName;
            }
          },
        },
      };
    }
  });

  const rowDataObject = {
    0: (mySearchValue = "") => patrimonyParcels.setSearchValue(mySearchValue),
    1: (mySearchValue = "") => owners.setSearchValue(mySearchValue),
    2: (mySearchValue = "") => residents.setSearchValue(mySearchValue),
    3: (mySearchValue = "") => vkbo.setSearchValue(mySearchValue),
    4: (mySearchValue = "") => personalInfo.setSearchValue(mySearchValue),
  };

  const downloadCSVObject = {
    0: patrimonyParcels,
    1: owners,
    2: residents,
    3: vkbo,
    4: personalInfo,
  };

  const changeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const getTabRestriction = (role, index) => {
    return roleRestrictions.find((roleRestriction) => roleRestriction.role.toLowerCase() === role.toLowerCase()).restricted.includes(index);
  };

  const options = {
    selectableRows: "none",
    download: false,
    print: false,
    filter: false,
    search: false,
    responsive: "standard", // scrollMaxHeight | scrollFullHeight | stacked
    sort: true,
    viewColumns: false,
    rowsPerPage: 25,
    tableBodyHeight: perceelInfoTableHeight,
    rowsPerPageOptions: [25, 50, 100],
    textLabels: {
      body: {
        noMatch: "Geen info gevonden...",
        toolTip: "Sorteren",
      },
      pagination: {
        rowsPerPage: "Rijen per pagina:",
        displayRows: "van",
      },
    },
  };

  return (
    <>
      <div className={classes.title}>
        <AppBar position="static" color="secondary" elevation={0}>
          <CustomLinearProgress style={{ opacity: loading ? 1 : 0 }} variant="query" color={"secondary"} />
          <Toolbar>
            <Typography className={classes.title} variant="h6" color="inherit" data-test-id="tour-capakey" id="tour-capakey" noWrap>
              Perceel {capakey.full}
            </Typography>
            <IconButton
              data-test-id="tour-parcelinfo"
              id="tour-parcelinfo"
              aria-label="Open tour"
              onClick={(e) => {
                if (showTour) {
                  setShowTour(false);
                } else {
                  setPerceelInfoPanel(PERCEEL_INFO_PANEL_ENUM.OPEN);
                  setShowTour(true, TOUR_ENUM.PERCEELINFO);
                }
              }}
            >
              <LiveHelpIcon style={{ color: "#ffffff" }} />
            </IconButton>
            <div className={classes.search} data-test-id="tour-search" id="tour-search">
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Zoeken in tabel..."
                data-test-id="txt-area-search-tabel"
                value={searchValue}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <Tooltip title="download als csv" aria-label="download als csv">
              <IconButton
                id="tour-download"
                data-test-id="tour-download"
                color="inherit"
                onClick={() => {
                  const { rowData, rowNames } = downloadCSVObject[tabValue];
                  createCSVDownload(rowNames, rowData, options);
                }}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
            {perceelInfoPanel !== PERCEEL_INFO_PANEL_ENUM.MINIMIZED && (
              <Tooltip title="minimize" aria-label="minimize">
                <IconButton data-test-id="tour-view-minimize" id="tour-view" color="inherit" onClick={(e) => setPerceelInfoPanel(PERCEEL_INFO_PANEL_ENUM.MINIMIZED)}>
                  <MinimizeIcon style={{ paddingBottom: 10 }} />
                </IconButton>
              </Tooltip>
            )}
            {perceelInfoPanel !== PERCEEL_INFO_PANEL_ENUM.OPEN && (
              <Tooltip title="halfscreen" aria-label="open">
                <IconButton data-test-id="tour-view-halfscreen" id="tour-view" color="inherit" onClick={(e) => setPerceelInfoPanel(PERCEEL_INFO_PANEL_ENUM.OPEN)}>
                  <FullscreenExitIcon />
                </IconButton>
              </Tooltip>
            )}

            {perceelInfoPanel !== PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED && (
              <Tooltip title="fullscreen" aria-label="open/maximize">
                <IconButton data-test-id="tour-view-fullscreen" id="tour-view" color="inherit" onClick={(e) => setPerceelInfoPanel(PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED)}>
                  <FullscreenIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
          <Toolbar className={classes.tabsToolbar}>
            <Tabs className={classes.tabs} value={tabValue} onChange={changeTab} color="inherit" variant="fullWidth">
              <Tab data-test-id="tour-table" id="tour-table" label="Patrimoniumpercelen" />
              <Tab label="Eigenaars" data-test-id="tbl-eigenaars" />
              <Tab label="Bewoners (actueel)" data-test-id="tbl-bewoners" />
              <Tab label="Vkbo (actueel)" disabled />
              <Tab label="Persoonsgegevens (actueel)" disabled />
            </Tabs>
          </Toolbar>
        </AppBar>

        {(tabValue === 0 || tabValue === 1) && <TableSubscriptionMessage type={leggerType} />}

        {(tabValue === 2 || tabValue === 3) && <TableSubscriptionMessage type={APP_LEGGER_TYPES.ACTUAL} />}

        <div
          style={{
            height: perceelInfoPanelHeight,
            overflow: "auto",
          }}
          id="parcel-table" /* mui-datatables style not in mui5 => public/table.css */
        >
          {!loading && (
            <>
              {tabValue === 0 && role && !getTabRestriction(role, 0) && (
                <HorizontalDataTable data={patrimonyParcels.rowData} columns={parcelColumns} options={options} muiTheme={getParcelInfoTableStyle} />
              )}
              {tabValue === 1 && role && !getTabRestriction(role, 1) && (
                <HorizontalDataTable data={owners.rowData} columns={ownerColumns} options={options} muiTheme={getMuiThemeEigenaars} />
              )}
              {tabValue === 2 && role && !getTabRestriction(role, 2) && (
                <HorizontalDataTable data={residents.rowData} columns={residents.rowNames} options={options} muiTheme={getParcelInfoTableStyle} />
              )}
              {tabValue === 3 && <HorizontalDataTable data={vkbo.rowData} columns={vkbo.rowNames} options={options} muiTheme={getParcelInfoTableStyle} />}
              {tabValue === 4 && <HorizontalDataTable data={personalInfo.rowData} columns={personalInfo.rowNames} options={options} muiTheme={getParcelInfoTableStyle} />}
              {role && getTabRestriction(role, tabValue) && (
                <Card style={{ height: "100%" }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom align="center">
                      Geen toegang met dit profiel.
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </>
          )}
        </div>

        {tabValue === 2 && perceelInfoPanel !== PERCEEL_INFO_PANEL_ENUM.MINIMIZED && (
          <div className={classes.houseHolderWrapper}>
            <FormControlLabel
              className={classes.houseHolder}
              control={<Checkbox checked={houseHolder} onChange={(event) => setHouseHolders(event.target.checked)} name="houseHolder" />}
              label="Enkel gezinshoofden"
              data-test-id="btn-bewoners-gezinshoofden"
            />
            <FormControlLabel
              className={classes.busNumber}
              control={<Checkbox checked={busNumberFilter} onChange={(event) => setBusNumberFilter(event.target.checked)} name="busNumber" />}
              label="Filter op busnummers"
            />
          </div>
        )}
      </div>
    </>
  );
});

export default withStyles(styles)(PerceelInfo);
