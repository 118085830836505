import { createTheme, alpha } from "@mui/material/styles";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  tabsToolbar: {
    minHeight: "48px",
    background: "#ffffff",
    margin: "0px 24px",
    padding: 0,
  },
  tabs: {
    backgroundColor: "#ffffff",
    color: "rgba(0,0,0,.87)",
    width: "100%",
    flexContainer: {
      scroller: {
        indicator: {
          display: "none",
        },
      },
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  houseHolderWrapper: {
    position: "absolute",
    width: 600,
    bottom: 2,
  },
  houseHolder: {},
  busNumber: {
    marginLeft: 20,
  },
});

export const getParcelInfoTableStyle = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          borderRadius: 0,
          height: "inherit",
        },
        responsiveScroll: {
          maxHeight: "none !important",
          height: "calc(100% - 63px)",
        },
      },
    },
  });

export const getMuiThemeEigenaars = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          borderRadius: 0,
          height: "inherit",
        },
        responsiveScroll: {
          maxHeight: "none !important",
          height: "calc(100% - 63px)",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          "& td:nth-last-child(-n+3)": {
            padding: "0 10px !important",
          },
        },
      },
    },
  });

export default styles;
