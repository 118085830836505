const orbitLocation = (result) =>
  result["orbit:the_geom"][0]["gml:MultiPolygon"][0]["gml:polygonMember"][0]["gml:Polygon"][0]["gml:outerBoundaryIs"][0]["gml:LinearRing"][0]["gml:coordinates"][0];

export const TOWING_SERVICE = {
  id: "TowingService",
  type: "orbit:takeldienst",
  getResult: orbitLocation,
};

export const SUBDIVISION_SERVICE = {
  id: "SubdivisionsService",
  type: "orbit:subdivisions",
  getResult: (result) => result["wfs:FeatureCollection"]["gml:featureMember"],
  getLocation: orbitLocation,
};
