import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import styles from "./ShowParcelsStyles";
import { TextField } from "@mui/material";
import { ROUTE_PUBLIC_INVESTIGATION_RESULT } from "routes/RouteList";
import { APP_LEGGER_TYPES } from "app-constants";
/**
 * 
 * @param {
 * Onderzoek met liggingsindicatie: Mogelijke opties (checkboxes):
Aangrenzende percelen ( A )
In een straal van 100m en niet aangrenzend ( I )
Buiten een straal van 100m (en binnen 300m) ( B )
Binnen de contour van de locatie(s) ( C )
Onderzoek met perimeter van <invulveld> meter
(Geen radio button) 
Daarnaast moet nog een knop komen “Selecteren op kaart” die onafhankelijk van de 
bovenste 2 opties uitgevoerd kan worden.}  
 */
const ShowParcels = hooksObserver(({ classes }) => {
  const {
    routingStore: { push },
    authStore: { email: defaultEmail },
    applicationStore: { publicInvestigationId, publicInvestigation, publicInvestigation: { email, setEmail, searchType } },
  } = useContext(StoresContext);

  useEffect(() => {
    setEmail(defaultEmail);
  }, [defaultEmail]);

  return (
    <div className={classes.root}>
      <Typography>De gevonden percelen worden opgelicht weergegeven op kaart.</Typography>
      <Typography>Je kan percelen toevoegen en weglaten door ze aan te klikken op kaart.</Typography>
      <Typography>Klik op onderstaande knop om voor deze percelen de gegevens te verzamelen.</Typography>
      {publicInvestigation.leggerType === APP_LEGGER_TYPES.ACTUAL && (
        <Typography>
          <strong>Let op:</strong> Dit kan enkele minuten duren. Je ontvangt een E-mail bericht op onderstaande account wanneer de gegevens klaar zijn.
        </Typography>
      )}

      <form
        autoComplete="onn"
        onSubmit={async (event) => {
          event.preventDefault();
          publicInvestigation.setStatus(5);
          await publicInvestigation.save(publicInvestigationId);
          push(`${ROUTE_PUBLIC_INVESTIGATION_RESULT}/${publicInvestigationId}`);
        }}
      >
        {publicInvestigation.leggerType === APP_LEGGER_TYPES.ACTUAL && (
          <TextField
            style={{ marginTop: 20 }}
            required
            id="email"
            variant="outlined"
            fullWidth
            value={email}
            label="Vul hier uw email in"
            placeholder="Vul hier uw email in"
            onChange={(event) => setEmail(event.target.value)}
          />
        )}
        <Button
          variant="outlined"
          type="submit"
          size="large"
          fullWidth
          style={{
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          Gegevens Verzamelen
        </Button>
      </form>
    </div>
  );
});

ShowParcels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShowParcels);
