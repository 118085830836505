import Login from "containers/login/Login";
import SelectProfile from "containers/users/SelectProfile";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, Fragment } from "react";
import { Redirect, Route } from "react-router";
import BasePage from "../containers/base/BasePage";
import { withErrorBoundary } from "../containers/partials/error-boundary/ErrorBoundary";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { StoresContext } from "contexts";
import {
  ROUTE_BASE,
  ROUTE_LOGGING,
  ROUTE_HISTORY,
  ROUTE_PERCEELINFO,
  ROUTE_PERSONS_PARCELS,
  ROUTE_PUBLIC_INVESTIGATION,
  ROUTE_PUBLIC_INVESTIGATION_OMV_VIEW,
  ROUTE_PUBLIC_INVESTIGATION_PROCEDURE,
  ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW,
  ROUTE_PUBLIC_INVESTIGATION_RESULT,
  ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW,
  ROUTE_SEARCH_OWNER_TYPE,
  ROUTE_SEARCH_CAPAKEY_TYPE,
  ROUTE_SELECTEER_PROFIEL,
  ROUTE_SETTINGS,
  ROUTE_LOGGING_MAGDA,
  ROUTE_LOGGING_GENERAL,
} from "./RouteList";
import LoggingPage from "containers/logging/LoggingPage";

const AppRoute = hooksObserver(() => {
  const {
    authStore: { role },
  } = useContext(StoresContext);

  return (
    <Fragment>
      <PublicRoutes>
        <Route exact path={ROUTE_BASE} component={Login} />
        <Route exact path={ROUTE_SELECTEER_PROFIEL} component={SelectProfile} />
        <Redirect to={ROUTE_BASE} />
      </PublicRoutes>
      {(role && role.toLowerCase() === "geoportaal-auditor" && (
        <PrivateRoutes>
          <Route exact path={[ROUTE_LOGGING, ROUTE_LOGGING_MAGDA, ROUTE_LOGGING_GENERAL, ROUTE_SELECTEER_PROFIEL]} component={LoggingPage} />
          <Redirect to={ROUTE_LOGGING} />
        </PrivateRoutes>
      )) || (
        <PrivateRoutes>
          <Route
            exact
            path={[
              ROUTE_BASE,
              ROUTE_PERCEELINFO,
              ROUTE_SEARCH_OWNER_TYPE,
              ROUTE_SEARCH_CAPAKEY_TYPE,
              ROUTE_PERSONS_PARCELS,
              ROUTE_HISTORY,
              ROUTE_SETTINGS,
              ROUTE_PUBLIC_INVESTIGATION,
              ROUTE_PUBLIC_INVESTIGATION_OMV_VIEW,
              ROUTE_PUBLIC_INVESTIGATION_PROCEDURE,
              ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW,
              ROUTE_PUBLIC_INVESTIGATION_RESULT,
              ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW,
              ROUTE_SELECTEER_PROFIEL,
            ]}
            component={BasePage}
          />
          <Redirect to={ROUTE_BASE} />
        </PrivateRoutes>
      )}
    </Fragment>
  );
});

export default withErrorBoundary(AppRoute);
