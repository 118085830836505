const useThreeDMCViewerStyles = (theme) => ({
  footprint: {
    pointerEvents: "none !important",
    stroke: "rgba(38,71,145,1) !important",
    strokeWidth: "1px !important",
    strokeOpacity: "1 !important",
    fill: "rgba(38,71,145,0.9019607843137255) !important",
    fillOpacity: "0.9019607843137255 !important",
    zIndex: 499,
    "&:hover": {
      stroke: "rgba(38,71,145,1) !important",
      strokeWidth: "1px !important",
      strokeOpacity: "1 !important",
      fill: "rgba(38,71,145,0.9019607843137255) !important",
      fillOpacity: "0.9019607843137255 !important",
    },
  },
  pickfootprint: {
    cursor: "crosshair",
    stroke: "rgba(38,71,145,1) !important",
    strokeWidth: "1px !important",
    strokeOpacity: "1 !important",
    fill: "rgba(38,71,145,0.9019607843137255) !important",
    fillOpacity: "0.9019607843137255 !important",
    zIndex: 499,
    "&:hover": {
      stroke: "rgba(38,71,145,1) !important",
      strokeWidth: "1px !important",
      strokeOpacity: "1 !important",
      fill: "rgba(38,71,145,0.9019607843137255) !important",
      fillOpacity: "0.9019607843137255 !important",
    },
  },
});

export default useThreeDMCViewerStyles;
