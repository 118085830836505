import * as publicLayers from "./publicLayers";
import * as neighboorhoodLayers from "./neighboorhoodLayers";
import React from "react";
import { Typography } from "@mui/material";

export { publicLayers, neighboorhoodLayers };
export default neighboorhoodLayers;

export const MESSAGE_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  UNKNOWN: "unknown",
};

export const publicInvestigationResearchTypes = [
  {
    label: <Typography variant="body2">Aangrenzende percelen (A)</Typography>,
    value: "A",
  },
  {
    label: <Typography variant="body2">In een straal van 100m en niet aangrenzend (I)</Typography>,
    value: "I",
  },
  {
    label: <Typography variant="body2">Buiten een straal van 100m (en binnen 300m) (B)</Typography>,
    value: "B",
  },
  {
    label: <Typography variant="body2">Binnen de contour van de locatie(s) (C)</Typography>,
    value: "C",
  },
];
