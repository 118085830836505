import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Map as LeafletMap, ScaleControl, withLeaflet } from "react-leaflet";
import ResizeObserver from "react-resize-observer";
import ZoomButton from "../ZoomButton/ZoomButton";
import useMapStyles from "./MapStyles";
const callAll = (...fns) => (...args) => fns.forEach((fn) => fn && fn(...args));
const Map = ({ children, zoom: { onZoom, currentLevel, minLevel, maxLevel }, latLng, bounds, logo, header, onClick, mapRef, onZoomend, onMoveend, onViewportChanged = () => { }, popupCloseFn = () => { }, customClass, style, }) => {
    const [zoomLevel, setZoomLevel] = useState(currentLevel);
    const classes = useMapStyles();
    //@ts-ignore
    const zoomend = ({ target: { _zoom } }) => {
        if (mapRef.current)
            setZoomLevel(_zoom);
    };
    const zoom = (type) => {
        if (type === "zoomIn")
            mapRef.current.leafletElement.zoomIn();
        else
            mapRef.current.leafletElement.zoomOut();
    };
    useEffect(() => {
        mapRef.current.leafletElement.attributionControl.options = {
            prefix: "",
        };
    }, []);
    useEffect(() => {
        mapRef.current.leafletElement.setZoom(currentLevel);
    }, [currentLevel]);
    useEffect(() => {
        if (mapRef.current)
            setZoomLevel(mapRef.current.leafletElement._zoom);
    }, [mapRef.current && mapRef.current.leafletElement._zoom]);
    const resize = () => {
        if (mapRef.current)
            mapRef.current.leafletElement.invalidateSize();
    };
    return (React.createElement(LeafletMap, { style: style ? style : {}, id: "map", ref: mapRef, className: classNames(header ? classes.header : classes.noHeader, customClass), zoomControl: false, center: latLng, zoom: currentLevel, maxZoom: maxLevel, onZoomend: callAll(onZoomend, zoomend), onMoveend: onMoveend, bounds: bounds, onClick: onClick, onPopupClose: (e) => {
            popupCloseFn();
        }, onViewportChanged: onViewportChanged },
        React.createElement(ScaleControl, { imperial: false }),
        React.createElement(ResizeObserver, { onResize: resize }),
        React.createElement(ZoomButton, { zoom: zoom, position: "topright", canZoomOut: minLevel < zoomLevel, canZoomIn: maxLevel > zoomLevel }),
        children,
        logo && logo.img && (React.createElement("a", { href: logo.url, target: "_blank" },
            React.createElement("img", { src: logo.img, alt: "Logo", className: classes.logo })))));
};
//@ts-ignore
export default withLeaflet(Map);
