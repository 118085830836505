import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { withStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { APP_LEGGER_TYPES } from "app-constants";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Draggable from "react-draggable";
import { injectIntl } from "react-intl";
import { ROUTE_PERCEEL, ROUTE_SEARCH_CAPAKEY, ROUTE_SEARCH_OWNER } from "routes/RouteList";
import styles from "./ModalViewStyles";
import { useParams } from "react-router";

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const ModalView = hooksObserver(({ classes, open, setOpen, intl: { formatMessage } }) => {
  let { leggerType = APP_LEGGER_TYPES.ACTUAL } = useParams();

  const {
    uiStore: { setSideBarOpen, addToHistory, popupModel, popupModel: { getDataForCapakey } },
    mapStore: { setBounds },
    routingStore: { push },
    applicationStore: { perceelInfoActief: { setCapakey } },
  } = useContext(StoresContext);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle className={classes.title} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="searchDialog" open={open} PaperComponent={PaperComponent}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
        Uitgebreid zoeken
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              size="large"
              variant="contained"
              color="inherit"
              className={classes.advancedSearchButton}
              onClick={() => {
                setSideBarOpen(true);
                push(ROUTE_SEARCH_CAPAKEY + leggerType);
              }}
            >
              Zoeken op perceel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="large"
              variant="contained"
              color="inherit"
              className={classes.advancedSearchButton}
              onClick={() => {
                setSideBarOpen(true);
                push(ROUTE_SEARCH_OWNER + leggerType);
              }}
            >
              Zoeken op eigenaar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

export default withStyles(styles)(injectIntl(ModalView));
