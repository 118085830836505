import { Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import { withStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { getLocationForCapakey, getLocationsForCapakeys } from "services/api";
import styles from "./CapakeyInputListStyles";
import { toJS } from "mobx";

const showParcel = async (capakey, setLatLng) => {
  const { lat, lng } = await getLocationForCapakey(capakey);
  setLatLng({ lat, lng });
};

const getParcelsWithLocation = async (capakeys) => {
  const parcels = {};
  if (capakeys.length > 0) {
    const data = await getLocationsForCapakeys(capakeys);
    data.features.forEach((item) => {
      const capakey = item.properties.CAPAKEY;
      parcels[capakey] = {
        type: "C",
        CAPAKEY: capakey,
        coordinates: item.geometry.coordinates,
      };
    });
  }

  return parcels;
};

const CapakeyInputList = hooksObserver(({ classes }) => {
  const {
    applicationStore: { publicInvestigation: { setSelectedParcels, selectedParcels } },
    mapStore: { setLatLng },
  } = useContext(StoresContext);

  const [enteredCapakeys, setEnteredCapakeys] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [length, setLength] = useState(enteredCapakeys.length);

  const validateCapakeys = (e, setter) => {
    const enteredCapakeys = [];
    setInputValue(e.target.value);
    e.target.value
      .replace(/ /gi, "")
      .replace(/,|\r|\n/gi, ";")
      .split(";")
      .forEach((capakey) => {
        const re = new RegExp("\\d{5}[A-Z_]{1}\\d{4}\\/\\d{2}[A-Z_]\\d{3}");
        const [result] = capakey.match(re) || [null];
        if (result === capakey && !enteredCapakeys.includes(capakey)) {
          enteredCapakeys.push(capakey);
        }
      });
    setter(enteredCapakeys);
  };

  const handleBlur = async () => {
    const parcels = await getParcelsWithLocation(enteredCapakeys);
    if (parcels) {
      setSelectedParcels(parcels);
    }
  };

  // @TODO: Bij het refreshen gebeurd de autosave nog voor deze effect (waarschijnlijk) en dus is de lijst leeg na een tweede refresh
  useEffect(() => {
    if (length !== enteredCapakeys.length || length === 0) {
      handleBlur();
      setLength(enteredCapakeys.length);
    }
  }, [enteredCapakeys]);

  useEffect(() => {
    if (Object.keys(selectedParcels).length > 0 && enteredCapakeys.length === 0) {
      let capakeys = "";
      Object.keys(selectedParcels).forEach((parcel) => (capakeys += `${parcel};`));
      validateCapakeys({ target: { value: capakeys } }, setEnteredCapakeys);
    }
  }, [selectedParcels]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom>
          Plak een lijst van perceelnummers gescheiden door een punt-komma (;) of komma (,) in onderstaand veld.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="outlined-multiline-static"
          label="Lijst met perceelnummers"
          fullWidth
          multiline
          rows="8"
          placeholder="Bijvoorbeeld: 44815F2207/00F000;44815F2254/00D002;44815F2317/00P000"
          margin="normal"
          variant="outlined"
          value={inputValue}
          onChange={(e) => {
            validateCapakeys(e, setEnteredCapakeys);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">Aantal percelen: {length}</Typography>
      </Grid>
      <Grid item xs={12}>
        <List dense className={classes.list}>
          {enteredCapakeys.map((capakey) => (
            <ListItem
              key={capakey}
              button
              onClick={() => {
                showParcel(capakey, setLatLng);
              }}
            >
              <ListItemText primary={capakey} />
              <ArrowRightIcon />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
});

export default withStyles(styles)(CapakeyInputList);
