/* eslint-disable func-names */
import { action, extendObservable, toJS } from "mobx";

const NotifierStore = function () {
  extendObservable(this, {
    notifications: [],

    enqueueSnackbar: action((note) => {
      this.notifications.push({
        key: new Date().getTime() + Math.random(),
        ...note,
      });
    }),

    removeSnackbar: action((key) => {
      function matchesEl(el, key) {
        return el.key === key;
      }
      this.notifications.splice(this.notifications.findIndex(matchesEl, key), 1);
    }),
  });
};

export default new NotifierStore();
