import axios from "axios";
import proj4 from "proj4";
import xml2js from "xml2js";
import { camelCase } from "camel-case";
import { polygon } from "@turf/helpers";

proj4.defs(
  "EPSG:31370",
  "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs",
);

let crss_lambert = {
  proj: "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs",
  unproj:
    "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs",
};

const parser = new xml2js.Parser({ attrkey: "attributes" });

const removeKeyPrefixes = (object) => {
  const objectStringified = JSON.stringify(object);
  let replaced = objectStringified
    .replace(/EL_ELEMENT_/g, "")
    .replace(/IV_/g, "")
    .replace(/FE_/g, "")
    .replace(/orbit:AF_/g, "")
    .replace(/AF_/g, "")
    .replace(/DO_/g, "")
    .replace(/EL_/g, "")
    .replace(/FE_/g, "")
    .replace(/orbit:AF_/g, "")
    .replace(/orbit:DO_/g, "")
    .replace(/orbit:EL_/g, "")
    .replace(/orbit:FE_/g, "")
    .replace(/orbit:/g, "");

  return JSON.parse(replaced);
};

const cleanUpResult = (data, getLocation) => {
  const cleanedKeysObject = removeKeyPrefixes(data);
  const cleanedObject = {};
  Object.entries(cleanedKeysObject).forEach((entry) => {
    const [key, value] = entry;
    const type = camelCase(key);
    cleanedObject[type] = value;
  });
  cleanedObject.location = getLocation(data);

  return cleanedObject;
};

const wktToBase84 = (key) => (data) => {
  const coordinates =
    data["orbit:the_geom"][0]["gml:MultiPolygon"][0]["gml:polygonMember"][0]["gml:Polygon"][0]["gml:outerBoundaryIs"][0]["gml:LinearRing"][0]["gml:coordinates"][0];

  const resultPoints = coordinates.split(" ").map((item) => item.split(","));
  const convertedPoints = resultPoints.map((result) => proj4(crss_lambert.unproj, crss_lambert.proj, [parseFloat(result[0]), parseFloat(result[1])]));

  return polygon([convertedPoints]);
};

export const getData = async (libType) => {
  const url = `${window.env.MAPCENTIA_URL_WFS}?request=GetFeature&TYPENAME=${libType.type}`;
  try {
    const result = await axios.get(url);
    let data = [];
    parser.parseString(result.data, function (err, parseResult) {
      const resultSet = libType.getResult(parseResult);

      data = resultSet.map((perc) => {
        const newData = cleanUpResult(perc[libType.type][0], wktToBase84(libType.type));
        return newData;
      });
    });
    return data;
  } catch (error) {
    console.log("something went wrong with fetching ", libType.type, error.toString());
  }
};
