const styles = (theme) => ({
  container: {
    height: "100%",
  },
  button: {
    padding: 16,
    height: 68,
  },
  historyTitle: {
    paddingLeft: 16,
    paddingBottom: 16,
  },
  listcontainer: {
    height: "calc(100% - 117px)",
    overflow: "hidden",
    overflowY: "auto",
  },
  list: {
    padding: 0,
  },
  piTitle: {
    wordBreak: "break-word",
  },
});

export default styles;
