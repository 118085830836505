import { action, observable, toJS, flow, reaction } from "mobx";
import { getParcelInfo, getResidents, searchCompanyByNumber, searchActivePersonByInsz } from "services/api";
import TableModel from "./TableModel";
import { APP_LEGGER_TYPES } from "app-constants";

export default class PerceelInfoModel {
  uiStore;

  @observable
  searchValue = "";

  @observable
  loading = false;

  @observable
  capakey = {};

  @observable
  polygon = {};

  @observable
  patrimonyParcels = new TableModel();

  @observable
  owners = new TableModel();

  @observable
  residents = new TableModel();

  @observable
  vkbo = new TableModel();

  @observable
  personalInfo = new TableModel();

  @observable
  leggerType;

  @observable
  houseHolder = false;

  constructor() {
    const reaction1 = reaction(
      () => this.loading,
      (loading) => {
        // console.log("loading 1:", loading);
      },
    );
  }

  @action.bound
  loadParcelInfo = flow(function* (capakey, leggerType) {
    this.capakey = typeof capakey === "object" ? capakey : { full: capakey };
    this.loading = true;
    this.leggerType = leggerType;
    this.patrimonyParcels.insert([]);
    this.owners.insert([]);
    this.resetOtherTabs();
    try {
      const {
        data: { PatrimoniumPercelen, Eigenaars },
      } = yield getParcelInfo(this.capakey.full, this.leggerType);

      PatrimoniumPercelen.sort((a, b) => (a.Partitie > b.Partitie ? 1 : -1));
      Eigenaars.sort((a, b) => (a.Partitie > b.Partitie ? 1 : -1));

      this.patrimonyParcels.insert(PatrimoniumPercelen);
      this.owners.insert(Eigenaars);

      this.loading = false;
      return true;
    } catch (error) {
      return false;
    }
  });

  @action.bound
  loadResidents = flow(function* (capakey, shape, houseHolder, busNumberFilter) {
    this.loading = true;
    const { data } = yield getResidents(this.capakey.full, shape, houseHolder, busNumberFilter);
    this.residents.insert(data);
    this.loading = false;
  });

  @action.bound
  loadVkbo = flow(function* (type, insz) {
    this.loading = true;
    this.vkbo.insert([]);
    const { data } = yield searchCompanyByNumber(APP_LEGGER_TYPES.ACTUAL, insz);

    if (data && data[0]) {
      const {
        Ondernemingsnummer,
        MaatschappelijkeNaam,
        description: { Huisnummer, Busnummer, Descripties: { Descriptie }, Gemeente: { PostCode } },
      } = data[0];

      const Adres = Descriptie[0].Adres;
      const Contact = Descriptie[0].Contact;
      const Straat = Adres.Straat.Naam;
      const Gemeente = Adres.Gemeente.Naam;

      const companyInfo = {
        MaatschappelijkeNaam,
        Ondernemingsnummer,
        Straat,
        Huisnummer,
        PostCode,
        Gemeente,
        Busnummer: Busnummer ? Busnummer : "",
        Telefoonnummer: Contact && Contact.Telefoonnummer,
        Email: Contact && Contact.Email,
        Website: Contact && Contact.Website,
      };

      this.vkbo.insert([companyInfo]);
      this.loading = false;
    }
  });

  @action.bound
  loadPersonalInfo = flow(function* (insz) {
    this.loading = true;

    const { data } = yield searchActivePersonByInsz(insz);

    if (data && data[0]) {
      this.personalInfo.insert(data);
      this.loading = false;
    }
  });

  resetOtherTabs = () => {
    this.residents.insert([]);
    this.personalInfo.insert([]);
    this.vkbo.insert([]);
  };

  @action
  searchParcelInfo = (e) => {
    this.searchValue = e.target.value;
  };

  @action
  setCapakey = (value) => {
    console.log("setCapaKEY", value);
    this.capakey = value;
  };

  @action
  setLoading = (value) => {
    console.log("set loading to false");
    this.loading = value;
  };
}
