import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText } from "@mui/material";
import { withStyles } from "@mui/styles";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { ROUTE_BASE, ROUTE_SELECTEER_PROFIEL } from "routes/RouteList";
import { getUserRights } from "services/auth";
import { firstLetterUpperCase } from "services/utils";
import styles from "./SelectProfileStyles";

const AUTH_URL = window.env.AUTH_URL;

const SelectProfile = hooksObserver(({ open, canClose, classes, intl: { formatMessage } }) => {
  const {
    uiStore: { setDialogSelectProfileOpen, dialogSelectProfileOpen },
    authStore: { availableProfiles, setAvailableProfiles, sessionId, setSessionId, setSelectingProfile },
    authStore,
    routingStore: { push, location },
  } = useContext(StoresContext);

  useEffect(() => {
    const fetchRights = async (urlParams) => {
      if (urlParams.has("id")) {
        const id = urlParams.get("id");
        setSessionId(id);
        const rights = await getUserRights(id);
        setAvailableProfiles(rights);
      }
      if (authStore.sessionId === null) {
        setDialogSelectProfileOpen(true);
        //push(ROUTE_BASE);
      } else {
        setDialogSelectProfileOpen(true);
        push(ROUTE_SELECTEER_PROFIEL);
      }
    };
    const urlParams = new URLSearchParams(location.search);
    fetchRights(urlParams);
    setSelectingProfile(true);
  }, []);

  const selectProfile = async (right) => {
    window.location.href = `${AUTH_URL}userights?s=${right.scope}&r=${right.role}&id=${sessionId}`;
  };

  return (
    <Dialog open={open || dialogSelectProfileOpen} onClose={(e) => (canClose ? setDialogSelectProfileOpen(false) : "")}>
      <DialogTitle className={classes.dialogTitle}>Selecteer profiel</DialogTitle>
      <DialogContent>
        <List>
          {availableProfiles.map((profile, index) => (
            <ListItem
              key={index}
              button
              onClick={(e) => {
                selectProfile(profile);
              }}
            >
              <ListItemText primary={firstLetterUpperCase(profile.role)} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
});
export default withRouter(injectIntl(withStyles(styles)(SelectProfile)));
