import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useMapStyles = () => {
    const theme = useTheme();
    return makeStyles({
        noHeader: {
            width: "100%",
            height: "calc(100vh)",
        },
        header: {
            width: "100%",
            height: "calc(100vh - 64px)",
        },
        logo: {
            position: "absolute",
            bottom: "30px",
            right: "20px",
            height: "40px",
            opacity: 0.8,
            zIndex: 1000,
        },
    })();
};
export default useMapStyles;
