import React, { useState, useContext, useEffect } from "react";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { APP_LEGGER_TYPES, SUBSCRIPTION_CODE } from "app-constants";
import { StoresContext } from "contexts";
import SubscriptionMessage from "./search-subscription-message";
import SearchSubscriptionMessage from "./search-subscription-message";
import Autocomplete from "@mui/material/Autocomplete";

const SearchPerson = ({ type }) => {
  const {
    applicationStore: { search },
    authStore: { subscriptions },
  } = useContext(StoresContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [year, setYear] = useState();

  useEffect(() => {
    if (type === APP_LEGGER_TYPES.ACTUAL) {
      setYear("1980");
    } else {
      setYear(undefined);
    }
  }, [type]);

  return (
    <div>
      <form
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
          if (firstName.length > 0 && lastName.length > 0) {
            search.searchPerson(type, firstName, lastName, year);
          }
        }}
      >
        <Grid container spacing={2} direction="column" alignItems="stretch">
          <SearchSubscriptionMessage type={type} />
          <Grid item>
            <TextField required id="firstname" label="Voornaam" variant="outlined" fullWidth onChange={(event) => setFirstName(event.target.value)} />
          </Grid>
          <Grid item>
            <TextField required id="lastname" label="Familienaam" variant="outlined" fullWidth onChange={(event) => setLastName(event.target.value)} />
          </Grid>
          {type === APP_LEGGER_TYPES.ACTUAL && (
            <Grid item>
              <Autocomplete
                id="country-select-demo"
                // helperText="Selecteer het geboortejaar"
                variant="outlined"
                options={getNumberOfYears(100)}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  if (newValue) {
                    setYear(newValue.value);
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Geboortejaar"
                    required
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item>
            <Button type="submit" variant="outlined" fullWidth data-test-id="btn-zoekop-person">
              Zoek op
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const getNumberOfYears = function (yearsToGet) {
  const startyear = new Date().getFullYear();
  const years = [];
  for (let i = startyear; i > startyear - yearsToGet; i--) {
    years.push({ label: i.toString(), value: i });
  }
  return years;
};

export default SearchPerson;
