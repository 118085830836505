import React, { Fragment, useContext, createContext } from "react";
import { Typography, Box, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Popup, _MapContext as MapContext } from "react-map-gl";
import useMapPopupStyles from "./MapPopupStyles";
const PopupContext = createContext({
    onClose: (e) => {
        console.log("close");
    },
    lng: 0,
    lat: 0,
    canCloseTooltip: true,
    offSetTop: 20,
});
const MapPopup = ({ children, onClose = (e) => { }, lng, lat, canCloseTooltip, offSetTop = 0 }) => {
    return (React.createElement(Popup, { key: "popup", longitude: lng, latitude: lat, closeOnClick: canCloseTooltip, offsetTop: offSetTop, closeButton: false, onClose: onClose },
        React.createElement(PopupContext.Provider, { value: { onClose, lng, lat, canCloseTooltip, offSetTop } },
            children,
            " ")));
};
const PopupHeader = (props) => {
    const { children } = props;
    const { onClose } = useContext(PopupContext);
    const mapContext = useContext(MapContext);
    const classes = useMapPopupStyles();
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: classes.toolbar },
            React.createElement(Typography, { variant: "h6", "data-test-id": "popup-header" },
                React.createElement(Box, { textAlign: "justify", m: 1 }, children)),
            React.createElement("span", { className: classes.grow }),
            React.createElement(IconButton, { onClick: (evt) => {
                    onClose(evt);
                    if (mapContext.eventManager)
                        mapContext.eventManager.once("click", (e) => e.stopPropagation(), evt.target);
                } },
                React.createElement(CloseIcon, null))),
        React.createElement(Divider, null),
        React.createElement("br", null)));
};
const PopupContent = ({ children }) => React.createElement("div", { "data-test-id": "popup-content" }, children);
const PopupFooter = ({ children }) => (React.createElement("div", { "data-test-id": "popup-footer" },
    React.createElement("br", null),
    React.createElement(Divider, null),
    " ",
    children));
MapPopup.Header = PopupHeader;
MapPopup.Content = PopupContent;
MapPopup.Footer = PopupFooter;
export default MapPopup;
