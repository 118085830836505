import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { withStyles } from "@mui/styles";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { PUBLIC_INVESTIGATION_SEARCH_TYPE } from "stores/models/PublicInvestigationModel";
import styles from "./SelectSearchTypeStyles";
import { APP_LEGGER_TYPES } from "app-constants";
import { Checkbox } from "@mui/material";
/**
 * 
 * @param {
 * Onderzoek met liggingsindicatie: Mogelijke opties (checkboxes):
Aangrenzende percelen ( A )
In een straal van 100m en niet aangrenzend ( I )
Buiten een straal van 100m (en binnen 300m) ( B )
Binnen de contour van de locatie(s) ( C )
Onderzoek met perimeter van <invulveld> meter
(Geen radio button) 
Daarnaast moet nog een knop komen “Selecteren op kaart” die onafhankelijk van de 
bovenste 2 opties uitgevoerd kan worden.}  
 */
const SelectSearchType = hooksObserver(({ classes }) => {
  const {
    applicationStore: { publicInvestigation: { searchType, setSearchType, leggerType, setHouseHolder, houseHolder, setDeduplicate, deduplicate } },
  } = useContext(StoresContext);

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <RadioGroup
          value={searchType}
          aria-label="searchtype"
          name="searchType"
          className={classes.group}
          onChange={(event) => {
            setSearchType(event.target.value);
          }}
        >
          <FormControlLabel selected control={<Radio />} label="Eigenaars" value={PUBLIC_INVESTIGATION_SEARCH_TYPE.OWNERS} />
          <FormControlLabel
            control={<Checkbox checked={deduplicate} onChange={(event) => setDeduplicate(event.target.checked)} name="duplicateResult" />}
            label="Ontdubbelen i.f.v. aanschrijving"
            disabled={searchType === PUBLIC_INVESTIGATION_SEARCH_TYPE.RESIDENTS}
          />
          <FormControlLabel disabled={leggerType === APP_LEGGER_TYPES.FISCAL} value={PUBLIC_INVESTIGATION_SEARCH_TYPE.RESIDENTS} control={<Radio />} label="Bewoners" />
        </RadioGroup>

        <FormControlLabel
          disabled={searchType === PUBLIC_INVESTIGATION_SEARCH_TYPE.OWNERS}
          control={<Checkbox checked={houseHolder} onChange={(event) => setHouseHolder(event.target.checked)} name="checkedF" />}
          label="Enkel gezinshoofden"
        />
      </FormControl>
    </div>
  );
});

SelectSearchType.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectSearchType);
