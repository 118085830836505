export const APP_LOCALES = {
  NL: "nl",
  FR: "fr",
};

export const APP_LEGGER_TYPES = {
  ACTUAL: "actueel",
  FISCAL: "fiscaal",
};

export const DEFAULT_LOCALE = APP_LOCALES.NL;

export const MAP_TYPES = {
  WMTS: "wmts",
  TILE: "tile",
  WMS: "wms",
};

export const MAP_TILE_MATRIX = {
  GOOGLE: "GoogleMapsVL",
  G20: "g20",
};

export const MAP_TILE_FORMAT = {
  PNG: "image/png",
};

export const MAP_CONFIG = {
  zoom: window.env.MAP_WMTS_TILE_LAYER_DEFAULT_ZOOM || 15,
};

export const HEADER_CONFIG = {
  SEARCH_ENABLED: window.env.SEARCH_ENABLED || true,
  SEARCH_RESULT_COUNT: 8,
};

export const SIDEBAR_CONFIG = {
  width: 350,
};

export const Z_INDEXES = {
  MAP_PUBLIC_WMTS_TILE_LAYER: 100,
  MAP_PUBLIC_TILE_LAYER: 101,
  MAP_PUBLIC_WMTS_TILE_LAYER_ORG: 102,
  MAP_ORBIT_LOGO: 1000,
};

export const SUBSCRIPTION_CODE = {
  MOBILE_MAPPING: 1,
  SEARCH_BY_OWNER: 2,
  SEARCH_ON_MAP: 3,
  EXPORT_EXTERNAL_APP: 4,
  SEARCH_BY_PARCEL: 5,
  SEARCH_URBAIN: 6,
  SEARCH_OMV: 7,
  PUBLIC_INVESTIGATION: 8,
  SEARCH_BY_MAGDA: 9,
};
