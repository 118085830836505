import { action, observable } from "mobx";
import { getLocationDataForCapakey, getDataForLocationFromLatLng } from "services/api";

class PopupModel {
  @observable isMarkerOpened = false;

  @observable loading = false;

  @observable capakey;

  @observable actueleCapakey = {};

  @observable actuelePolygon = {};

  @observable fiscaleCapakey = {};

  @observable fiscalePolygon = {};

  @observable position;

  @action
  getDataForLocationFromLatLng = async (lat, lng, openPopup = false) => {
    try {
      const { data } = await getDataForLocationFromLatLng(lat, lng);
      await this.updateData(data);
      if (openPopup) {
        this.setIsMarkerOpened(true);
      }
    } catch (error) {}
  };

  @action
  getDataForCapakey = async (capakey, openPopup = false) => {
    this.capakey = capakey;
    const result = await getLocationDataForCapakey(capakey);
    if (result?.data) {
      const { data } = result;
      this.position = data.center;
      await this.updateData(data);

      if (openPopup) {
        this.setIsMarkerOpened(true);
      }
    }
  };

  @action
  updateData = async (data) => {
    if (data.actueleInfo) {
      await this.setActueleData(data.actueleInfo);
    } else {
      await this.setActueleData();
    }

    if (data.fiscaleInfo) {
      await this.setFiscaleData(data.fiscaleInfo);
    } else {
      await this.setFiscaleData();
    }
  };

  @action
  setFiscaleData = async ({ Capakey, polygon } = { Capakey: {}, polygon: {} }) => {
    this.fiscaleCapakey = Capakey;
    this.fiscalePolygon = polygon;
  };

  @action
  setActueleData = async ({ Capakey, polygon } = { Capakey: {}, polygon: {} }) => {
    this.actueleCapakey = Capakey;
    this.actuelePolygon = polygon;
  };

  @action
  setIsMarkerOpened = (value) => {
    //console.log("Setting marker openend:", value);
    this.isMarkerOpened = value;
  };
}

export default PopupModel;
