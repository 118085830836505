import { observable, action, computed } from "mobx";

export default class ErrorModel {
  @observable
  open = false;

  @observable
  title = "";

  @observable
  message;

  @action
  setIsOpen = (value) => {
    this.open = value;
  };

  @action
  setData = (title, message) => {
    console.log("error model", title, message);
    this.open = true;
    this.title = title;
    this.message = message;
  };
}
