// import withWidth, { isWidthUp } from "@mui/material/withWidth";
import { Fab, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { Fragment, useState } from "react";
import useHeaderFabStyles from "./HeaderFabStyles";
function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
    }, null) || "xs");
}
const HeaderFab = ({ fabtitle, fabicon, id = null, fabaction = null, fabactions = [], fabmenuid = undefined }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useHeaderFabStyles();
    const open = Boolean(anchorEl);
    const width = useWidth();
    const handleMenu = (event) => {
        //@ts-ignore
        setAnchorEl(event.currentTarget);
    };
    function handleClose() {
        setAnchorEl(null);
    }
    return (React.createElement(Fragment, null,
        React.createElement(Fab, { id: id ? id : undefined, "aria-owns": fabaction === null && open ? fabmenuid : undefined, "aria-haspopup": fabaction === null, 
            //@ts-ignore
            onClick: (fabaction === null && handleMenu) || fabaction, variant: "extended", color: "inherit", className: classes.customfab },
            !["xs", "sm"].includes(width) && fabtitle,
            fabicon),
        fabaction === null && (React.createElement(Menu, { id: fabmenuid, anchorEl: anchorEl, anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, open: open, onClose: handleClose }, fabactions.map((action, index) => (React.createElement(MenuItem, { key: "menu-action-" + fabtitle + index, onClick: (e) => {
                action.funct();
                handleClose();
            } }, action.title)))))));
};
export default HeaderFab;
