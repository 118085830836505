import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import axios from "axios";

/**
 * model used to control the layer of the maps
 * maplist is a json with al possible layers
 * when changing the activemapindex the map will be rerendered
 *
 */
class MapLayerModel {
  constructor() {
    this.loadMaps();
  }

  @observable
  maps = [];

  @observable
  overlays = [];

  @persist
  @observable
  activeMapIndex = 0;

  @persist
  @observable
  activeOverlayIndex = -1;

  @action
  loadMaps = async () => {
    try {
      const {
        data: { basemaps, overlays = [] },
      } = await axios.get("/config/maps.json");
      if (basemaps && this.activeMapIndex >= basemaps.length) {
        this.activeMapIndex = 0;
      }
      if (overlays && this.activeOverlayIndex >= overlays.length) {
        this.activeOverlayIndex = -1;
      }
      this.maps = basemaps;
      this.overlays = overlays;
    } catch (error) {
      console.log("ERROR::", error.toString());
    }
  };

  @action
  setActiveMapIndex = (index) => {
    this.activeMapIndex = index;
  };

  @action
  setActiveOverlayIndex = (index) => {
    this.activeOverlayIndex = index;
  };

  @computed
  get activeMap() {
    return this.maps[this.activeMapIndex];
  }

  @computed
  get activeOverlay() {
    return this.overlays?.[this.activeOverlayIndex];
  }
}

export default MapLayerModel;
