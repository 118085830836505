import { APP_LEGGER_TYPES } from "app-constants";
import axios from "axios";
import jsonpAdapter from "axios-jsonp";
import { apiLocalCacheAction } from "./cache";

export const setDefaultHeader = (token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};

export const getParcelInfo = async (capakey, leggerType) => {
  const { data } = await axios.get(`${window.env.API_URL}parcel/${leggerType}/${capakey.split("/")[0]}/${capakey.split("/")[1]}`, {
    capakey,
  });
  return data;
};

export const getMagdaParcelExists = async (capakey) => {
  const { data } = await axios.get(`${window.env.API_URL}parcel/magda/${capakey.split("/")[0]}/${capakey.split("/")[1]}`, {
    capakey,
  });
  return data;
};

export const getLeggerNotifications = async () => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}municipalities/notifications`);
    return data?.data || {};
  } catch (er) {
    return {};
  }
};

export const getSubscriptions = async (capakey, leggerType) => {
  const { data } = await axios.get(`${window.env.API_URL}parcel/subscriptions`);
  return data;
};

export const getResidents = async (capakey, shape, houseHolder, busNumberFilter) => {
  try {
    const { data } = await axios.post(`${window.env.API_URL}parcel/residents`, {
      capakey,
      shape,
      houseHolder,
      busNumberFilter,
    });
    return data;
  } catch (error) {
    return { data: [] };
  }
};

const makeUrlReady = (value) => `/${encodeURIComponent(value.trim()).replace(/'/g, "%27")}`;

export const searchPerson = async (type, firstname, lastname, year = "") => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}search-owner/${type}/search-person${makeUrlReady(firstname)}${makeUrlReady(lastname)}${makeUrlReady(year.toString())}`);
    return data;
  } catch (error) {
    return { data: [] };
  }
};

export const searchPersonByInsz = async (type, insz) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}search-owner/${type}/search-insz${makeUrlReady(insz)}`);
    return data;
  } catch (error) {
    return { data: [] };
  }
};

export const searchActivePersonByInsz = async (insz) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}search-owner/actueel/search-person${makeUrlReady(insz)}`);
    return data;
  } catch (error) {
    return { data: [] };
  }
};

export const searchCompanyByName = async (type, name) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}search-owner/${type}/search-company-by-name${makeUrlReady(name)}`);
    return data;
  } catch (error) {
    return { data: [] };
  }
};

export const searchCompanyByNumber = async (type, insz) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}search-owner/${type}/search-company-by-insz${makeUrlReady(insz.toString())}`);
    return data;
  } catch (error) {
    return { data: [] };
  }
};

export const getParcels = async (type, value, afdeling, source) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${window.env.API_URL}search-owner/${type}/parcels/${value}${afdeling ? `/${afdeling}` : ``}`, { cancelToken: source.token })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.log("ERROR:API:getParcels", err);
        if (err.constructor.name == "Cancel") {
          resolve({ data: [], error: message });
        }

        resolve({ data: [] });
      });
  });
};

export const convertGeoJsonToCapakeys = async (geoJSON) => {
  const { data } = await axios.post(`${window.env.API_URL}wfs/search-polygon-parcels`, {
    geojson: geoJSON,
    crs: 4326,
  });

  const selectedParcels = {};
  data.data.forEach((parcel) => (selectedParcels[parcel.capakey] = parcel.capakey));
  return selectedParcels;
};

export const convertGeoJsonToBuffers = async (geoJSON, mode, cancel) => {
  const data = {
    geojson: geoJSON,
    crs: 4326,
    mode,
  };

  const actionFn = () => {
    return axios.post(`${window.env.API_URL}wfs/search-polygon-buffer`, data, { cancelToken: cancel?.token });
  };

  const response = await apiLocalCacheAction(actionFn, data, "convertGeoJsonToBuffers");
  return response?.data?.data;
};

export const getBufferFromDB = async (geoJSON) => {
  const { data } = await axios.post(`${window.env.API_URL}wfs/get-polygon-buffer`, {
    geojson: geoJSON,
  });

  return data.data;
};

export const convertGeoJsonToBuffersByRadius = async (geoJSON, radius, mode, cancel) => {
  const data = {
    geojson: geoJSON,
    crs: 4326,
    radius,
    mode,
  };

  const actionFn = () => {
    return axios.post(`${window.env.API_URL}wfs/search-polygon-buffer-by-radius`, data, { cancelToken: cancel?.token });
  };

  const response = await apiLocalCacheAction(actionFn, data, "convertGeoJsonToBuffersByRadius");
  return response?.data?.data;
};

/**
 * function responsible for feching all polygons
 * @param {rectangle} bounds
 */
export const getParcelsByBounds = async (bounds, mode, cancel) => {
  const data = { bounds, mode };
  const actionFn = () => {
    return axios.post(`${window.env.API_URL}wfs/get-parcels-by-bounds`, data, { cancelToken: cancel.token });
  };

  const response = (await apiLocalCacheAction(actionFn, data, "getParcelsByBounds")) || {};
  return response?.data?.data;
};

export const convertShapeFile = async (shapeFile, type = APP_LEGGER_TYPES.ACTUAL) => {
  const formData = new FormData();
  formData.append("shapeFile", shapeFile);
  formData.append("type", type);

  try {
    const {
      data: { data },
    } = await axios.post(`${window.env.API_URL}wfs/convert-shapefile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    console.log("ERROR:API:convertShapeFile", error.toString());
    return [error.toString()];
  }
};

export const getLocationDataForCapakey = async (capakey) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}wfs/capakey/${capakey.split("/")[0]}/${capakey.split("/")[1]}`);
    return data;
  } catch (error) {}
};

export const getStreets = async ({ value, muncipality = "Gent", count = 5 }) => {
  try {
    const {
      data: { LocationResult },
    } = await axios({
      url: `${REACT_APP_API_STREET}${muncipality}%20${value}&c=${count}`,
      adapter: jsonpAdapter,
    });

    return LocationResult.map(({ FormattedAddress: item, Location: { Lat_WGS84, Lon_WGS84 } }) => ({
      type: "street",
      value: item,
      label: item,
      location: { lat: Lat_WGS84, lon: Lon_WGS84 },
    }));
  } catch (error) {
    console.log("ERROR:API:getStreets", error.toString());
    return [error.toString()];
  }
};

export const searchStreetParcels = async function ({ value }) {
  try {
    const {
      data: { SuggestionResult },
    } = await axios({
      url: `${REACT_APP_API_PARCEL}${value}&c=5`,
      adapter: jsonpAdapter,
    });

    return SuggestionResult.map((item) => ({
      type: "parcels",
      value: item,
      label: item,
    }));
  } catch (error) {
    console.log("ERROR:API:getParcels", error.toString());
    return [error.toString()];
  }
};
export const getSearchDataStreets = async (value) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}parcel/search-streets/${encodeURIComponent(value)}`);
    return data;
  } catch (error) {
    console.log("ERROR:API:getParcels", error.toString());
    return { data: [] };
  }
};

export const getLogging = async (type, startDate, endDate) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${window.env.API_URL}logging/get-logging/${type}/${startDate}/${endDate}`);
    return data;
  } catch (error) {
    console.log("ERROR:API:getLogging", error.toString());
    return { data: [] };
  }
};

export const getSearchDataCapakeys = async (capakey) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}parcel/search-capakeys/${capakey.split("/")[0]}/${capakey.split("/")[1]}`);
    return data;
  } catch (error) {
    console.log("ERROR:API:getParcels", error.toString());
    return { data: [] };
  }
};

export const getPublicInvestigationHistory = async (id = null) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}parcel/get-public-investigation-history${id !== null ? `/${id}` : ``}`);
    return data;
  } catch (error) {
    console.log("ERROR:API:getPublicInvestigationHistory", error.toString());
    return null;
  }
};
export const getDataForLocation = async (boundingBox, width, height, x, y) => {
  let url = `${window.env.API_URL}parcel/location/${boundingBox}/${width}/${height}/${x}/${y}`;
  const { data } = await axios.get(url);
};

export const deletePublicInvestigationHistory = async (id = null) => {
  try {
    const { data } = await axios.delete(`${window.env.API_URL}parcel/get-public-investigation-history${id !== null ? `/${id}` : ``}`);
    return data;
  } catch (error) {
    console.log("ERROR:API:deletePublicInvestigationHistory", error.toString());
    return { data: [] };
  }
};

export const savePublicInvestigationHistory = async (publicInvestigation, id) => {
  try {
    const { data } = await axios.post(`${window.env.API_URL}parcel/save-public-investigation-history${id !== null ? `/${id}` : ``}`, {
      publicInvestigation,
    });
    return data;
  } catch (error) {
    console.log("ERROR:API:deletePublicInvestigationHistory", error.toString());
    return { data: [] };
  }
};

export const fetchPublicInvestigationResults = async (id) => {
  try {
    const { data } = await axios.get(`${window.env.API_URL}parcel/public-investigation-result/${id}`);

    return data.data;
  } catch (error) {
    console.log("ERROR:API:fetchPublicInvestigationResults", error.toString());
    return null;
  }
};

export const downloadPublicInvestigationShapeResults = async (id, filename) => {
  try {
    const response = await axios.request({
      url: `${window.env.API_URL}parcel/download-public-investigation-shape/${id}`,
      method: "GET",
      responseType: "blob",
    });
    const fileName = filename || `shape_${id}`;
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `${fileName}.zip`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return true;
  } catch (error) {
    console.log("ERROR:API:downloadPublicInvestigationShapeResults", error.toString());
    return null;
  }
};

export const getDataForLocationFromLatLng = async (lat, lng) => {
  let url = `${window.env.API_URL}wfs/location/${lat}/${lng}`;
  const { data } = await axios.get(url);

  return data;
};

export const getSelectableParcels = async (bounds) => {
  const { data: polygonArray } = await axios.post(`${window.env.API_URL}wfs/getparcelsbybounds`, {
    bounds,
  });

  return polygonArray.data;
};

export const getLocationsForCapakeys = async (capakeys) => {
  const { data: polygonArray } = await axios.post(`${window.env.API_URL}wfs/get-locations-for-capakeys`, {
    capakeys,
  });

  return polygonArray.data;
};

export const getLocation = async (value) => {
  try {
    const {
      data: { LocationResult },
    } = await axios({
      url: `https://geo.api.vlaanderen.be/geolocation/v4/Location?q=${value}`,
      adapter: jsonpAdapter,
    });

    return {
      lat: LocationResult["0"].Location.Lat_WGS84,
      lng: LocationResult["0"].Location.Lon_WGS84,
    };
  } catch (error) {
    console.log("ERROR:API:getLocation", error.toString());
    return [error.toString()];
  }
};

export const getLocationForCapakey = async (capakey) => {
  const {
    data: { geometry: { center } },
  } = await axios.get(`https://geo.api.vlaanderen.be/capakey/v2/parcel/${encodeURIComponent(capakey)}?srs=4326`, {
    capakey,
  });
  const [lng, lat] = JSON.parse(center).coordinates;
  return { lat, lng };
};

export const getMetadataForCapakey = async (capakey) => {
  try {
    const {
      data: { adres, geometry, geometry: { boundingBox, center } },
    } = await axios.get(`https://geo.api.vlaanderen.be/capakey/v2/parcel/${encodeURIComponent(capakey)}?data=adp&srs=4326`, {
      capakey,
    });
    const [lng, lat] = JSON.parse(center).coordinates;
    return { adres, shape: JSON.parse(boundingBox), center: { lng, lat } };
  } catch (er) {
    return {};
  }
};

export const getPublicInvestigation = async (projectNumber, params) => {
  console.log("omv", projectNumber, params);
  try {
    const url = `${window.env.API_URL}parcel/check-public-investigation-and-start-new${window.env.OMV_V2 === "true" ? "-v2" : ""}/actueel/${projectNumber}${params}`;
    console.log("omv", url);
    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    console.log("ERROR:API:getPublicInvestigation", error.toString());
    return { data: [] };
  }
};

export const fetchPublicInvestigations = async () => {
  try {
    const url = `${window.env.API_URL}parcel/fetch-public-investigations`;
    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    console.log("ERROR:API:fetchPublicInvestigations", error.toString());
    return { data: [] };
  }
};

export const fetchPublicInvestigationsV2 = async ({ searchTerm }) => {
  try {
    const url = `${window.env.API_URL}parcel/fetch-public-investigations-v2${searchTerm ? `?searchTerm=${searchTerm}` : ""}`;
    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    console.log("ERROR:API:fetchPublicInvestigations", error.toString());
    return { data: [] };
  }
};

export const fetchPublicInvestigationGeom = async ({ projectNum }) => {
  try {
    const url = `${window.env.API_URL}parcel/fetch-public-investigation-geom/${projectNum}`;
    const { data } = await axios.get(url);

    return data?.data;
  } catch (error) {
    console.log("ERROR:API:fetchPublicInvestigations", error.toString());
    return { data: [] };
  }
};

export const fetchMunicipality = async (municipality, mode = "fiscal") => {
  const { data } = await axios.get(`https://geo.api.vlaanderen.be/capakey/v2/municipality/${municipality}?data=adp&status=${mode}&srs=4326`);
  return data;
};

export const fetchDepartments = async (municipality, mode = "fiscal") => {
  const { data } = await axios.get(`https://geo.api.vlaanderen.be/capakey/v2/municipality/${municipality}/department/?data=adp&status=${mode}`);
  return data;
};

export const fetchDepartment = async (municipality, departmentCode, mode = "fiscal") => {
  const { data } = await axios.get(`https://geo.api.vlaanderen.be/capakey/v2/municipality/${municipality}/department/${departmentCode}?data=adp&status=${mode}&srs=4326`);
  return data;
};

export const fetchSections = async (municipality, departmentCode, mode = "fiscal") => {
  const { data } = await axios.get(`https://geo.api.vlaanderen.be/capakey/v2/municipality/${municipality}/department/${departmentCode}/section/?data=adp&status=${mode}`);
  return data;
};

export const fetchSection = async (municipality, departmentCode, sectionCode, mode = "fiscal") => {
  const { data } = await axios.get(
    `https://geo.api.vlaanderen.be/capakey/v2/municipality/${municipality}/department/${departmentCode}/section/${sectionCode}?data=adp&status=${mode}&srs=4326`,
  );
  return data;
};

export const fetchParcels = async (municipality, departmentCode, sectionCode, mode = "fiscal") => {
  const { data } = await axios.get(
    `https://geo.api.vlaanderen.be/capakey/v2/municipality/${municipality}/department/${departmentCode}/section/${sectionCode}/parcel/?data=adp&status=${mode}`,
  );
  return data;
};

export const fetchParcel = async (municipality, departmentCode, sectionCode, parcel, mode = "fiscal") => {
  const { data } = await axios.get(
    `https://geo.api.vlaanderen.be/capakey/v2/municipality/${municipality}/department/${departmentCode}/section/${sectionCode}/parcel/${parcel}?data=adp&status=${mode}&srs=4326`,
  );
  return data;
};
