import { SIDEBAR_CONFIG } from "app-constants";
import { createTheme } from "@mui/material/styles";
import { lighten, darken } from "@mui/material/styles";
import lightBlue from "@mui/material/colors/lightBlue";

export const styles = (theme) => ({
  drawer: {
    width: SIDEBAR_CONFIG.width,
    flexShrink: 0,
    overflow: "hidden",
  },
  drawerPaper: {
    width: SIDEBAR_CONFIG.width,
    overflow: "hidden",
    borderRight: 0,
  },
  drawerHeader: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    zIndex: 99,
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    ...theme.mixins.toolbar,
  },
  whiteText: {
    color: "white",
  },
  grow: {
    flexGrow: 1,
  },
  closeButton: {
    color: "white",
    marginRight: "5px",
  },
  datePicker: {
    padding: 15,
  },
});

export const datePickerTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#009be5",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        //color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: "#009be5",
        "&:hover": {
          backgroundColor: darken("#009be5", 0.15),
        },
      },
      dayDisabled: {
        //color: lightBlue["100"],
      },
      current: {
        color: "#009be5",
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#009be5",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#009be5",
      },
      thumb: {
        borderColor: "#009be5 !important",
        backgroundColor: "#009be5 !important",
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: "#009be5",
      },
      root: {
        "&:focus": {
          color: "#009be5",
        },
      },
    },
    MuiDialogActions: {
      root: {
        color: "#009be5",
        MuiButton: {
          label: {
            color: "#009be5",
          },
        },
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#009be5",
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: "#fff",
      },
    },
    MuiButton: {
      label: {
        color: "#009be5",
      },
    },
  },
});
