import { autorun } from "mobx";

const styles = (theme) => ({
  button: {
    margin: 8,
  },
  listItemTitle: {
    paddingBottom: 0,
  },
  speechBubble: {
    position: "absolute !important",
    borderRadius: 0,
    width: "35px",
    height: "35px",
    textAlign: "center",
    zIndex: "500 !important",
    "&> i": {
      fontSize: "21px",
      color: "#ffffff !important",
    },
    "&:after": {
      content: "",
      position: "absolute",
      bottom: 0,
      left: "50%",
      width: 0,
      height: 0,
      border: "5px solid transparent",
      borderTopColor: "inherit",
      borderBottom: 0,
      marginLeft: "-5px",
      marginBottom: "-5px",
    },
  },
  speechBubbleLarge: {
    position: "absolute !important",
    borderRadius: 0,
    width: "35px",
    height: "35px",
    textAlign: "left",
    opacity: 0.95,
    zIndex: "99999 !important",
    "&> i": {
      fontSize: "21px",
      color: "#ffffff !important",
    },
    "&:after": {
      content: "",
      position: "absolute",
      bottom: 0,
      left: "50%",
      width: 0,
      height: 0,
      border: "5px solid transparent",
      borderTopColor: "inherit",
      borderBottom: 0,
      marginLeft: "-5px",
      marginBottom: "-5px",
    },
  },
  popup: {
    minWidth: "300px",
    bottom: "-42px !important",
    borderRadius: 0,
    "& .leaflet-popup-content-wrapper": {
      borderRadius: 0,
      padding: 0,
      "& .leaflet-popup-content": {
        margin: 0,
      },
    },
    "& .leaflet-popup-close-button": {
      display: "none",
    },
  },
  popupButton: {
    margin: "17px 8px",
  },
  popupListItemText: {
    "& p": {
      margin: "0 !important",
    },
  },
  popupListItemTitle: {
    textTransform: "uppercase",
    backgroundColor: "#607d8b", // rood: #d32f2f
    padding: "0 16px",
    "& button span:first-child": {
      color: "#ffffff",
    },
  },
  popupListItemTextTitle: {
    "& span span": {
      color: "#ffffff",
      fontSize: "15px",
    },
  },
  buffer100: {
    stroke: "white", //"#8D07F6",
    strokeWidth: "4px",
    strokeOpacity: 1,
    fillOpacity: 0,
    strokeDasharray: "1,6",
    pointerEvents: "none !important",
  },
  bufferPerimeter: {
    stroke: "#D22F2D",
    strokeWidth: "4px",
    strokeOpacity: 1,
    fillOpacity: 0,
    strokeDasharray: "1,6",
    pointerEvents: "none !important",
  },
  buffer300: {
    stroke: "black", //"#8D07F6",
    strokeWidth: "4px",
    strokeOpacity: 1,
    fillOpacity: 0,
    strokeDasharray: "1,6",
    pointerEvents: "none !important",
  },
  drawedShape: {
    stroke: "#1abc9c",
    fill: "#1abc9c",
    strokeOpacity: 0.9,
    fillOpacity: 0.5,
  },
  publicInvestigationMap: {
    "& path.leaflet-interactive.parcel": {
      stroke: "snow",
      strokeWidth: "1px",
      strokeOpacity: 1,
      fillOpacity: 0,
      "&:hover": {
        stroke: "snow",
        strokeWidth: "2px",
        strokeOpacity: 0.5,
        fill: "blue",
        fillOpacity: 0.5,
      },
    },
    "& path.leaflet-interactive.parcel.A": {
      stroke: "snow",
      strokeWidth: "1px",
      strokeOpacity: 1,
      fill: "orange",
      fillOpacity: 0.5,
      strokeDasharray: "5,5",
      "&:hover": {
        stroke: "snow",
        strokeWidth: "1px",
        strokeOpacity: 1,
        fill: "orange",
        fillOpacity: 0.5,
        strokeDasharray: "5,5",
      },
    },
    "& path.leaflet-interactive.parcel.B": {
      stroke: "snow",
      strokeWidth: "1px",
      strokeOpacity: 1,
      fill: "#FFFF05",
      fillOpacity: 0.5,
      strokeDasharray: "5,5",
      "&:hover": {
        stroke: "snow",
        strokeWidth: "1px",
        strokeOpacity: 1,
        fill: "#FFFF05 !important",
        fillOpacity: 0.5,
        strokeDasharray: "5,5",
      },
    },
    "& path.leaflet-interactive.parcel.I": {
      stroke: "snow",
      strokeWidth: "1px",
      strokeOpacity: 1,
      fill: "#8D07F6",
      fillOpacity: 0.5,
      strokeDasharray: "5,5",
      "&:hover": {
        stroke: "snow",
        strokeWidth: "1px",
        strokeOpacity: 1,
        fill: "#8D07F6 !important",
        fillOpacity: 0.5,
        strokeDasharray: "5,5",
      },
    },
    "& path.leaflet-interactive.parcel.P": {
      stroke: "snow",
      strokeWidth: "1px",
      strokeOpacity: 1,
      fill: "#D22F2D",
      fillOpacity: 0.4,
      strokeDasharray: "5,5",
      "&:hover": {
        stroke: "snow",
        strokeWidth: "1px",
        strokeOpacity: 1,
        fill: "#D22F2D !important",
        fillOpacity: 0.4,
        strokeDasharray: "5,5",
      },
    },
    "& path.leaflet-interactive.C": {
      stroke: "snow",
      strokeWidth: "1px",
      strokeOpacity: 1,
      fill: "#0029FA",
      fillOpacity: 0.5,
      strokeDasharray: "5,5",
      "&:hover": {
        stroke: "snow",
        strokeWidth: "1px",
        strokeOpacity: 1,
        fill: "#0029FA !important",
        fillOpacity: 0.5,
        strokeDasharray: "5,5",
      },
    },
  },
  leafletInteractive: {
    cursor: "help",
    "& .leaflet-measure-path-measurement": {
      width: "max-content !important",
      height: "max-content !important",
      padding: "2px 5px",
      fontSize: "13px",
      backgroundColor: "#eee",
      textAlign: "center",
      fontWeight: "bold",
      color: "#d32f2f",
      boxShadow: "0 0 8px #eee",
      borderRadius: "12px",
    },
    "& svg.customzindex": {
      zIndex: 500,
    },
    "& path.leaflet-interactive": {
      stroke: "darkslategrey",
      strokeWidth: "1px",
      strokeOpacity: 1,
      fill: "darkslategrey",
      fillOpacity: 0.3,
      "&:hover": {
        stroke: "darkslategrey",
        strokeWidth: "2px",
        strokeOpacity: 1,
        fill: "darkslategrey",
        fillOpacity: 0.3,
      },
    },
    "& path.leaflet-interactive.active": {
      stroke: "snow !important",
      strokeWidth: "2px !important",
      strokeOpacity: "1 !important",
      fill: "yellow !important",
      fillOpacity: "0.8 !important",
    },
    "& path.ooPolygon": {
      stroke: "#d32f2f",
      strokeWidth: "3px",
      strokeOpacity: 1,
      fill: "snow",
      fillOpacity: 0,
      "&:hover": {
        stroke: "#d32f2f !important",
        strokeWidth: "3px !important",
        strokeOpacity: "1 !important",
        fill: "#d32f2f !important",
        fillOpacity: "1 !important",
      },
    },
    "& path.publicInvestigationMap": {
      stroke: "#d32f2f",
      strokeWidth: "3px",
      strokeOpacity: 1,
      fill: "snow",
      fillOpacity: 0,
      "&:hover": {
        stroke: "#d32f2f !important",
        strokeWidth: "3px !important",
        strokeOpacity: "1 !important",
        fill: "#d32f2f !important",
        fillOpacity: "1 !important",
      },
    },
    "& path.test": {
      stroke: "yellow",
      strokeWidth: "3px",
      strokeOpacity: 1,
      fill: "black",
      fillOpacity: 0.6,
      "&:hover": {
        stroke: "#d32f2f !important",
        strokeWidth: "3px !important",
        strokeOpacity: "1 !important",
        fill: "#d32f2f !important",
        fillOpacity: "1 !important",
      },
    },
    "& path.leaflet-draw": {
      stroke: "black",
      strokeOpacity: 1,
      strokeWidth: "1px",
      fill: "black",
      fillOpacity: 0.5,
      "&:hover": {
        stroke: "black",
        strokeOpacity: 1,
        strokeWidth: "1px",
        fill: "black",
        fillOpacity: 0.5,
      },
    },
  },
  leafletInteractiveMCpickPosition: {
    cursor: "crosshair",
  },
  leafletInteractiveWithPerceelInfo: {
    cursor: "help",
    "& path.leaflet-interactive": {
      stroke: "darkslategrey",
      strokeWidth: "1px",
      strokeOpacity: 1,
      fill: "darkslategrey",
      fillOpacity: 0.3,
      "&:hover": {
        stroke: "darkslategrey",
        strokeWidth: "2px",
        strokeOpacity: 1,
        fill: "darkslategrey",
        fillOpacity: 0.3,
      },
    },
    "& svg.customzindex": {
      zIndex: 500,
    },
    "& path.leaflet-interactive.active": {
      stroke: "snow !important",
      strokeWidth: "2px !important",
      strokeOpacity: "1 !important",
      fill: "yellow !important",
      fillOpacity: "0.8 !important",
    },
    "& path.leaflet-draw": {
      stroke: "black",
      strokeOpacity: 1,
      strokeWidth: "1px",
      fill: "black",
      fillOpacity: 0.5,
      "&:hover": {
        stroke: "black",
        strokeOpacity: 1,
        strokeWidth: "1px",
        fill: "black",
        fillOpacity: 0.5,
      },
    },
  },
  prgrssbr: {
    display: "block",
  },
  prgrssbrhddn: {
    display: "none",
  },
});

/*
#prgrsbr {
    margin: 0,
    position: absolute,
    top: 0,
    display: none,
}

*/

export default styles;
