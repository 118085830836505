import { withStyles } from "@mui/styles";
import { HorizontalDataTable } from "@orbit/components";
import { Grid, Button, Dialog, DialogTitle, DialogContent, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { injectIntl } from "react-intl";
import styles, { getParcelInfoTableStyle } from "./LoggingViewStyles";
import { getLogging } from "services/api";
import { LOGGING_MODE_ENUM } from "stores/models/LoggingModel";
import { toJS } from "mobx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const options = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: true,
  filterType: "dropdown",
  responsive: "standard",
  tableBodyHeight: "",
  tableBodyMaxHeight: "calc(100vh - 181px)",
  selectableRows: "none",
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  textLabels: {
    body: {
      noMatch: "Geen info gevonden...",
      toolTip: "Sorteren",
      //columnHeaderTooltip: column => `Sort for ${column.label}`
    },
    pagination: {
      next: "Volgende pagina",
      previous: "Vorige pagina",
      rowsPerPage: "Rijen per pagina:",
      displayRows: "van",
    },
    toolbar: {
      search: "Zoeken",
      downloadCsv: "Download CSV",
      print: "Print",
      viewColumns: "Bekijk kolommen",
      filterTable: "Filter",
    },
    filter: {
      all: "Alles",
      title: "FILTERS",
      reset: "Beginwaarden",
    },
    viewColumns: {
      title: "Toon kolommen",
      titleAria: "Toon/verberg kolommen",
    },
  },
};

let cancable;

const LoggingView = hooksObserver(({ classes, intl: { formatMessage } }) => {
  const {
    applicationStore: { logging: { mode, applicationLogging, magdaLogging } },
  } = useContext(StoresContext);

  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const applicationLoggingColumns = applicationLogging.rowNames.map((rowName) => {
    switch (rowName.name) {
      case "firstName":
        return {
          ...rowName,
          name: "Voornaam",
        };
      case "lastName":
        return {
          ...rowName,
          name: "Naam",
        };
      case "role":
        return {
          ...rowName,
          name: "Profiel",
        };
      case "email":
        return {
          ...rowName,
          name: "Email",
        };
      case "function":
        return {
          ...rowName,
          name: "Functie",
        };
      case "requested":
        return {
          name: "Parameters",
          options: {
            customBodyRender: (value, { rowData }) => {
              console.log(value);
              const items = [];
              for (const item in value) {
                items.push(item);
              }
              return (
                <ul
                  style={{
                    maxWidth: 400,
                  }}
                >
                  {items.map((item) => (
                    <li>{value[item] instanceof Object ? JSON.stringify(value[item]) : value[item]}</li>
                  ))}
                </ul>
              );
            },
          },
        };
      case "time":
        return {
          ...rowName,
          name: "Datum",
          options: {
            customBodyRender: (value, { rowData }) => {
              return new Date(value.replace("T", " ")).toLocaleString();
            },
          },
        };
      default:
        return {
          ...rowName,
          name: "Default",
        };
        break;
    }
  });

  const magdaLoggingColumns = magdaLogging.rowNames.map((rowName) => {
    switch (rowName.name) {
      case "firstName":
        return {
          ...rowName,
          name: "Voornaam",
        };
      case "lastName":
        return {
          ...rowName,
          name: "Naam",
        };
      case "role":
        return {
          ...rowName,
          name: "Profiel",
        };
      case "email":
        return {
          ...rowName,
          name: "Email",
        };
      case "function":
        return {
          ...rowName,
          name: "Functie",
        };
      case "requested":
        return {
          name: "Parameters",
          options: {
            customBodyRender: (value, { rowData }) => {
              console.log(value);
              const items = [];
              for (const item in value) {
                items.push(item);
              }
              return (
                <ul
                  style={{
                    maxWidth: 400,
                  }}
                >
                  {items.map((item) => (
                    <li>{value[item] instanceof Object ? JSON.stringify(value[item]) : value[item]}</li>
                  ))}
                </ul>
              );
            },
          },
        };
      case "questions":
        return {
          name: "Vragen",
          options: {
            customBodyRender: (value, { rowData }) => {
              return (
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setContent(value);
                      setOpen(true);
                    }}
                  >
                    Bekijk vragen
                  </Button>
                </div>
              );
            },
          },
        };
      case "time":
        return {
          ...rowName,
          name: "Datum",
          options: {
            customBodyRender: (value, { rowData }) => {
              return new Date(value.replace("T", " ")).toLocaleString();
            },
          },
        };
      default:
        return {
          ...rowName,
          name: "Default",
        };
        break;
    }
  });

  return (
    <div className={classes.container}>
      <div className={classes.tableWrapper}>
        <HorizontalDataTable
          data={mode === LOGGING_MODE_ENUM.APPLICATION ? applicationLogging.rowData : magdaLogging.rowData}
          columns={mode === LOGGING_MODE_ENUM.APPLICATION ? applicationLoggingColumns : magdaLoggingColumns}
          options={options}
          muiTheme={getParcelInfoTableStyle}
        />
      </div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Vragen
        </DialogTitle>
        <DialogContent dividers>
          {content.map((question, index) => {
            return (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index + 1}a-content`} id={`panel${index + 1}a-header`}>
                  <Typography className={classes.heading}>Vraag {index + 1}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <pre>{question}</pre>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default withStyles(styles)(injectIntl(LoggingView));
