import { convertLocationToWGS84 } from "utils/location";
import useQuery from "./useQuery";

const useQueryLocation = () => {
  try {
    const queryParams = useQuery();
    const x = Number(queryParams.get("x")?.replace(",", "."));
    const y = Number(queryParams.get("y")?.replace(",", "."));
    const crs = Number(queryParams.get("crs")) || 4326;
    if (x && y) {
      const location = convertLocationToWGS84(crs, x, y);

      // @todo: maybe more checks?
      if (location && location.lat && location.lng) {
        return location;
      }
    }
  } catch (e) {}
  return null;
};

export default useQueryLocation;
