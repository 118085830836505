import { Divider, Grid, IconButton, List, ListItem, ListItemText, Tab, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { CustomLinearProgress } from "@orbit/components";
import { APP_LEGGER_TYPES, SUBSCRIPTION_CODE } from "app-constants";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import SearchCompanyByName from "views/advanced-search/search-company-by-name";
import SearchCompanyByNr from "views/advanced-search/search-company-by-number";
import SearchPerson from "views/advanced-search/search-person";
import SearchPersonByINSZ from "views/advanced-search/search-person-by-insz";
import styles, { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "./SidebarSearchByPersonStyles";

import { useParams } from "react-router";
import { SubscriptionMessage } from "views/advanced-search/search-subscription-message";

const searchTypes = [
  {
    title: "Zoek persoon op naam",
    form: ({ type }) => <SearchPerson type={type} data-test-id="li-zoekpersoonopnaam" />,
  },
  {
    title: "Zoek persoon via INSZ",
    form: ({ type }) => <SearchPersonByINSZ type={type} data-test-id="li-zoekpersoonviainsz" />,
  },
  {
    title: "Zoek onderneming op naam",
    form: ({ type }) => <SearchCompanyByName type={type} data-test-id="li-zoekondernemingopnaam" />,
  },
  {
    title: "Zoek onderneming via ondernemingsnr.",
    form: ({ type }) => <SearchCompanyByNr type={type} data-test-id="li-zoekondernemingopnummer" />,
  },
];

const SidebarSearch = hooksObserver(({ classes }) => {
  const {
    routingStore: { push },
    applicationStore: { search },
  } = useContext(StoresContext);

  let { leggerType } = useParams();
  const [expanded, setExpanded] = React.useState(0);

  const handleExpansionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleChange(event, type) {
    push(type);
  }

  const LeggerTypeTabs = () => (
    <Tabs value={leggerType} indicatorColor="secondary" textColor="secondary" variant="fullWidth" onChange={handleChange}>
      <Tab label="Actueel" value={APP_LEGGER_TYPES.ACTUAL} />
      <Tab label="Fiscaal" value={APP_LEGGER_TYPES.FISCAL} data-test-id="tab-fiscal-sidebarsearchowner" />
    </Tabs>
  );

  return (
    <React.Fragment>
      <SubscriptionMessage code={SUBSCRIPTION_CODE.SEARCH_BY_OWNER} />
      <div>
        {searchTypes.map((searchType, index) => (
          <ExpansionPanel key={index} square expanded={expanded === index} onChange={handleExpansionChange(index)}>
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography>{searchType.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column">
                <Grid item>
                  <LeggerTypeTabs />
                </Grid>
                <Grid item className={classes.container}>
                  {searchType.form({
                    type: leggerType,
                  })}
                </Grid>
                {search.loading && <CustomLinearProgress />}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
      <Divider />
      <List className={classes.parcellist}>
        {search.persons.map((person) => {
          return (
            <ListItem key={person.officialId} button onClick={() => push(leggerType + "/" + person.officialId)}>
              <ListItemText primary={person.firstname + " " + person.name} secondary={person.officialId} />
              <IconButton size="small">
                <ArrowRightIcon />
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
});

SidebarSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarSearch);
