const styles = (theme) => ({
  list: {
    marginLeft: -16,
    marginRight: -16,
  },
  input: {
    marginLeft: "0",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
});

export default styles;
