import { observable, action } from "mobx";
import { login, logout, validatetoken, changeNis, checkMunicipalitySubscription } from "../services/auth";
import { persist } from "mobx-persist";
import axios from "axios";
import SubscriptionModel from "./models/SubscriptionModel";

const AUTH_URL = window.env.AUTH_URL;

export default class AdminAuthStore {
  @persist
  @observable
  isLoggedIn = false;

  @persist
  @observable
  selectingProfile = false;

  @persist("object")
  @observable
  user = {};

  @persist
  @observable
  token = null;

  @persist("list")
  @observable
  availableProfiles = [];

  @persist("object", SubscriptionModel)
  @observable
  subscriptions = new SubscriptionModel();

  @persist
  @observable
  sessionId = null;

  @persist
  @observable
  role = null;

  @persist
  @observable
  email = "";

  @persist
  @observable
  nis = null;

  @persist("list")
  @observable
  niscodes = [];

  @persist
  @observable
  municipality = null;

  @persist
  @observable
  redirectUrl = null;

  @action
  setRedirectUrl = (newUrl) => {
    console.log("Setting redirect to " + newUrl);
    this.redirectUrl = newUrl;
  };

  @action
  setAvailableProfiles = (profiles) => {
    console.log("Setting profiles:", profiles);
    this.availableProfiles = profiles;
  };

  @action
  setSessionId = (id) => {
    this.sessionId = id;
  };

  /**
   * returns logged in status
   */
  @action
  doLogin = async (fake = false) => {
    try {
      const { token, role, nis, email, municipality, featuresForMunicipality, niscodes } = await login(fake ? "" : this.sessionId, fake);

      this.toggleSelectingProfile();
      this.token = token;
      this.role = role;
      this.nis = nis;
      this.municipality = municipality;
      this.niscodes = niscodes;
      this.email = email;
      this.subscriptions.setSubscriptions(featuresForMunicipality);
      this.isLoggedIn = true;
      return true;
    } catch (e) {
      console.log("Login Error:", e.toString());
    }
    return false;
  };

  @action
  checkSubscription = async () => {
    const isValid = await checkMunicipalitySubscription(this.sessionId);
    return isValid;
  };

  @action
  validateToken = async (toggle = false) => {
    const { token, role, nis, municipality, featuresForMunicipality, niscodes } = await validatetoken(this.sessionId);
    if (toggle) this.toggleSelectingProfile();
    this.token = token;
    this.role = role;
    this.niscodes = niscodes;
    this.nis = nis;
    this.municipality = municipality;
    this.subscriptions.setSubscriptions(featuresForMunicipality);
    return true;
  };

  @action
  changeNis = async (newNis) => {
    const { token, role, nis, municipality, featuresForMunicipality, niscodes } = await changeNis(newNis);
    this.token = token;
    this.role = role;
    this.niscodes = niscodes;
    this.nis = nis;
    this.municipality = municipality;
    this.subscriptions.setSubscriptions(featuresForMunicipality);
  };

  /**
   * returns logged in status
   */
  @action
  doLogout = async (force = false) => {
    //window.alert("Should have logged out.");
    if (!force) await logout(this.token);
    this.user = {};
    this.token = null;
    this.availableProfiles = [];
    this.sessionId = null;
    this.role = null;
    this.isLoggedIn = false;
    window.location.href = `${AUTH_URL}logout`;
    this.selectingProfile = false;

    return this.isLoggedIn;
  };

  @action
  forceIsLoggedInFalse = () => {
    this.user = {};
    this.token = null;
    this.availableProfiles = [];
    this.sessionId = null;
    this.role = null;
    this.isLoggedIn = false;
  };

  @action
  toggleSelectingProfile = () => {
    this.selectingProfile = !this.selectingProfile;
  };

  @action
  setSelectingProfile = (value) => {
    this.selectingProfile = value;
  };
}
