import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useControlButtonStyles = () => {
    const theme = useTheme();
    return makeStyles({
        fab: {
            margin: theme.spacing(1),
            color: "#e6e6e6",
        },
    })();
};
export default useControlButtonStyles;
