import { Drawer, Typography, IconButton } from "@mui/material";
import { Switch, Route, withRouter } from "react-router";
import { observer as hooksObserver } from "mobx-react-lite";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { StoresContext } from "contexts";
import { styles } from "./SidebarLoggingStyles";
import CloseIcon from "@mui/icons-material/Close";
import SidebarLoggingHome from "./SidebarLoggingHome";
import { useWidth } from "hooks/useWidth";

const SidebarLogging = hooksObserver(({ classes }) => {
  const {
    uiStore: { sideBarOpen, setSideBarOpen },
  } = useContext(StoresContext);

  const width = useWidth();
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={sideBarOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography variant="h6" className={classes.whiteText}>
          Logs
        </Typography>
        <div className={classes.grow} />
        {["sm"].includes(width) && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={(e) => {
              setSideBarOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <SidebarLoggingHome />
    </Drawer>
  );
});

SidebarLogging.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SidebarLogging));
