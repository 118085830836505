import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, ListItemAvatar, Avatar } from "@mui/material";
import { withStyles } from "@mui/styles";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import styles from "./SelectNisStyles";
import PersonIcon from "@mui/icons-material/Person";

const SelectNis = hooksObserver(({ open, closeFn, classes, intl: { formatMessage } }) => {
  const {
    authStore: { changeNis },
    authStore,
  } = useContext(StoresContext);

  return (
    <Dialog open={open} onClose={() => closeFn(false)}>
      <DialogTitle className={classes.dialogTitle}>Selecteer klant</DialogTitle>
      <DialogContent className={classes.dailogContent}>
        <List>
          {authStore.niscodes.map((niscode, index) => {
            return (
              <ListItem
                key={niscode.niscode}
                button
                onClick={async () => {
                  await changeNis(niscode.niscode);
                  closeFn(false);
                }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={niscode.niscode.toString() + " - " + niscode.name.toString()} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
});
export default withRouter(injectIntl(withStyles(styles)(SelectNis)));
