import { createTheme } from "@mui/material/styles";

/**
 *
 */
let light = createTheme({
  palette: {
    primary: {
      main: "#607d8b",
    },
    secondary: {
      main: "#d32f2f",
    },
    icon: {
      main: "#000",
      sec: "#00ACC1",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

let dark = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#607d8b",
    },
    secondary: {
      main: "#00ACC1",
    },
    icon: {
      main: "#FFF",
      sec: "#00ACC1",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export { light, dark };
