import CssBaseline from "@mui/material/CssBaseline";
import { withStyles } from "@mui/styles";
import { APP_LEGGER_TYPES } from "app-constants";
import classNames from "classnames";
import { MESSAGE_TYPES } from "config/config";
import ErrorModal from "containers/partials/modals/ErrorModal";
import SelectNis from "containers/users/SelectNis";
import SelectProfile from "containers/users/SelectProfile";
import { StoresContext } from "contexts";
import useQuery from "hooks/useQuery";
import useQueryLocation from "hooks/useQueryLocation";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef } from "react";
import { Route, useParams, useRouteMatch, withRouter } from "react-router";

import {
  ROUTE_BASE,
  ROUTE_HISTORY,
  ROUTE_LOGGING,
  ROUTE_LOGGING_GENERAL,
  ROUTE_LOGGING_MAGDA,
  ROUTE_PERCEEL,
  ROUTE_PERCEELINFO,
  ROUTE_PERSONS_PARCELS,
  ROUTE_PUBLIC_INVESTIGATION,
  ROUTE_PUBLIC_INVESTIGATION_OMV,
  ROUTE_PUBLIC_INVESTIGATION_OMV_VIEW,
  ROUTE_PUBLIC_INVESTIGATION_PROCEDURE,
  ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW,
  ROUTE_PUBLIC_INVESTIGATION_RESULT,
  ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW,
  ROUTE_SEARCH_CAPAKEY,
  ROUTE_SEARCH_CAPAKEY_TYPE,
  ROUTE_SEARCH_OWNER,
  ROUTE_SEARCH_OWNER_TYPE,
  ROUTE_SELECTEER_PROFIEL,
  ROUTE_SETTINGS,
} from "routes/RouteList";
import { getLeggerNotifications, getPublicInvestigation } from "services/api";
import HeaderView from "views/header/HeaderView";
import MapPublicInvestigationView from "views/map-views/MapPublicInvestigationView";
import MapView from "views/map-views/MapView";
import Notifier from "views/notifier/Notifier";
import PublicInvestigationResult from "../partials/public-investigation/PublicInvestigationResult";
import Sidebar from "../partials/sidebar/Sidebar";
import styles from "./BasePageStyles";

const canShowNotification = (notif) => {
  if (!notif) return false;
  const startValid = !notif.start || new Date(notif.start).getTime() <= new Date().getTime();
  const endValid = !notif.end || new Date(notif.end).getTime() >= new Date().getTime();
  return notif.enabled && (notif.title || notif.body || notif.url) && startValid && endValid;
};

const BasePage = hooksObserver(({ classes, location: { pathname } }) => {
  const {
    uiStore,
    uiStore: {
      setContentHeight,
      sideBarOpen,
      errorModel,
      setDialogSelectNisOpen,
      dialogSelectNisOpen,
      popupModel,
      popupModel: { setIsMarkerOpened, getDataForLocationFromLatLng },
      addToHistory,
    },
    notifierStore,
    mapStore: { setLatLng, setPointOfInterest, mapLayer: { maps } },
    routingStore: { push },
    authStore: { sessionId, selectingProfile, validateToken, redirectUrl, setRedirectUrl },
    applicationStore: { perceelInfoActief: { setCapakey } },
  } = useContext(StoresContext);

  const rootRef = useRef(null);
  const headerRef = useRef(null);

  /**
   * check if route is new route to start public investigation
   * used by external partners
   *
   */
  const isStartSpacialResearchFromOMV = useRouteMatch(ROUTE_PUBLIC_INVESTIGATION_OMV_VIEW);

  const params = useParams();
  const queryParams = useQuery();
  const location = useQueryLocation();

  const { projectNumber } = params;

  useEffect(() => {
    const handleLocation = async () => {
      const type = Object.values(APP_LEGGER_TYPES).find((t) => t === queryParams.get("type"));
      try {
        setLatLng(location);
        await getDataForLocationFromLatLng(location.lat, location.lng);
        if (popupModel.actueleCapakey.full || popupModel.fiscaleCapakey.full) {
          if (!type) {
            setIsMarkerOpened(true);
          } else {
            const capakey = type === APP_LEGGER_TYPES.ACTUAL ? popupModel.actueleCapakey : popupModel.fiscaleCapakey;
            addToHistory(capakey);
            setCapakey(capakey);
            push(`${ROUTE_PERCEEL}${type}/${encodeURIComponent(capakey.full)}`);
          }
        } else {
          setPointOfInterest(location);
        }
      } catch (er) {}
    };
    if (location) {
      handleLocation();
    }
  }, [location]);

  useEffect(() => {
    if (rootRef.current !== null && headerRef.current !== null) {
      setContentHeight(rootRef.current.clientHeight - headerRef.current.clientHeight);
    }
  }, [rootRef & headerRef]);

  useEffect(() => {
    if (sessionId && selectingProfile && pathname !== ROUTE_SELECTEER_PROFIEL) {
      const validate = async () => {
        await validateToken(selectingProfile);
      };
      validate();
    }
  }, []);

  /**
   * if route matches ROUTE_PUBLIC_INVESTIGATION_OMV_VIEW
   * then we need to call the api with the given data
   * that data will be used to create a new spatial research project
   * and then we redirect to the newly created program
   */
  useEffect(() => {
    const fetchPublicInvestigation = async (projectNumber) => {
      const { data } = await getPublicInvestigation(projectNumber, window.location.search);
      if (data.id) {
        return push(ROUTE_PUBLIC_INVESTIGATION_PROCEDURE + "/" + data.id);
      }
      push(ROUTE_BASE);
    };

    if (isStartSpacialResearchFromOMV) {
      const regex = /OMV_[0-9]{10}/g;
      const [omv] = projectNumber.match(regex);
      if (omv) {
        fetchPublicInvestigation(omv);
      } else {
        notifierStore.enqueueSnackbar({
          message: "Het omgevingsnummer is niet correct.",
          options: { variant: MESSAGE_TYPES.WARNING },
        });
        push(ROUTE_BASE);
      }
    }
  }, []);

  useEffect(() => {
    const getNotifications = async () => {
      const data = await getLeggerNotifications();
      if (data?.notifications?.length) {
        data.notifications.filter(canShowNotification).forEach((notif) => {
          const canExpand = true; // !!notif.body || !!notif.url; // for now always
          const variant = notif.type || MESSAGE_TYPES.INFO;
          notifierStore.enqueueSnackbar({
            message: canExpand
              ? {
                  id: notif.id,
                  title: notif.title,
                  message: [notif.body],
                  isExpanded: canExpand,
                  variant,
                  link: !notif.url ? null : { url: notif.url, text: "Meer info" },
                }
              : notif.title,
            options: { variant, persist: canExpand, key: notif.id },
          });
        });
      }
    };
    getNotifications();
  }, []);

  return (
    <React.Fragment>
      <Notifier />
      <Route exact path={ROUTE_SELECTEER_PROFIEL} component={SelectProfile} />
      <Route
        render={(props) =>
          props.location.pathname !== ROUTE_SELECTEER_PROFIEL && (
            <div className={classes.root} ref={rootRef}>
              <CssBaseline />
              <Sidebar />
              <HeaderView />
              <ErrorModal
                {...uiStore.errorModel}
                open={errorModel.open}
                handleClose={() => {
                  errorModel.setIsOpen(false);
                }}
              />
              <SelectNis open={dialogSelectNisOpen} closeFn={setDialogSelectNisOpen} />

              <main
                className={classNames(classes.content, {
                  [classes.contentShift]: sideBarOpen,
                })}
              >
                <div className={classes.drawerHeader} ref={headerRef} />
                {/* 
                route for switching between two maps
                we have the public investigation map 
                and another one for all other routes
                */}
                <Route
                  exact
                  path={[ROUTE_PUBLIC_INVESTIGATION, ROUTE_PUBLIC_INVESTIGATION_PROCEDURE, ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW]}
                  component={MapPublicInvestigationView}
                />
                <Route exact path={[ROUTE_PUBLIC_INVESTIGATION_RESULT, ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW]} component={PublicInvestigationResult} />
                {maps.length > 0 && (
                  <Route
                    exact
                    path={[
                      ROUTE_BASE,
                      ROUTE_LOGGING,
                      ROUTE_LOGGING_GENERAL,
                      ROUTE_LOGGING_MAGDA,
                      ROUTE_SELECTEER_PROFIEL,
                      ROUTE_PERCEEL,
                      ROUTE_PERCEELINFO,
                      ROUTE_SEARCH_CAPAKEY,
                      ROUTE_SEARCH_CAPAKEY_TYPE,
                      ROUTE_SEARCH_OWNER,
                      ROUTE_SEARCH_OWNER_TYPE,
                      ROUTE_PERSONS_PARCELS,
                      ROUTE_HISTORY,
                      ROUTE_SETTINGS,
                    ]}
                    component={MapView}
                  />
                )}
              </main>
            </div>
          )
        }
      />
    </React.Fragment>
  );
});

BasePage.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(BasePage));
