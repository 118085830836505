import React, { useEffect, useRef, useState } from "react";
import { FeatureGroup, GeoJSON, withLeaflet } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";

L.drawLocal.draw.toolbar.buttons.polyline = "";
L.drawLocal.draw.toolbar.buttons.polygon = "";
L.drawLocal.draw.toolbar.buttons.circle = "";
L.drawLocal.draw.toolbar.buttons.rectangle = "";
L.drawLocal.draw.toolbar.buttons.marker = "";
L.drawLocal.draw.toolbar.buttons.circlemarker = "";
L.drawLocal.edit.toolbar.buttons.edit = "";
L.drawLocal.edit.toolbar.buttons.remove = "";
L.drawLocal.edit.toolbar.buttons.editDisabled = "Geen figuren om te bewerken.";
L.drawLocal.edit.toolbar.buttons.removeDisabled = "Geen figuren om te verwijderen.";
L.drawLocal.draw.toolbar.undo.title = "Verwijder laatste punt";
L.drawLocal.draw.toolbar.undo.text = "Verwijder laatste punt";
L.drawLocal.draw.toolbar.finish.title = "Klaar";
L.drawLocal.draw.toolbar.finish.text = "Klaar";
L.drawLocal.draw.toolbar.actions.title = "Annuleer";
L.drawLocal.draw.toolbar.actions.text = "Annuleer";
L.drawLocal.edit.toolbar.actions.save.title = "Klaar";
L.drawLocal.edit.toolbar.actions.save.text = "Klaar";
L.drawLocal.edit.toolbar.actions.cancel.title = "Annuleer";
L.drawLocal.edit.toolbar.actions.cancel.text = "Annuleer";
L.drawLocal.edit.toolbar.actions.clearAll.title = "Wis alles";
L.drawLocal.edit.toolbar.actions.clearAll.text = "Wis alles";
L.drawLocal.draw.handlers.rectangle.tooltip.start = "Klik en sleep om een vierkant te beginnen tekenen.";
L.drawLocal.draw.handlers.polyline.tooltip.start = "Klik om een lijn te beginnen tekenen.";
L.drawLocal.draw.handlers.polyline.tooltip.cont = "Klik om verder te gaan met de lijn.";
L.drawLocal.draw.handlers.polyline.tooltip.end = "Klik op het laatste punt om de lijn af te sluiten.";
L.drawLocal.draw.handlers.polygon.tooltip.start = "Klik om een figuur te beginnen tekenen.";
L.drawLocal.draw.handlers.polygon.tooltip.cont = "Klik om verder te gaan met de figuur.";
L.drawLocal.draw.handlers.polygon.tooltip.end = "Klik op het eerste punt om de figuur af te sluiten.";
L.drawLocal.draw.handlers.circle.tooltip.start = "Klik en sleep om een cirkel te beginnen tekenen.";
L.drawLocal.draw.handlers.simpleshape.tooltip.end = "Laat los om de figuur af te sluiten.";
L.drawLocal.edit.handlers.edit.tooltip.text = "Sleep ankers om een figuur aan te passen.";
L.drawLocal.edit.handlers.edit.tooltip.subtext = "Klik op annuleer om de wijzigingen ongedaan te maken.";
L.drawLocal.edit.handlers.remove.tooltip.text = "Klik op een figuur om te verwijderen.";

/**
 *
 * @param {onChange} props
 */
const DrawTool = ({
  onChange,
  drawedGeoJson,
  featureGroupRef = null,
  drawOptions = {
    metric: true,
    circle: false,
    circlemarker: false,
    marker: false,
    polyline: false,
  },
}) => {
  const featureGroup = featureGroupRef ? featureGroupRef : useRef(null);

  const [geoJson, setGeoJson] = useState(null);
  const previousGeoJson = usePrevious(geoJson);

  const [isEditing, setIsEditing] = useState(false);

  const handler = (event) => {
    if (featureGroup.current) {
      setGeoJson(featureGroup.current.leafletElement.toGeoJSON());
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousGeoJson) !== JSON.stringify(geoJson) && geoJson) {
      onChange(geoJson);
    }
  }, [geoJson]);

  /**
   * interval used to autoupdate the values
   * for example when deleting is activated there are no new events dispatched
   * only when the user explicitly pushes saves button
   */
  useInterval(
    () => {
      handler();
    },
    isEditing ? 500 : null,
  );

  return (
    <FeatureGroup ref={featureGroup}>
      <EditControl
        draw={drawOptions}
        position="topleft"
        onEdited={handler}
        onCreated={handler}
        onDeleted={handler}
        onEditStart={handler}
        onEditStop={handler}
        onDeleteStart={() => setIsEditing(true)}
        onDeleteStop={() => setIsEditing(false)}
      />
      {drawedGeoJson.features && drawedGeoJson.features.length > 0 && <GeoJSON data={drawedGeoJson} />}
    </FeatureGroup>
  );
};

export default withLeaflet(DrawTool);

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
