export const ExpansionPanelStyles = {
  root: {
    border: "1px solid rgba(0,0,0,.125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
  },
};

export const ExpansionPanelSummaryStyles = {
  root: {
    margin: 0,
    backgroundColor: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    marginBottom: -1,
    minHeight: 50,
    "&$expanded": {
      minHeight: 50,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    margin: 0,
  },
};

export const ExpansionPanelDetailsStyles = (theme) => ({
  root: {
    padding: 0,
  },
});
