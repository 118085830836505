// root auth
export const ROUTE_BASE = "/";
export const ROUTE_LOGGING = "/logging";
export const ROUTE_LOGGING_GENERAL = ROUTE_LOGGING + "/general";
export const ROUTE_LOGGING_MAGDA = ROUTE_LOGGING + "/magda";
export const ROUTE_SELECTEER_PROFIEL = ROUTE_BASE + "account/selecteer";
export const ROUTE_PERCEEL = ROUTE_BASE + "perceelsidentificatie/";
export const ROUTE_PERCEELINFO = ROUTE_BASE + "perceelsidentificatie/:leggerType?/:capakey?";
export const ROUTE_SEARCH_CAPAKEY = ROUTE_BASE + "capakey/";
export const ROUTE_SEARCH_CAPAKEY_TYPE = ROUTE_BASE + "capakey/:leggerType?";
export const ROUTE_SEARCH_OWNER = ROUTE_BASE + "zoeken/";
export const ROUTE_SEARCH_OWNER_TYPE = ROUTE_BASE + "zoeken/:leggerType?";
export const ROUTE_PERSONS_PARCELS = ROUTE_BASE + "zoeken/:leggerType/:officialId";
export const ROUTE_HISTORY = ROUTE_BASE + "geschiedenis";
export const ROUTE_SETTINGS = ROUTE_BASE + "instellingen";
export const ROUTE_PUBLIC_INVESTIGATION = ROUTE_BASE + "ruimtelijk-onderzoek";
export const ROUTE_PUBLIC_INVESTIGATION_PROCEDURE = ROUTE_PUBLIC_INVESTIGATION + "/procedure";
export const ROUTE_PUBLIC_INVESTIGATION_OMV = ROUTE_PUBLIC_INVESTIGATION + "/OMV";
export const ROUTE_PUBLIC_INVESTIGATION_OMV_VIEW = ROUTE_PUBLIC_INVESTIGATION_OMV + "/:projectNumber";
export const ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW = ROUTE_PUBLIC_INVESTIGATION_PROCEDURE + "/:id";
export const ROUTE_PUBLIC_INVESTIGATION_RESULT = ROUTE_PUBLIC_INVESTIGATION + "/resultaat";
export const ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW = ROUTE_PUBLIC_INVESTIGATION_RESULT + "/:id";
