import { makeStyles } from "@mui/styles";
const useHeaderFabStyles = () => {
    return makeStyles({
        customfab: {
            border: 0,
            boxShadow: "none",
            textAlign: "center",
            backgroundColor: "transparent",
            height: "auto",
            padding: 8,
            paddingLeft: 14,
            transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            "&:active": {
                boxShadow: "none",
            },
        },
        customfabicon: {
            fontSize: "1.5rem",
            marginLeft: 12,
        },
    })();
};
export default useHeaderFabStyles;
