import { createTheme } from "@mui/material/styles";

const styles = (theme) => ({
  container: {
    height: "calc(100% - 64px)",
    width: "100%",
  },
  tableWrapper: {
    height: "100%",
  },
});

export const getParcelInfoTableStyle = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          borderRadius: 0,
          height: "100%",
        },
        responsiveScroll: {
          height: "auto",
          maxHeight: "calc(100% - 157px) !important",
        },
        MUIDataTableFilterList: {
          minHeight: "40px",
        },
      },
      MUIDataTableFilterList: {
        minHeight: "40px",
      },
    },
  });

export default styles;
