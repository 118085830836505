import { withStyles } from "@mui/styles";
import L from "leaflet";
import { Map } from "@orbit/components";
import { StoresContext } from "contexts";
import Logo from "layout/img/MapLogo.png";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FeatureGroup, Polygon, withLeaflet, Tooltip } from "react-leaflet";
import styles from "./MapStyles";
import BaseMap from "./shared-map-components";
import RenderIf from "components/RenderIf";
import classNames from "classnames";
import { publicInvestigationResearchTypes } from "config/config";
import { toJS } from "mobx";
import { PUBLIC_INVESTIGATION_RESEARCH_TYPE } from "stores/models/PublicInvestigationModel";

const logo = {
  img: Logo,
  url: "https://www.orbitgis.com/",
};

const PolygonComp = ({ researchType, type, capakey, coordinates }) => {
  // style
  const dynamicClasses = {};
  if (researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.PERIMETER) {
    dynamicClasses["P"] = true;
  } else {
    dynamicClasses[type] = true;
  }
  const polygonClasses = classNames("parcel", dynamicClasses);

  // type
  const currentType = publicInvestigationResearchTypes.find((researchType) => researchType.value === type);
  const typeLabel = currentType && currentType.label;

  // draw
  return (
    <Polygon className={polygonClasses} positions={coordinates}>
      <Tooltip>
        {capakey}
        {researchType === PUBLIC_INVESTIGATION_RESEARCH_TYPE.PERIMETER ? "" : <> {typeLabel}</>}
      </Tooltip>
    </Polygon>
  );
};

const MapPublicInvestigationResultView = hooksObserver(({ classes }) => {
  const {
    mapStore: { setZoomLevel, zoomLevel, latLng, bounds, setBounds, mapLayer: { activeMap }, setBoundsForGeoJson },
    applicationStore: { publicInvestigation },
  } = useContext(StoresContext);

  const [geoJsonData, setGeoJsonData] = useState(null);

  let mapRef = useRef();

  const onZoom = (action) => {
    console.log("onZoom");
    if (action === "zoomIn") setZoomLevel(zoomLevel + 1);
    else if (action === "zoomOut") setZoomLevel(zoomLevel - 1);
  };

  const zoom = {
    currentLevel: zoomLevel,
    zoom: onZoom,
    maxLevel: activeMap ? activeMap.maxZoom : 20,
    minLevel: activeMap ? activeMap.minZoom : 1,
  };

  const onZoomend = (action) => {
    setZoomLevel(action.target._zoom);
  };

  useEffect(() => {
    if (publicInvestigation?.geoJsonData && !!Object.keys(publicInvestigation.geoJsonData).length) {
      setGeoJsonData(toJS(publicInvestigation?.geoJsonData));
    } else {
      setGeoJsonData(null);
    }
  }, [publicInvestigation?.geoJsonData]);

  useEffect(() => {
    if (mapRef.current && geoJsonData) {
      setBoundsForGeoJson(geoJsonData);
    }
  }, [mapRef, geoJsonData]);

  return (
    <React.Fragment>
      <Map
        zoom={zoom}
        latLng={latLng}
        logo={logo}
        header={true}
        mapRef={mapRef}
        bounds={bounds}
        onViewportChanged={() => setBounds(null)}
        style={{
          height: "calc(100% - 64px)",
        }}
        customClass={classes.publicInvestigationMap}
        onZoomend={onZoomend}
      >
        {activeMap && <BaseMap map={activeMap} />}
        <RenderIf condition={geoJsonData}>
          <FeatureGroup>
            {geoJsonData?.features?.map((feature, i) => (
              <PolygonComp
                key={i}
                coordinates={feature.geometry?.coordinates}
                capakey={feature.properties?.capakey}
                type={feature.properties?.type}
                researchType={publicInvestigation.researchType}
              />
            ))}
          </FeatureGroup>
        </RenderIf>
      </Map>
    </React.Fragment>
  );
});

export default withLeaflet(withStyles(styles)(MapPublicInvestigationResultView));
