import { observable, action } from "mobx";
import { persist } from "mobx-persist";
import MapLayerModel from "./models/MapLayerModel";
import PublicModel from "./models/PublicModel";
import { getLocation } from "../services/geo";
import NeighboorhoodModel from "./models/NeighboorhoodModel";
import { getSelectableParcels } from "../services/api";
import L from "leaflet";
import MappingCloudModel from "./models/MappingCloudModel";

export default class MapStore {
  /**
   * model used to control the layer of the maps
   */
  @persist("object", MapLayerModel)
  @observable
  mapLayer = new MapLayerModel();

  @persist("object", PublicModel)
  @observable
  publicMaps = new PublicModel();

  @persist("object", NeighboorhoodModel)
  @observable
  neighboorhood = new NeighboorhoodModel();

  @observable
  mappingCloud = new MappingCloudModel(this.setBounds);

  @persist("object")
  @observable
  latLng = { lat: 51.076964, lng: 3.677793 };

  @observable
  selectableParcels = [];

  @observable
  bounds;

  @persist
  @observable
  zoomLevel = 18;

  @action
  setLatLng = (latLng) => {
    console.log("setLatLng", latLng);
    this.latLng = latLng;
  };

  @observable
  pointOfInterest = null;

  @action
  center = (geoJson) => {
    try {
      const center = L.geoJSON(geoJson).getBounds().getCenter();

      this.setLatLng(center);
    } catch (error) {
      console.log("center map to publicinvestigation error", error.toString());
    }
  };
  @action
  getSelectableParcels = async (bounds) => {
    const data = await getSelectableParcels(bounds);
    this.selectableParcels = data;
  };

  @action.bound
  setBounds = (bounds, pad = 0.3) => {
    if (bounds) bounds = bounds.pad(pad);
    this.bounds = bounds;
  };

  @action
  setZoomLevel = (zoomLevel) => {
    this.zoomLevel = zoomLevel;
  };

  @action
  setPointOfInterest = (val) => {
    this.pointOfInterest = val;
  };

  @action
  setBoundsForGeoJson = (geojson) => {
    if (geojson && geojson.features?.length) {
      let myPolygon = L.geoJson(geojson);
      const newBounds = myPolygon.getBounds();
      const copyBounds = JSON.parse(JSON.stringify(newBounds));
      newBounds._southWest.lat = copyBounds._southWest.lng;
      newBounds._southWest.lng = copyBounds._southWest.lat;
      newBounds._northEast.lat = copyBounds._northEast.lng;
      newBounds._northEast.lng = copyBounds._northEast.lat;
      this.bounds = newBounds;
    }
  };

  @action.bound
  async searchLatLongForLocation(location) {
    const result = await getLocation(location);
    this.latLng = result;
  }
}
