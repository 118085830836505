import MuiAlert from "@mui/lab/Alert";
import { StoresContext } from "contexts";
import React, { useContext } from "react";
import { observer as hooksObserver } from "mobx-react-lite";
import { toJS } from "mobx";

function Alert(props) {
  return <MuiAlert style={{ margin: 5 }} elevation={6} variant="filled" {...props} />;
}

export default hooksObserver(function NotInPlanMessage({ code }) {
  const {
    authStore: { subscriptions },
  } = useContext(StoresContext);

  if (!subscriptions.getSubscriptionByCode(code)) {
    return <Alert severity="info">Deze optie zit niet in uw formule!</Alert>;
  }

  return null;
});
