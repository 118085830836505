import { action, observable, toJS, flow, reaction } from "mobx";
import { getLogging } from "services/api";
import TableModel from "./TableModel";

export const LOGGING_MODE_ENUM = Object.freeze({
  APPLICATION: "normal",
  MAGDA: "magda",
});

export default class LoggingModel {
  @observable
  loading = false;

  @observable
  applicationLogging = new TableModel();

  @observable
  magdaLogging = new TableModel();

  @observable
  mode = LOGGING_MODE_ENUM.APPLICATION;

  @observable
  startDate = new Date().setTime(new Date().getTime() - 24 * 60 * 60 * 1000 * 7);

  @observable
  endDate = new Date().setTime(new Date().getTime());

  @action
  loadLogging = async () => {
    this.loading = true;
    try {
      this.applicationLogging.insert([]);
      this.magdaLogging.insert([]);

      const start = Number.isInteger(this.startDate) ? this.startDate : this.startDate.getTime();
      const end = Number.isInteger(this.endDate) ? this.endDate : this.endDate.getTime();

      const [appData, magdaData] = await Promise.all([getLogging(LOGGING_MODE_ENUM.APPLICATION, start, end), getLogging(LOGGING_MODE_ENUM.MAGDA, start, end)]);

      this.applicationLogging.insert(appData.data);
      this.magdaLogging.insert(magdaData.data);
    } catch (error) {
      console.log("ERROR::", error.toString());
    }
    this.loading = false;
  };

  @action
  setStartDate = (newDate) => {
    this.startDate = newDate;
  };

  @action
  setEndDate = (newDate) => {
    this.endDate = newDate;
  };

  @action
  toggleMode = () => {
    this.mode === LOGGING_MODE_ENUM.APPLICATION ? (this.mode = LOGGING_MODE_ENUM.MAGDA) : (this.mode = LOGGING_MODE_ENUM.APPLICATION);
  };

  @action
  setMode = (newmode) => {
    this.mode = newmode;
  };
}
