import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    padding: 2,
    backgroundColor: "transparent",
  },
  stepLabel: {
    "& .MuiStepLabel-alternativeLabel": {
      marginTop: 4,
    },
  },
});

function getSteps() {
  return ["Selecteer databron...", "Weergave op map", "Kies type opzoeking", "Kies type onderzoek", "Weergave percelen op kaart", "Weergave resultaten"];
}

const HorizontalLabelPositionBelowStepper = hooksObserver((props) => {
  const {
    applicationStore: { publicInvestigation: { status } },
  } = useContext(StoresContext);

  const { classes } = props;
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper activeStep={status} alternativeLabel className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel className={classes.stepLabel}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
});

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);
