import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import ErrorModel from "./models/ErrorModel";
import PopupModel from "./models/PopupModel";

export const PERCEEL_INFO_PANEL_ENUM = Object.freeze({
  OPEN: 1,
  OPEN_MAXIMIZED: 2,
  CLOSED: 3,
  MINIMIZED: 4,
  FIXED: 5,
});

export const TOUR_ENUM = Object.freeze({
  HEADER: 1,
  PERCEELINFO: 2,
  RUIMTELIJK_ONDERZOEK: 3,
});

export default class UiStore {
  @persist
  @observable
  muncipality = "Gent";

  @persist
  @observable
  contentHeight;

  @persist
  @observable
  sideBarOpen = true;

  @persist
  @observable
  sideBarRightOpen = false;

  @persist
  @observable
  tourType = TOUR_ENUM.HEADER;

  @persist
  @observable
  showTour = true;

  @observable
  tourIndex = 0;

  @persist
  @observable
  perceelInfoPanel = PERCEEL_INFO_PANEL_ENUM.MINIMIZED;

  @observable errorModel = new ErrorModel();

  @observable dialogSelectProfileOpen = false;

  @observable dialogSelectNisOpen = false;

  @observable duplicateSpatialResearchResult = false;

  @observable mapSpatialResearchResult = false;

  @persist("list")
  @observable
  history = [];

  @observable popupModel = new PopupModel();

  @action
  setSideBarOpen = (isOpen) => {
    this.sideBarOpen = isOpen;
  };

  @action
  setShowTour = (showTour, type = null) => {
    if (type) {
      this.tourIndex = 0;
      this.tourType = type;
    }
    this.showTour = showTour;
  };

  @action
  setTourIndex = (tourIndex) => {
    this.tourIndex = tourIndex;
  };

  @action
  setDialogSelectProfileOpen = (isOpen) => {
    this.dialogSelectProfileOpen = isOpen;
  };

  @action
  setDialogSelectNisOpen = (isOpen) => {
    this.dialogSelectNisOpen = isOpen;
  };

  @action
  addToHistory = (data) => {
    const historyItem = { date: Date.now(), data };
    this.history = [...this.history.splice(-29, 29), historyItem];
  };

  @action
  setSideBarRightOpen = (isOpen) => {
    this.sideBarRightOpen = isOpen;
  };

  @action
  setContentHeight = (height) => {
    this.contentHeight = height;
  };

  @action
  setPerceelInfoPanel = (state) => {
    this.perceelInfoPanel = state;
  };

  @action
  togglePerceelInfoPanelOpenMaximize = () => {
    if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED) {
      this.perceelInfoPanel = PERCEEL_INFO_PANEL_ENUM.OPEN;
    } else {
      this.perceelInfoPanel = PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED;
    }
  };

  @action
  minimizePerceelInfoPanel = () => {
    this.perceelInfoPanel = PERCEEL_INFO_PANEL_ENUM.MINIMIZED;
  };

  @action
  setDuplicateSpatialResearchResult = (value) => {
    this.duplicateSpatialResearchResult = value;
  };

  @action
  setMapSpatialResearchResult = (value) => {
    this.mapSpatialResearchResult = value;
  };

  @computed
  get perceelInfoTableHeight() {
    if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED) {
      return "calc(" + Math.round(this.contentHeight) + "px - 175px )";
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN) {
      return "calc(" + Math.round(this.contentHeight / 2) + "px - 175px )";
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.MINIMIZED) {
      return "calc(64px)";
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.CLOSED) {
      return 0;
    }
  }

  @computed
  get perceelInfoPanelHeight() {
    if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED) {
      return "calc(" + Math.round(this.contentHeight) + "px -  102px )";
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN) {
      return "calc(" + Math.round(this.contentHeight / 2) + "px -  102px )";
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.MINIMIZED) {
      return "calc(64px )";
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.CLOSED) {
      return 0;
    }
  }

  @computed
  get mapHeight() {
    if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN_MAXIMIZED) {
      return 0;
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.OPEN) {
      return Math.round(this.contentHeight / 2);
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.MINIMIZED) {
      return this.contentHeight - 72;
    } else if (this.perceelInfoPanel === PERCEEL_INFO_PANEL_ENUM.CLOSED) {
      return this.contentHeight;
    }
  }
}
