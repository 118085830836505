import { MAP_TYPES } from "app-constants";
import React from "react";
import { TileLayer, WMSTileLayer } from "react-leaflet";
import WMTSTileLayer from "react-leaflet-wmts";

const MapLayer = ({ ...props }) => {
  return (
    <>
      {props.type === MAP_TYPES.WMTS && <WMTSTileLayer {...props} />}
      {props.type === MAP_TYPES.TILE && <TileLayer {...props} />}
      {props.type === MAP_TYPES.WMS && <WMSTileLayer {...props} />}
    </>
  );
};

const BaseMap = ({ map, overlay }) => {
  return (
    <>
      {!!map && <MapLayer key={map.title} {...map} zIndex={1} />}
      {!!overlay && <MapLayer key={overlay.title} {...overlay} zIndex={2} />}
    </>
  );
};

export default BaseMap;
