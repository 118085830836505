import { withStyles } from "@mui/styles";
import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardContent, CardHeader, Grid, Icon } from "@mui/material";

import messages from "./messages";

import styles from "./RetryPanelStyles";

const RetryPanel = ({ classes }) => (
  <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: "90vh" }}>
    <Card className={classes.card}>
      <CardHeader avatar={<Icon className={"icon-logo-orbit-black"} />} title={"Geoportaal Legger"} />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            Oops, deze applicatie is vastgelopen. Gelieve de pagina te verversen om opnieuw te proberen.
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={(e) => {
                window.location.reload();
              }}
            >
              Klik hier om de pagina opnieuw te laden.
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default withStyles(styles)(RetryPanel);
