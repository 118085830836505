import proj4 from "proj4";

proj4.defs([
  [
    "EPSG:31370",
    "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs ",
  ],
  ["EPSG:3857", "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"],
]);

// examples

// from crs=31370 (Lambert72)
// convertLocationToWGS84(31370, 104341.20, 192438.63)

// from crs=3857 (Web Mercator)
// convertLocationToWGS84(3857,  413857.00, 6628429.41)

// from crs=4326 (standaard)
// convertLocationToWGS84(4326,  51.04032251198258, 3.717740685398528)

// out WGS84 should be ~ { lat: 51.04032251198258, lng: 3.717740685398528 }

export const convertLocationToWGS84 = (fromCrs, x, y) => {
  if (Number(fromCrs) === 4326) {
    return { lat: y, lng: x };
  }
  let result = proj4(`EPSG:${fromCrs}`, "WGS84", [x, y]);
  return { lat: result[1], lng: result[0] };
};
