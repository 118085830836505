import React from "react";
import { Grid } from "@mui/material";
import RenderIf from "components/RenderIf";
import NotInPlanMessage from "components/NotInPlanMessage";
import { SUBSCRIPTION_CODE, APP_LEGGER_TYPES } from "app-constants";

export function SubscriptionMessage({ code }) {
  return (
    <Grid item>
      <NotInPlanMessage code={code} />
    </Grid>
  );
}

export default function SearchSubscriptionMessage({ type }) {
  return (
    <Grid item>
      <NotInPlanMessage code={type === APP_LEGGER_TYPES.FISCAL ? SUBSCRIPTION_CODE.SEARCH_URBAIN : SUBSCRIPTION_CODE.SEARCH_BY_MAGDA} />
    </Grid>
  );
}

export function TableSubscriptionMessage({ type }) {
  return (
    <Grid xs={3} item>
      <NotInPlanMessage code={type === APP_LEGGER_TYPES.FISCAL ? SUBSCRIPTION_CODE.SEARCH_URBAIN : SUBSCRIPTION_CODE.SEARCH_BY_MAGDA} />
    </Grid>
  );
}
