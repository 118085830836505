const styles = {
  root: {
    width: "100%",
  },
  input: {
    width: 50,
    marginLeft: 10,
    marginBottom: 10,
  },
};
export default styles;
