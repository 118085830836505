import React from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LockIcon from "@mui/icons-material/Lock";
import MinimizeIcon from "@mui/icons-material/Minimize";
import perceelsidentificatie from "../../public/img/perceelsidentificatie.png";
import uitgebreid_zoeken from "../../public/img/uitgebreid_zoeken.png";

export const headerSteps = [
  {
    disableBeacon: true,
    target: "#tour",
    offset: -10,
    content: "Welkom bij de algemene rondleiding van Geoportaal Legger. Hier kan je een rondleiding volgen met de nieuwigheden in de applicatie.",
  },
  {
    target: "#zoom-in",
    placement: "left",
    offset: -10,
    title: <>Navigeren / navigatie</>,
    content: (
      <>
        Gebruik de scroll functie of navigeer hier met + en -. Bij touchscreens kan je dubbeltikken op het scherm om in te zoomen. Je kan tevens het scherm aanraken met twee
        vingers, en die naar elkaar toe of van elkaar weg bewegen om in- en uit te zoomen
      </>
    ),
  },
  {
    target: "#map-layers",
    placement: "left",
    offset: -10,
    title: <>Kaartlaag</>,
    content: <>Klik hier om de Kaartlaag te wijzigen</>,
  },
  {
    target: "#header-search",
    offset: -10,
    title: <>Zoeken op adres of capakey</>,
    content: <>Geef hier een adres of perceelnummer in</>,
  },
  {
    target: "#header-search-advanced",
    offset: -10,
    title: <>Uitgebreid zoeken</>,
    content: (
      <>
        Hier kan je zoeken op Perceel of eigenaar Afbeelding toevoegen
        <br />
        <br />
        <img src={uitgebreid_zoeken} style={{ maxWidth: 300 }} />
      </>
    ),
  },
  {
    target: "#user-fab",
    offset: -10,
    title: <>Profiel wijzigen/uitloggen</>,
    content: <>Klik hier als je je finaliteit wilt wijzigen of uitloggen. Log je uit, dien je je opnieuw aan te melden met ACM/IDM</>,
  },
  {
    target: "#map",
    offset: -10,
    title: <>Perceel selecteren op kaart</>,
    placement: "center",
    content: (
      <>
        Door op een perceel te klikken, kan je actuele of fiscale bevragingen doen
        <br />
        <br />
        <img src={perceelsidentificatie} style={{ maxWidth: 300 }} />
      </>
    ),
  },
  {
    target: "#tour",
    offset: -10,
    title: <>Succes</>,
    content: <>Je weet nu helemaal je weg in Geoportaal Legger</>,
  },
];

export const perceelSteps = [
  {
    disableBeacon: true,
    offset: -10,
    target: "#tour-parcelinfo",
    offset: -10,
    content: "Welkom bij de rondleiding tabelinformatie!",
  },
  {
    target: "#tour-capakey",
    offset: -10,
    placement: "left",
    title: <>Perceelnummer</>,
    content: <>Perceelnummer van het geselecteerde perceel</>,
  },
  {
    target: "#tour-search",
    offset: -10,
    placement: "left",
    title: <>Zoeken</>,
    content: <>Zoeken in de actieve tabel</>,
  },
  {
    target: "#tour-download",
    offset: -10,
    placement: "left",
    title: <>Downloaden</>,
    content: <>Download de actieve tabel naar een CSV bestand</>,
  },
  {
    target: "#tour-view",
    offset: -10,
    placement: "left",
    title: <>Weergave van resultaten</>,
    content: (
      <>
        <MinimizeIcon /> Minimaliseer
        <br />
        <FullscreenExitIcon /> Halveer
        <br />
        <FullscreenIcon /> Maximaliseer
      </>
    ),
  },
  {
    target: "#tour-table",
    offset: -10,
    placement: "left",
    title: <>Resultaten</>,
    content: (
      <>
        De resultaten worden weergegeven in 5 tabellen (klik op de tab om deze actief te maken):
        <br />
        Patrimoniumpercelen
        <br />
        Eigenaars
        <br />
        Bewoners
        <br />
        VKBO
        <br />
        Persoonsgegevens
        <br />
        <br />
        <LockIcon /> Je hebt geen toegang tot de gegevens vanuit jouw finaliteit
        <br />
        <MoreVertIcon /> Druk bij eigenaars op deze knop om persoon- of ondernemingsgegevens op te zoeken.
      </>
    ),
  },
  {
    target: "#tour-parcelinfo",
    offset: -10,
    placement: "left",
    title: <>Succes</>,
    content: <>Je weet nu helemaal je weg in de tabelinformatie</>,
  },
];

export const roSteps = [
  {
    disableBeacon: true,
    offset: -10,
    styles: { options: { width: 500 } },
    target: "#tour",
    offset: -10,
    content: (
      <>
        <iframe
          width="450"
          height="315"
          src="https://www.youtube.com/embed/lNPpEaBugTk"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </>
    ),
  },
];
