const styles = (theme) => ({
  root: {
    display: "flex",
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
});

export default styles;
