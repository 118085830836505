import { observable, action, remove, computed, toJS } from "mobx";
import { persist } from "mobx-persist";
import { publicLayers } from "config/config";
export default class PublicModel {
  @persist("object")
  @observable
  layersList = {};

  @persist("object")
  @observable
  layers = {};

  @action
  setShow = (layer, value) => {
    this.layersList[layer.name].show = value;
    if (value) {
      this.setLayerData(layer);
    } else {
      this.removeLayerData(layer);
    }
  };

  @action
  setOpacity = (layer, value) => {
    this.layersList[layer.name].opacity = value;
    if (this.layersList[layer.name].show) {
      this.setLayerData(layer);
    } else {
      this.removeLayerData(layer);
    }
  };

  @computed
  get layersArr() {
    return Object.entries(toJS(publicLayers)).map(([key, value]) => value);
  }

  @computed
  get activeArr() {
    return Object.entries(toJS(this.layers)).map(([key, value]) => value);
  }

  setLayerData = (layer) => {
    layer.opacity = this.layersList[layer.name].opacity;
    this.layers[layer.name] = layer;
  };

  removeLayerData = (layer) => {
    remove(this.layers, layer.name);
  };

  constructor() {
    Object.keys(publicLayers).forEach((el) => {
      this.layersList[publicLayers[el].name] = {
        show: false,
        opacity: publicLayers[el].opacity,
      };
    });
  }
}
