import { SIDEBAR_CONFIG } from "app-constants";

const styles = (theme) => ({
  drawer: {
    width: SIDEBAR_CONFIG.width,
    flexShrink: 0,
    overflow: "hidden",
  },
  drawerPaper: {
    width: SIDEBAR_CONFIG.width,
    overflow: "hidden",
    borderRight: 0,
  },
  drawerHeader: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    zIndex: 99,
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    ...theme.mixins.toolbar,
  },
  whiteText: {
    color: "white",
  },
  grow: {
    flexGrow: 1,
  },
  homeButton: {
    color: "white",
  },
  closeButton: {
    color: "white",
    marginRight: "10px",
  },
});

export default styles;
