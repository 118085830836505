import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { withStyles } from "@mui/styles";
import LayersIcon from "@mui/icons-material/Layers";
import ListIcon from "@mui/icons-material/List";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router";
import L from "leaflet";

import {
  ROUTE_PUBLIC_INVESTIGATION,
  ROUTE_PUBLIC_INVESTIGATION_PROCEDURE,
  ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW,
  ROUTE_PUBLIC_INVESTIGATION_RESULT,
  ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW,
} from "routes/RouteList";
import PublicInvestigationHistory from "./public-investigation/PublicInvestigationHistory";
import StartPublicInvestigationPanel from "./public-investigation/Start";
import styles, { useBottomActionStyles } from "./SidebarSearchStyles";
import { SUBSCRIPTION_CODE } from "app-constants";
import { SubscriptionMessage } from "views/advanced-search/search-subscription-message";

const SidebarPublicInvestigation = hooksObserver(({ classes, match: { path, params: { id } } }) => {
  const {
    mapStore,
    mapStore: { mapLayer, setLatLng },
    applicationStore,
    applicationStore: { publicInvestigation: { load, status }, publicInvestigation, publicInvestigationId, setPublicInvestigationId, resetPublicInvestigation, enableAutosave },
    routingStore: { push },
  } = useContext(StoresContext);

  const [bottomNav, setBottomNav] = useState(0);

  const classesBottomAction = useBottomActionStyles();

  useEffect(() => {
    mapLayer.setActiveMapIndex(1);

    return () => resetPublicInvestigation();
  }, []);

  const loadPublicInvestigation = async (id) => {
    applicationStore.setCanSave(false);
    setPublicInvestigationId(id);
    await load(id);
    applicationStore.setCanSave(true);
  };

  useEffect(() => {
    if (!(id === undefined && publicInvestigationId === null)) {
      if (publicInvestigationId !== null && id === undefined) {
        console.log("sidebar.store id is known, but route has no id, pushing route:", publicInvestigationId);
        enableAutosave();
        push(ROUTE_PUBLIC_INVESTIGATION_PROCEDURE + `/${publicInvestigationId}`);
      } else if (id !== undefined && publicInvestigationId === null) {
        console.log("sidebar.route id is known, but store has no id, loading id:", id);
        loadPublicInvestigation(id);
      } else {
        console.log("sidebar.store and route have id, toggling autorun functionality");
        enableAutosave();
      }
    } else {
      console.log("sidebar.store and route both have no id, so do nothing");
    }
  }, [publicInvestigationId]);

  useEffect(() => {
    switch (path) {
      case "/ruimtelijk-onderzoek/procedure":
      case "/ruimtelijk-onderzoek/procedure/:id":
      case "/ruimtelijk-onderzoek/resultaat/:id":
        setBottomNav(1);
        break;
      case "/ruimtelijk-onderzoek":
      default:
        setBottomNav(0);
        break;
    }
  }, [path]);

  return (
    <React.Fragment>
      <div className={classes.sidebarContent}>
        <SubscriptionMessage code={SUBSCRIPTION_CODE.PUBLIC_INVESTIGATION} />
        <Switch>
          <Route exact path={ROUTE_PUBLIC_INVESTIGATION}>
            <PublicInvestigationHistory />
          </Route>
          <Route
            exact
            path={[ROUTE_PUBLIC_INVESTIGATION_PROCEDURE, ROUTE_PUBLIC_INVESTIGATION_PROCEDURE_VIEW, ROUTE_PUBLIC_INVESTIGATION_RESULT, ROUTE_PUBLIC_INVESTIGATION_RESULT_VIEW]}
          >
            <StartPublicInvestigationPanel />
          </Route>
        </Switch>
      </div>
      <BottomNavigation value={bottomNav} showLabels className={classes.bottomNav}>
        <BottomNavigationAction
          classes={classesBottomAction}
          onClick={() => {
            push(ROUTE_PUBLIC_INVESTIGATION);
          }}
          label="Overzicht"
          icon={<ListIcon className={bottomNav === 0 ? classesBottomAction.selected : ""} />}
        />
        {(status < 5 && (
          <BottomNavigationAction
            classes={classesBottomAction}
            onClick={() => {
              publicInvestigationId !== null ? push(ROUTE_PUBLIC_INVESTIGATION_PROCEDURE + `/${publicInvestigationId}`) : push(ROUTE_PUBLIC_INVESTIGATION_PROCEDURE);
            }}
            label="Procedure"
            icon={<LayersIcon className={bottomNav === 1 ? classesBottomAction.selected : ""} />}
          />
        )) || (
          <BottomNavigationAction
            classes={classesBottomAction}
            onClick={() => {
              push(ROUTE_PUBLIC_INVESTIGATION_RESULT + `/${publicInvestigationId}`);
            }}
            label="Resultaat"
            icon={<LayersIcon className={bottomNav === 1 ? classesBottomAction.selected : ""} />}
          />
        )}
      </BottomNavigation>
    </React.Fragment>
  );
});

SidebarPublicInvestigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SidebarPublicInvestigation));
