import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

const ImportShapeContent = withStyles({
  input: {
    display: "none",
  },
  button: {
    marginTop: 20,
    width: "100%",
  },
})(({ classes, children, accept, onChange, disabled }) => (
  <div>
    {children}
    <input accept={accept} className={classes.input} id="contained-button-file" type="file" onChange={onChange} />
    <label htmlFor="contained-button-file">
      <Button disabled={disabled} variant="contained" component="span" className={classes.button}>
        Upload
      </Button>
    </label>
  </div>
));

export default ImportShapeContent;
