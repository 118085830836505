const styles = (theme) => ({
  card: {
    minWidth: 275,
    maxWidth: 450,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  link: {
    margin: theme.spacing.unit,
  },
  img: {
    maxWidth: 60,
    maxHeight: 80,
    margin: "0 auto",
  },
  acmidmimgs: {
    //borderTop: "1px dashed grey",
    marginTop: 15,
    paddingTop: 15,
  },
  gridItem: {
    textAlign: "center",
  },
});

export default styles;
