import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const SIDEBAR_WIDTH = 350;
const useHeaderStyles = () => {
    const theme = useTheme();
    return makeStyles({
        root: {
            width: "100%",
        },
        appBar: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            marginLeft: SIDEBAR_WIDTH,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        title: {
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
        },
    })();
};
export default useHeaderStyles;
