import { SIDEBAR_CONFIG } from "app-constants";

const styles = (theme) => ({
  root: {
    minHeight: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flexGrow: 1,
    marginLeft: -SIDEBAR_CONFIG.width,
    width: `100%`,
    transition: "width 1s",
  },
  contentShift: {
    marginLeft: 0,
    width: `calc(100% - ${SIDEBAR_CONFIG.width}px)`,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
});

export default styles;
