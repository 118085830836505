const styles = (theme) => ({
  customListItemBase: {
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
    display: "flex",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "left",
    alignItems: "center",
    paddingTop: "11px",
    paddingBottom: "11px",
    justifyContent: "flex-start",
    textDecoration: "none",
    color: "inherit",
    border: 0,
    margin: 0,
    cursor: "pointer",
    outline: "none",
    userSelect: "none",
    borderRadius: 0,
    verticalAlign: "middle",
    backgroundColor: "transparent",
  },
  customListItemBaseText: {
    paddingLeft: 0,
    flex: "1 1 auto",
    padding: "0 16px",
    minWidth: 0,
  },
  customListItemButton: {
    flex: "0 0 auto",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "12px",
    overflow: "visible",
    fontSize: "1.5rem",
    textAlign: "center",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "50%",
  },
  input: {
    marginLeft: "12px",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchWrapper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
});

export default styles;
