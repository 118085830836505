import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { List, ListItem, ListItemText, IconButton } from "@mui/material";
import styles from "./SidebarHistoryStyles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import moment from "moment";

const SidebarGeschiedenis = hooksObserver(({ classes }) => {
  const {
    uiStore: { history, minimizePerceelInfoPanel, popupModel: { getDataForCapakey } },
  } = useContext(StoresContext);

  return (
    <React.Fragment>
      <List className={classes.parcellist}>
        {[
          ...new Set(
            history.map((historyItem) => ({
              date: historyItem.date,
              capakey: historyItem.data.full,
            })),
          ),
        ]
          .reverse()
          .map(({ capakey, date }) => (
            <ListItem
              key={capakey}
              button
              onClick={(e) => {
                minimizePerceelInfoPanel();
                getDataForCapakey(capakey, true);
              }}
            >
              <ListItemText primary={capakey} secondary={moment(date).format("DD/MM/YY hh:mm")} />
              <IconButton size="small">
                <ArrowRightIcon />
              </IconButton>
            </ListItem>
          ))}
      </List>
    </React.Fragment>
  );
});

SidebarGeschiedenis.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarGeschiedenis);
