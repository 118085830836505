import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const VARIANTS_COLORS = {
  error: "rgb(211, 47, 47)",
  info: "rgb(2, 136, 209)",
  warning: "rgb(245, 124, 0)",
  success: "rgb(56, 142, 60)",
};

const styles = (theme) => ({
  card: {
    width: 400,
  },
  errorMessage: {
    maxHeight: 200,
    overflow: "auto",
  },
  typography: {
    fontWeight: "bold",
    width: "100%",
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
    backgroundColor: "#fddc6c",
    display: "flex",
  },
  action: {
    margin: 0,
  },
  icons: {
    display: "flex",
    flexDirection: "row",
  },
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: "#b3b3b3",
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: "none",
  },
});

const SnackMessage = React.forwardRef((props, ref) => {
  const {
    classes,
    title = "Melding van Magda",
    message,
    isExpanded = false,
    variant,
    link = {
      url: "https://wiki.geoportaal.be/nl/Legger/Meldingen",
      text: "Meer over Meldingen",
    },
  } = props;

  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(isExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };

  const isExpandable = (message?.length && message[0]) || link;
  const backgroundColor = VARIANTS_COLORS[variant];
  const color = VARIANTS_COLORS[variant] ? "white" : "black";

  return (
    <Card ref={ref} className={classes.card} data-test-id="card-meldingmagda">
      <CardActions classes={{ root: classes.actionRoot, action: classes.action }} style={{ backgroundColor, color }}>
        <Typography variant="subtitle2" className={classes.typography}>
          {title}
        </Typography>
        <div className={classes.icons}>
          {isExpandable && (
            <IconButton
              aria-label="Show more"
              className={classnames(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              style={{ color }}
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
          <IconButton className={classes.expand} onClick={handleDismiss} style={{ color }}>
            <CloseIcon />
          </IconButton>
        </div>
      </CardActions>
      {isExpandable && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            {message.map((error) => (
              <Typography className={classes.errorMessage} gutterBottom>
                {error}
              </Typography>
            ))}

            {link && (
              <Button size="small" className={classes.button} onClick={() => open(link.url, "_blank")}>
                <CheckCircleIcon className={classes.checkIcon} />
                {link.text}
              </Button>
            )}
          </Paper>
        </Collapse>
      )}
    </Card>
  );
});

SnackMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(SnackMessage);
