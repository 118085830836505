import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const width = "315px";
const useLayerSelectorStyles = () => {
    const theme = useTheme();
    return makeStyles({
        root: {
            overflowY: "auto",
        },
        fab: {
            margin: theme.spacing(1),
            color: "#e6e6e6",
        },
        toolbar: {
            color: "white",
            backgroundColor: theme.palette.secondary.main,
        },
        drawer: {
            overflowY: "initial",
        },
        content: {
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            minWidth: width,
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        badge: {
            marginLeft: "16px",
        },
        pos: {
            marginBottom: 12,
        },
        postop: {
            marginTop: 12,
            marginBottom: 12,
        },
    })();
};
export default useLayerSelectorStyles;
