import React, { useEffect } from "react";
import { Switch } from "react-router";
import { inject } from "mobx-react";
import { observer as hooksObserver } from "mobx-react-lite";

const PrivateRoutes = hooksObserver(({ authStore: { isLoggedIn, setRedirectUrl, redirectUrl }, children }) => {
  if (!isLoggedIn && !["/", "/account/selecteer"].includes(window.location.pathname)) setRedirectUrl(window.location.pathname + window.location.search);
  useEffect(() => {
    if (isLoggedIn && redirectUrl) {
      console.log("Redirect found - " + redirectUrl);
      window.location.href = redirectUrl;
      setRedirectUrl(null);
    }
  }, [isLoggedIn, redirectUrl]);

  if (isLoggedIn) return <Switch>{children}</Switch>;
  return null;
});

export default inject("authStore")(PrivateRoutes);
