import React, { useState, useContext, useEffect } from "react";
import { Button, Grid, TextField, Card, CardContent, Typography } from "@mui/material";
import { StoresContext } from "contexts";
import { getMagdaParcelExists } from "../../services/api";

const SearchCapakeyWeb = ({ type }) => {
  const {
    applicationStore: { search },
    notifierStore,
  } = useContext(StoresContext);

  const [capasearch, setCapasearch] = useState("");

  const onChange = (e) => {
    setCapasearch(e.target.value);
  };

  const searchParcelInMagda = async () => {
    const data = await search.searchMagdaParcelExists(capasearch);
    if (!data) notifierStore.enqueueSnackbar({ message: "Geen resultaten gevonden" });
  };

  return (
    <div>
      <Grid container spacing={2} direction="column" alignItems="stretch">
        <Grid item>
          <Typography variant="body2" component="p" color="textPrimary" gutterBottom>
            Deze zoekfunctie dient om zeer recente perceel mutaties die nog niet in de GRB percelen zijn opgenomen op te zoeken bij Magda. Er wordt geen perceel op kaart getoond.
          </Typography>
        </Grid>
        <Grid item>
          <TextField label="Vul een capakey in" variant="outlined" value={capasearch} onChange={onChange} fullWidth data-test-id="pnl-fill-in-capakey" />
        </Grid>
        <Grid item>
          <Button type="submit" variant="outlined" disabled={capasearch.length !== 17} fullWidth onClick={searchParcelInMagda} data-test-id="btn-submit-magda">
            Zoeken in Magda
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchCapakeyWeb;
