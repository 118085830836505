const styles = (theme) => ({
  container: {
    height: "calc(100% - 64px)",
    width: "100%",
  },
  tableWrapper: {
    height: "100%",
  },
});

export default styles;
