import * as turfHelpers from "@turf/helpers";
import turfArea from "@turf/area";
import turfLength from "@turf/length";

export const getArea = ({ coordinates }) => {
  const polygon = turfHelpers.polygon(coordinates);
  const area = turfArea(polygon);
  return area;
};

export const getPerimeter = ({ coordinates }) => {
  const line = turfHelpers.lineString(coordinates[0]);
  const length = turfLength(line, { units: "meters" });
  return length;
};
