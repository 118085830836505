import { action, autorun, observable } from "mobx";
import { getPublicInvestigationHistory } from "services/api";
import { APP_LEGGER_TYPES } from "app-constants";
import io from "socket.io-client";
import PerceelInfoModel from "./models/PerceelInfoModel";
import PublicInvestigationModel, { PUBLIC_INVESTIGATION_STATUS } from "./models/PublicInvestigationModel";
import searchModel from "./models/SearchModel";
import LoggingModel from "./models/LoggingModel";

export default class ApplicationStore {
  @observable
  canSave = true;

  // @persist("object", PerceelInfoModel)
  @observable
  perceelInfoActief = new PerceelInfoModel();

  @observable
  logging = new LoggingModel();

  @observable
  search = new searchModel();

  @observable
  publicInvestigation = new PublicInvestigationModel();

  @observable
  publicInvestigationHistory = [];

  @observable
  publicInvestigationId = null;

  @observable
  loading = false;

  autoSaveObject = null;

  authStore;

  constructor(authStore, uiStore) {
    this.authStore = authStore;
    this.uiStore = uiStore;
  }

  @action
  setCanSave = (value) => {
    this.canSave = value;
  };

  @action
  toggleLoading = () => {
    this.loading = !this.loading;
  };

  @action
  enableAutosave = () => {
    this.autoSaveObject = autorun(() => {
      if (!this.canSave) {
        return;
      }
      /*       console.log("autorun.status:", this.publicInvestigation.status);
      console.log("autorun.type:", this.publicInvestigation.type);
      console.log("autorun.publicInvestigationId:", this.publicInvestigationId);
 */
      if (this.publicInvestigation.status > 0 && this.publicInvestigation.status < 5 && this.publicInvestigation.type > 0) {
        console.log("Autosave");
        this.publicInvestigation.save(this.publicInvestigationId);
      }
    });
  };

  @action
  disableAutosave = () => {
    if (this.autoSaveObject) {
      this.autoSaveObject();
    }
  };

  @action
  loadPublicInvestigationHistory = async () => {
    try {
      const { data } = await getPublicInvestigationHistory();
      this.publicInvestigationHistory = data;
    } catch (error) {}
  };

  @action
  startPublicInvestigation = async () => {
    if (this.publicInvestigationId === null) {
      const id = await this.publicInvestigation.save();
      this.publicInvestigationId = id;
    }
    if (this.publicInvestigationId) {
      this.publicInvestigation.setStatus(PUBLIC_INVESTIGATION_STATUS.SELECT_ON_MAP);
    }
  };

  @action
  resetPublicInvestigation = async (leggerType = APP_LEGGER_TYPES.ACTUAL) => {
    this.disableAutosave();
    this.publicInvestigation = new PublicInvestigationModel();
    this.publicInvestigation.setLeggertype(leggerType);
    this.publicInvestigationId = null;
  };

  @action
  setPublicInvestigationId = (id) => {
    this.publicInvestigationId = id;
  };

  setupWebSocket = () => {
    var socket = io("http://localhost:3031", {
      query: "auth_token=THE_JWT_TOKEN",
    });
    // Connection failed
    socket.on("error", function (err) {
      throw new Error(err);
    });
    // Connection succeeded
    socket.on("success", function (data) {
      console.log(data.message);
      console.log("user info: " + data.user);
      console.log("logged in: " + data.user.logged_in);
    });
  };
}
