import { alpha } from "@mui/material/styles";

const styles = (theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  geavanceerd: {
    position: "relative",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: theme.palette.grey[200],
    fontWeight: 400,
    fontSize: "13.3333px",
    textTransform: "initial",
    borderColor: alpha(theme.palette.common.black, 0.15),
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
  },
  advancedSearchButton: {
    width: 250,
    height: 100,
  },
  activeButton: {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  capakeyTabs: {
    margin: -16,
    marginBottom: 16,
  },
});

export default styles;
