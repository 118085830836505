/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "application.title",
    defaultMessage: "Geoportaal Legger",
  },
});
