import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { convertShapeFile } from "services/api";
import CapakeyInputList from "./CapakeyInputList";
import ImportShapeContent from "./ImportShapeContent";
import styles from "./NextStepsStyles";
import PublicInvestigationList from "./PublicInvestigationList";
import SelectResearchType from "./SelectResearchType";
import SelectSearchType from "./SelectSearchType";
import ShowParcels from "./ShowParcels";
import ShowResults from "./ShowResults";

const ShapeUploader = hooksObserver(() => {
  const {
    applicationStore: { publicInvestigation: { setShape, leggerType }, toggleLoading },
    mapStore: { center },
  } = useContext(StoresContext);

  const [fileProcessing, setFileProcessing] = useState(false);
  return (
    <ImportShapeContent
      accept={".shp"}
      onChange={async (event) => {
        if (event.target.files) {
          toggleLoading();
          setFileProcessing(true);
          const shape = await convertShapeFile(event.target.files[0], leggerType);
          setShape(shape);
          center(shape);
          setFileProcessing(false);
          toggleLoading();
        }
      }}
      disabled={fileProcessing}
    >
      <Typography variant="subtitle2" gutterBottom>
        Klik op de knop om het gewenste bestand te selecteren (SHP, Geojson, WKT)
      </Typography>
      <Typography variant="caption" gutterBottom>
        <strong>Let op:</strong> Alle objecten uit het bestand zullen op kaart geladen worden. Zorg er dus voor dat enkel de gewenste objecten in het bestand zitten.
      </Typography>
    </ImportShapeContent>
  );
});

/* DRAW: 1,
  SELECT_SHAPES: 2,
  SEARCH: 3,
  IMPORT_SHAPE: 4,
  IMPORT_DATASET: 5 */
const startContent = {
  1: (
    <React.Fragment>
      <Typography variant="subtitle2" gutterBottom>
        Teken via de teken-tools een contour op de kaart.
      </Typography>
      <Typography variant="caption" gutterBottom>
        Klik op Volgende om door te gaan.
      </Typography>
    </React.Fragment>
  ),
  2: (
    <React.Fragment>
      <Typography variant="subtitle2" gutterBottom>
        Selecteer 1 of meerdere percelen op kaart. Je kan percelen deselecteren door ze opnieuw aan te klikken.
      </Typography>
      <Typography variant="caption" gutterBottom>
        Klik op Volgende om door te gaan.
      </Typography>
    </React.Fragment>
  ),
  3: <PublicInvestigationList />,
  4: <ShapeUploader />,
  5: <CapakeyInputList />,
};

const steps = [
  {
    index: 1,
    title: "Weergave op kaart",
  },
  { title: "Type Opzoeking ", index: 2, content: <SelectSearchType /> },
  { title: "Type Onderzoek", index: 3, content: <SelectResearchType /> },
  { title: "Weergave percelen op kaart", index: 4, content: <ShowParcels /> },
  { title: "Weergave resultaten", index: 5, content: <ShowResults /> },
];

const NextSteps = hooksObserver(({ classes, children }) => {
  const {
    applicationStore: { publicInvestigation },
  } = useContext(StoresContext);

  const { status, type } = publicInvestigation;
  return (
    <div className={classes.container}>
      {steps.map((step) => {
        if (status !== step.index && status) {
          return null;
        }
        return (
          <React.Fragment key={step.index}>
            {step.index < 5 && children}
            <Paper square className={classes.panelcontent} elevation={0}>
              <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    {step.title}
                  </Typography>
                </Grid>
                <Grid item>{step.content ? step.content : startContent[type]}</Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default withStyles(styles)(NextSteps);
