const styles = (theme) => ({
  container: {
    padding: "24px",
  },
  textfield: {
    width: "100%",
  },
});

export default styles;
