import CssBaseline from "@mui/material/CssBaseline";
import { withStyles } from "@mui/styles";

import classNames from "classnames";
import ErrorModal from "containers/partials/modals/ErrorModal";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef } from "react";
import { Route } from "react-router";
import { ROUTE_SELECTEER_PROFIEL } from "routes/RouteList";
import HeaderView from "views/header/HeaderView";
import SidebarLogging from "../partials/sidebar/SidebarLogging";
import styles from "./LoggingPageStyles";
import SelectProfile from "containers/users/SelectProfile";
import Notifier from "views/notifier/Notifier";
import SelectNis from "containers/users/SelectNis";
import LoggingView from "views/logging/LoggingView";

const LoggingPage = hooksObserver(({ classes, location: { pathname } }) => {
  const {
    uiStore,
    uiStore: { setContentHeight, sideBarOpen, errorModel, setDialogSelectNisOpen, dialogSelectNisOpen },
    mapStore: { mapLayer: { maps } },
    authStore: { sessionId, selectingProfile, validateToken },
  } = useContext(StoresContext);

  useEffect(() => {
    if (rootRef.current !== null && headerRef.current !== null) {
      setContentHeight(rootRef.current.clientHeight - headerRef.current.clientHeight);
    }
  }, [rootRef & headerRef]);
  const rootRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    if (sessionId && selectingProfile && pathname !== ROUTE_SELECTEER_PROFIEL) {
      const validate = async () => {
        await validateToken(selectingProfile);
      };
      validate();
    }
  }, []);

  return (
    <React.Fragment>
      <Notifier />
      <Route exact path={ROUTE_SELECTEER_PROFIEL} component={SelectProfile} />
      <Route
        render={(props) =>
          props.location.pathname !== ROUTE_SELECTEER_PROFIEL && (
            <div className={classes.root} ref={rootRef}>
              <CssBaseline />
              <SidebarLogging />
              <HeaderView />
              <ErrorModal
                {...uiStore.errorModel}
                open={errorModel.open}
                handleClose={() => {
                  errorModel.setIsOpen(false);
                }}
              />
              <SelectNis open={dialogSelectNisOpen} closeFn={setDialogSelectNisOpen} />
              <main
                className={classNames(classes.content, {
                  [classes.contentShift]: sideBarOpen,
                })}
              >
                <div className={classes.drawerHeader} ref={headerRef} />
                <LoggingView />
              </main>
            </div>
          )
        }
      />
    </React.Fragment>
  );
});

LoggingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LoggingPage);
